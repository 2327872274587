import React, { useState, useEffect, useRef, useCallback } from 'react';
import ReactFlow, {
  ReactFlowProvider,
  addEdge,
  useNodesState,
  useEdgesState,
  Controls,
  useReactFlow,
  Background,
  MiniMap
} from 'reactflow';
import CryptoJS, { kdf } from 'crypto-js';
import { CloseOutlined, LoadingOutlined, SendOutlined, LinkOutlined, PhoneOutlined, PlusOutlined } from "@ant-design/icons";
import VirtualList from 'rc-virtual-list';
import { ReactSVG } from 'react-svg';
import templateSvg from '../assets/icons/template.svg';
import { useNavigate, useLocation } from 'react-router-dom';
import 'reactflow/dist/style.css';
import AutoSide from './AutoSide.jsx';
import '../assets/css/automation.css';
import { Typography, Checkbox, Drawer, Switch, Upload, Input, Button, Tabs, Space, List, Avatar, message, Col, Row, Tooltip, Empty, Card, Dropdown, Menu, Modal, Skeleton, Spin, Select, Form, DatePicker, Table } from 'antd';
import { AddOutlined, DeleteOutline, HelpOutlineOutlined } from '@mui/icons-material';
import ButtonEdge from './ButtonEdge.jsx';
import { ApartmentOutlined, EditOutlined } from '@ant-design/icons';
import { debounce, head, result } from 'lodash';
import TextArea from 'antd/es/input/TextArea.js';
import InitialFlow from './InitialFlow.js';
import TextButtonNode from './TextButtonNode.js';
import MediaButtonNode from './MediaButtonNode.js';
import ListNode from './ListNode.js';
import AskQuestionNode from './AskQuestionNode.js';
import TemplateNode from './TemplateNode.js';
import CatalougeNode from './CatalogueNode.js';
import SingleProductNode from './SingleProductNode.js';
import MultiProductNode from './MultiProductNode.js';
import ContentFlow from './ContentFlow.js';
let id = 0;
const getId = () => `dndnode_${id++}`;

const edgeTypes = {
  button: ButtonEdge,
};
const hashId = (id) => CryptoJS.SHA1(id).toString(CryptoJS.enc.Hex);

const ContainerHeight = 519;

const ContainerHeightOne = `calc(100vh - 204px)`;

const getBase64One = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
};

const nodeClassName = (node) => node.type;
const nodeTypes = { initialFlow: InitialFlow, textButton: TextButtonNode, mediaButton: MediaButtonNode, list: ListNode, askQuestion: AskQuestionNode, templateNode: TemplateNode, catalogueNode: CatalougeNode, singleProductNode: SingleProductNode, multiProductNode: MultiProductNode, contentFlow: ContentFlow };
const DnDFlow = ({ userData, record, is_edit }) => {
  const reactFlowWrapper = useRef(null);
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [nodeData, setNodeData] = useState([]);
  const [inputBoxes, setInputBoxes] = useState([{ id: 1, value: '' }]);
  const { screenToFlowPosition } = useReactFlow();
  const [flowName, setFlowName] = useState('Your Flow');
  const [inFlow, setInflow] = useState('');
  const [isActive, setIsActive] = useState('1');
  const [templatedata, setTemplatedata] = useState([]);
  const [catalogueData, setCataogueData] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isCatalogueModalVisible, setIsCatalogueModalVisible] = useState(false);
  const [isSingleProductModalVisible, setIsSingleProductModalVisible] = useState(false);
  const [isMultiProductModalVisible, setIsMultiProductModalVisible] = useState(false);
  const [templatePreview, setTemplatePreview] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [tmpBtnLoading, setTmpBtnLoading] = useState(false);
  const [activeTemplateItem, setActiveTemplateItem] = useState([]);
  const [activeCatalogue, setActiveCatalogue] = useState([]);
  const [selectedValues, setSelectedValues] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [filterOption, setFilterOption] = useState([]);
  const [templateImage, setTemplateImage] = useState('');
  const [templateImageExtension, setTemplateImageExtension] = useState('');
  const [templateImageId, setTemplateImageId] = useState('');
  const [loading, setLoading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const navigate = useNavigate();
  const [whatsappFlowData, setWhatsappFlowData] = useState([]);
  
  const fetchWhatsappFlows = async (userData) => {
    console.log(userData);
    try {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          whatsappaccesstoken: userData[0]?.whatsappAccessToken,
          wabaid: userData[0]?.wabaid,
        },
      };
      const response = await fetch(
        "https://connectby.io:3001/api/get-flows",
        requestOptions
      );

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const data = await response.json();
      console.log(data?.responseData?.data);
      setWhatsappFlowData(data?.responseData?.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };


  useEffect(() => {
    const templatedataUrl = `https://connectby.io:3001/api/get-template`;
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'whatsappaccesstoken': userData?.userData[0]?.whatsappAccessToken,
        'wabaid': userData?.userData[0]?.wabaid
      }
    };
    fetch(templatedataUrl, requestOptions)
      .then((res) => {
        if (!res.ok) {
          throw new Error('Network response was not ok');
        }
        return res.json();
      })
      .then((body) => {
        if (body.success === true) {
          setTemplatedata(body.responseData.data);
        } else {
          console.error('Error: Success is not true');
        }
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      })
      .finally(() => {
        setIsLoading(false);
      });
      getCatalogue(userData);
      fetchWhatsappFlows(userData?.userData);
  }, [userData]);

  const handleInputChange = useCallback((e, id) => {
    const newValue = e.target.value;
    setInflow(newValue);
    setInputBoxes((prevInputBoxes) =>
      prevInputBoxes.map((box) =>
        box.id === id
          ? { ...box, value: newValue }
          : box
      )
    );

    setNodes((prevNodes) =>
      prevNodes.map((node) => {
        if (node.data.id === id) {
          return {
            ...node,
            data: {
              ...node.data,
              inputBoxes: node.data.inputBoxes.map((box) =>
                box.id === id
                  ? { ...box, value: newValue }
                  : box
              ),
              inFlow: newValue
            }
          };
        }
        return node;
      })
    );
  }, []);

  const handleAddInputBox = useCallback(() => {
    const lastId = inputBoxes[inputBoxes.length - 1].id;
    setInputBoxes((prevInputBoxes) => [
      ...prevInputBoxes,
      { id: lastId + 1, value: '' }
    ]);
  }, [inputBoxes]);

  const handleDelete = useCallback((id) => {
    setInputBoxes((prevInputBoxes) => prevInputBoxes.filter((box) => box.id !== id));
  }, []);

  useEffect(() => {
    if (record) {
      const { trigger_name, flow_name, trigger_data, nodedata, nodes, is_active } = record;
      setFlowName(flow_name);
      setIsActive(is_active);
      setInflow(trigger_name);
      try {
        const parsedNodeData = nodedata ? JSON.parse(nodedata) : {};
        const parsedNodes = nodes ? JSON.parse(nodes) : [];
        setNodeData(parsedNodeData);
        parsedNodes.map((node, index) => {
          if (parsedNodes[0].id === 'dndnode_0') {
            id = id + index;
          } else {
            id = id + (index + 1);
          }
          if (node.data.inputBoxes) {
            const initialNode = {
              id: node.id,
              type: node.type,
              position: node.position,
              data: {
                label: node.data.label,
                handleInputChange,
                handleAddInputBox,
                handleDelete,
                inputBoxes: [{ id: 1, value: trigger_name }],
                inFlow: trigger_name,
                id: node.data.id
              },
            };
            setNodes((nds) => nds.concat(initialNode));
          }
          if (parsedNodeData[node.id]) {
            const newNode = {
              id: node.id,
              type: node.type,
              position: node.position,
              data: {
                label: `${node.data.label}`,
                onAddButton: handleAddButton,
                onClose: onCloseIconClick,
                id: `${node.data.id}`,
                onMainTextAreaChange: handleMainTextAreaChange,
                onAdditionalTextAreaChange: handleAdditionalTextAreaChange,
                onAdditionalTextAreaChangeOne: handleAdditionalTextAreaChangeOne,
                onMainTextAreaHeaderChange: handleMainTextAreaHeaderChange,
                onMainTextAreaFooterChange: handleMainTextAreaFooterChange,
                onMainTextAreaBtnChange: handleMainTextAreaBtnChange,
                onAttributeFormatChange: handleAttributeFormatChange,
                onMainTextAreaErrorChange: handleMainTextAreaErrorChange,
                onAdditionalTextAreaChangeTitle: handleAdditionalTextAreaChangeTitle,
                onAdditionalTextAreaChangeDescription: handleAdditionalTextAreaChangeDescription,
                onTemplatesPreview: handleTemplatePreview,
                onCataloguePreview: handleCataloguePreview,
                onSingleproductSelect: handleSingleProductPreview,
                onMultiProductSelect: handleMultiProductPreview,
                onFlowSelect: handleFlowSelect,
                onFlowBtnTextAreaChange: handleFlowBtnTextAreaChange,
                onCatalogue: activeCatalogue,
                onSingleproduct: selectedProductId,
                onMultiProduct: selectedProductIds,
                nodeData: parsedNodeData[node.id],
                flowData: whatsappFlowData
              },
            };
            console.log('newNode', newNode);
            setNodes((nds) => nds.concat(newNode));
          }
        });
        const parsedEdges = JSON.parse(trigger_data);
        setEdges(parsedEdges);
      } catch (error) {
        console.error('Error parsing data:', error);
      }
    } else {
      const initialNode = {
        id: getId(),
        type: 'initialFlow',
        position: { x: 100, y: 100 },
        data: {
          label: 'Flow Start',
          handleInputChange,
          handleAddInputBox,
          handleDelete,
          inputBoxes,
          inFlow,
          id
        },
      };
      setNodes((nds) => nds.concat(initialNode));
    }
  }, [whatsappFlowData]);


  const onConnect = useCallback(
    (params) =>
      setEdges((eds) => addEdge({ ...params, animated: true, type: 'button', style: { stroke: '#075E54' } }, eds)),
    []
  );

  const onCloseIconClick = useCallback((nodeId) => {
    setNodes((nds) => nds.filter((node) => node.id !== nodeId));
  }, []);

  const onDragOver = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  }, []);

  const handleMainTextAreaChange = useCallback((nodeId, value) => {
    setNodeData((prevNodeData) => ({
      ...prevNodeData,
      [nodeId]: {
        ...prevNodeData[nodeId],
        mainText: value
      }
    }));
    setNodes((prevNodes) =>
      prevNodes.map((node) =>
        node.id === nodeId
          ? {
            ...node,
            data: {
              ...node.data,
              nodeData: {
                ...node.data.nodeData,
                mainText: value
              }
            }
          }
          : node
      )
    );
  }, []);

  const handleFlowBtnTextAreaChange = useCallback((nodeId, value) => {
    setNodeData((prevNodeData) => ({
      ...prevNodeData,
      [nodeId]: {
        ...prevNodeData[nodeId],
        flowBtnText: value
      }
    }));
    setNodes((prevNodes) =>
      prevNodes.map((node) =>
        node.id === nodeId
          ? {
            ...node,
            data: {
              ...node.data,
              nodeData: {
                ...node.data.nodeData,
                flowBtnText: value
              }
            }
          }
          : node
      )
    );
  }, []);


  const handleFlowSelect = useCallback((nodeId, value) => {
    setNodeData((prevNodeData) => ({
      ...prevNodeData,
      [nodeId]: {
        ...prevNodeData[nodeId],
        flowId: value
      }
    }));
    setNodes((prevNodes) =>
      prevNodes.map((node) =>
        node.id === nodeId
          ? {
            ...node,
            data: {
              ...node.data,
              nodeData: {
                ...node.data.nodeData,
                flowId: value
              }
            }
          }
          : node
      )
    );
  }, []);

  const handleAttributeFormatChange = useCallback((nodeId, value) => {
    console.log(value);
    setNodeData((prevNodeData) => ({
      ...prevNodeData,
      [nodeId]: {
        ...prevNodeData[nodeId],
        attributeFormat: value
      }
    }));
    setNodes((prevNodes) =>
      prevNodes.map((node) =>
        node.id === nodeId
          ? {
            ...node,
            data: {
              ...node.data,
              nodeData: {
                ...node.data.nodeData,
                attributeFormat: value
              }
            }
          }
          : node
      )
    );
  }, []);

  const handleMainTextAreaErrorChange = useCallback((nodeId, value) => {
    setNodeData((prevNodeData) => ({
      ...prevNodeData,
      [nodeId]: {
        ...prevNodeData[nodeId],
        questionError: value
      }
    }));
    setNodes((prevNodes) =>
      prevNodes.map((node) =>
        node.id === nodeId
          ? {
            ...node,
            data: {
              ...node.data,
              nodeData: {
                ...node.data.nodeData,
                questionError: value
              }
            }
          }
          : node
      )
    );
  }, []);

  const handleCatalogueProductChange = useCallback((nodeId, record) => {
    setNodeData((prevNodeData) => ({
      ...prevNodeData,
      [nodeId]: {
        ...prevNodeData[nodeId],
        catalogueData: record
      }
    }));
    setNodes((prevNodes) =>
      prevNodes.map((node) =>
        node.id === nodeId
          ? {
            ...node,
            data: {
              ...node.data,
              nodeData: {
                ...node.data.nodeData,
                catalogueData: record
              }
            }
          }
          : node
      )
    );
  }, []);

  const handleSelectMultiCatalogueProductChange = useCallback((nodeId, record, catalogue) => {
    setNodeData((prevNodeData) => ({
      ...prevNodeData,
      [nodeId]: {
        ...prevNodeData[nodeId],
        multiProductData: record,
        catalogue: catalogue
      }
    }));
    setNodes((prevNodes) =>
      prevNodes.map((node) =>
        node.id === nodeId
          ? {
            ...node,
            data: {
              ...node.data,
              nodeData: {
                ...node.data.nodeData,
                multiProductData: record,
                catalogue: catalogue
              }
            }
          }
          : node
      )
    );
  }, []);

  const handleSelectSingleCatalogueProductChange = useCallback((nodeId, record, catalogue) => {
    setNodeData((prevNodeData) => ({
      ...prevNodeData,
      [nodeId]: {
        ...prevNodeData[nodeId],
        singleProductData: record,
        catalogue: catalogue
      }
    }));
    setNodes((prevNodes) =>
      prevNodes.map((node) =>
        node.id === nodeId
          ? {
            ...node,
            data: {
              ...node.data,
              nodeData: {
                ...node.data.nodeData,
                singleProductData: record,
                catalogue: catalogue
              }
            }
          }
          : node
      )
    );
  }, []);

  const handleMainTextAreaHeaderChange = useCallback((nodeId, value) => {
    setNodeData((prevNodeData) => ({
      ...prevNodeData,
      [nodeId]: {
        ...prevNodeData[nodeId],
        headerText: value
      }
    }));
    setNodes((prevNodes) =>
      prevNodes.map((node) =>
        node.id === nodeId
          ? {
            ...node,
            data: {
              ...node.data,
              nodeData: {
                ...node.data.nodeData,
                headerText: value
              }
            }
          }
          : node
      )
    );
  }, []);

  const handleMainTextAreaFooterChange = useCallback((nodeId, value) => {
    setNodeData((prevNodeData) => ({
      ...prevNodeData,
      [nodeId]: {
        ...prevNodeData[nodeId],
        footerText: value
      }
    }));
    setNodes((prevNodes) =>
      prevNodes.map((node) =>
        node.id === nodeId
          ? {
            ...node,
            data: {
              ...node.data,
              nodeData: {
                ...node.data.nodeData,
                footerText: value
              }
            }
          }
          : node
      )
    );
  }, []);

  const handleMainTextAreaBtnChange = useCallback((nodeId, value) => {
    setNodeData((prevNodeData) => ({
      ...prevNodeData,
      [nodeId]: {
        ...prevNodeData[nodeId],
        btnText: value
      }
    }));
    setNodes((prevNodes) =>
      prevNodes.map((node) =>
        node.id === nodeId
          ? {
            ...node,
            data: {
              ...node.data,
              nodeData: {
                ...node.data.nodeData,
                btnText: value
              }
            }
          }
          : node
      )
    );
  }, []);



  const handleAdditionalTextAreaChangeDescription = useCallback((nodeId, index, key, type, value) => {
    setNodeData((prevNodeData) => ({
      ...prevNodeData,
      [nodeId]: {
        ...prevNodeData[nodeId],
        additionalTexts: {
          ...prevNodeData[nodeId]?.additionalTexts,
          [key]: {
            ...prevNodeData[nodeId]?.additionalTexts?.[key],
            [index]: {
              ...prevNodeData[nodeId]?.additionalTexts?.[key]?.[index],
              [type]: value
            }
          }
        }
      }
    }));

    setNodes((prevNodes) =>
      prevNodes.map((node) =>
        node.id === nodeId
          ? {
            ...node,
            data: {
              ...node.data,
              nodeData: {
                ...node.data.nodeData,
                additionalTexts: {
                  ...node.data.nodeData?.additionalTexts,
                  [key]: {
                    ...node.data.nodeData?.additionalTexts?.[key],
                    [index]: {
                      ...node.data.nodeData?.additionalTexts?.[key]?.[index],
                      [type]: value
                    }
                  }
                }
              }
            }
          }
          : node
      )
    );

  }, []);

  const handleTemplatePreview = useCallback((nodeId) => {
    setIsModalVisible(true);
  }, []);
  const [selectedNodeId, setSelectedNodeId] = useState('');
  const handleCataloguePreview = useCallback((nodeId) => {
    setSelectedNodeId(nodeId);
    setIsCatalogueModalVisible(true);
  }, []);

  const handleSingleProductPreview = useCallback((nodeId) => {
    setSelectedNodeId(nodeId);
    setIsSingleProductModalVisible(true);
  }, []);

  const handleMultiProductPreview = useCallback((nodeId) => {
    setSelectedNodeId(nodeId);
    setIsMultiProductModalVisible(true);
  }, []);

  const handleAdditionalTextAreaChangeTitle = useCallback((nodeId, index, key, type, value) => {
    setNodeData((prevNodeData) => ({
      ...prevNodeData,
      [nodeId]: {
        ...prevNodeData[nodeId],
        additionalTexts: {
          ...prevNodeData[nodeId]?.additionalTexts,
          [key]: {
            ...prevNodeData[nodeId]?.additionalTexts?.[key],
            [index]: {
              ...prevNodeData[nodeId]?.additionalTexts?.[key]?.[index],
              [type]: value
            }
          }
        }
      }
    }));

    setNodes((prevNodes) =>
      prevNodes.map((node) =>
        node.id === nodeId
          ? {
            ...node,
            data: {
              ...node.data,
              nodeData: {
                ...node.data.nodeData,
                additionalTexts: {
                  ...node.data.nodeData?.additionalTexts,
                  [key]: {
                    ...node.data.nodeData?.additionalTexts?.[key],
                    [index]: {
                      ...node.data.nodeData?.additionalTexts?.[key]?.[index],
                      [type]: value
                    }
                  }
                }
              }
            }
          }
          : node
      )
    );

  }, []);

  const handleAdditionalTextAreaChange = useCallback((nodeId, index, value) => {
    setNodeData((prevNodeData) => ({
      ...prevNodeData,
      [nodeId]: {
        ...prevNodeData[nodeId],
        additionalTexts: {
          ...prevNodeData[nodeId]?.additionalTexts,
          [index]: value
        }
      }
    }));

    setNodes((prevNodes) =>
      prevNodes.map((node) =>
        node.id === nodeId
          ? {
            ...node,
            data: {
              ...node.data,
              nodeData: {
                ...node.data.nodeData,
                additionalTexts: {
                  ...node.data.nodeData?.additionalTexts,
                  [index]: value
                }
              }
            }
          }
          : node
      )
    );
  }, []);

  const handleAdditionalTextAreaChangeOne = useCallback((nodeId, index, type, value) => {
    setNodeData((prevNodeData) => ({
      ...prevNodeData,
      [nodeId]: {
        ...prevNodeData[nodeId],
        additionalTexts: {
          ...prevNodeData[nodeId]?.additionalTexts,
          [index]: {
            ...prevNodeData[nodeId]?.additionalTexts?.[index],
            [type]: value
          }
        }
      }
    }));
    setNodes((prevNodes) =>
      prevNodes.map((node) =>
        node.id === nodeId
          ? {
            ...node,
            data: {
              ...node.data,
              nodeData: {
                ...node.data.nodeData,
                additionalTexts: {
                  ...node.data.nodeData?.additionalTexts,
                  [index]: {
                    ...node.data.nodeData?.additionalTexts?.[index],
                    [type]: value
                  }
                }
              }
            }
          }
          : node
      )
    );
  }, []);

  const handleAddButton = useCallback((nodeId) => {
    setNodes((prevNodes) => {
      const updatedNodes = prevNodes.map((node) => {
        if (node.id === nodeId) {
          const buttons = node.data.buttons || [];

          if (buttons.length >= 3) {
            return node;
          }

          const newButton = {
            id: getId(),
            label: 'Button Label',
          };

          const updatedNode = {
            ...node,
            data: {
              ...node.data,
              buttons: [...buttons, newButton],
            },
          };

          return updatedNode;
        }
        return node;
      });

      return updatedNodes;
    });
  }, []);
  
  const onDrop = useCallback(
    (event) => {
      event.preventDefault();
      const item = JSON.parse(event.dataTransfer.getData('application/reactflow'));
      if (typeof item === 'undefined' || !item) {
        return;
      }
      const position = screenToFlowPosition({
        x: event.clientX,
        y: event.clientY,
      });
      const id = getId();
      const isFirstNode = nodes.length === 0;
      const newNodeType = isFirstNode ? 'input' : 'default';
      let nodetype = 'textButton';
      switch (item.label) {
        case 'Media Buttons':
          nodetype = 'mediaButton';
          break;
        case 'List':
          nodetype = 'list';
          break;
        case 'Ask Question':
          nodetype = 'askQuestion';
          break;
        case 'Template':
          nodetype = 'templateNode';
          break;
        case 'Catalogue Message':
          nodetype = 'catalogueNode';
          break;
        case 'Single Product':
          nodetype = 'singleProductNode';
          break;
        case 'Multi Product':
          nodetype = 'multiProductNode';
          break;
        case 'Flow':
          nodetype = 'contentFlow';
          break;
        default:
          nodetype = 'textButton';
          break;
      }
      console.log(nodetype);
      const newNode = {
        id,
        type: nodetype,
        position,
        data: {
          label: `${item.label}`,
          onAddButton: handleAddButton,
          onClose: onCloseIconClick,
          id,
          onMainTextAreaChange: handleMainTextAreaChange,
          onAdditionalTextAreaChange: handleAdditionalTextAreaChange,
          onAdditionalTextAreaChangeOne: handleAdditionalTextAreaChangeOne,
          onMainTextAreaHeaderChange: handleMainTextAreaHeaderChange,
          onMainTextAreaFooterChange: handleMainTextAreaFooterChange,
          onMainTextAreaBtnChange: handleMainTextAreaBtnChange,
          onAttributeFormatChange: handleAttributeFormatChange,
          onMainTextAreaErrorChange: handleMainTextAreaErrorChange,
          onAdditionalTextAreaChangeTitle: handleAdditionalTextAreaChangeTitle,
          onAdditionalTextAreaChangeDescription: handleAdditionalTextAreaChangeDescription,
          onTemplatesPreview: handleTemplatePreview,
          onCataloguePreview: handleCataloguePreview,
          onSingleproductSelect: handleSingleProductPreview,
          onMultiProductSelect: handleMultiProductPreview,
          onFlowSelect: handleFlowSelect,
          onFlowBtnTextAreaChange: handleFlowBtnTextAreaChange,
          onCatalogue: activeCatalogue,
          onSingleproduct: selectedProductId,
          onMultiProduct: selectedProductIds,
          nodeData: nodeData,
          flowData: whatsappFlowData
        },
      };

      setNodes((nds) => nds.concat(newNode));
    },
    [screenToFlowPosition, onCloseIconClick, handleAddButton, nodes.length, whatsappFlowData],
  );

  const handleSaveChanges = () => {
    const flowData = {
      nodes,
      edges,
      inputBoxes,
      flowName,
      isActive,
      nodeData
    };
    if(is_edit && is_edit === 'yes'){
      fetch('https://connectby.io:3001/api/update-automation-one', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          crm_user_id: userData?.userData[0]?.user_id,
          automation_list_id: record?.automation_list_id,
          trigger_name: inFlow,
          flow_name: flowName,
          nodes: nodes,
          trigger_data: edges,
          nodedata: nodeData,
          is_active: isActive ? '1' : '2'
        }),
      })
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then(data => {
          message.success('Flow saved successfully');
          console.log('Success:', data);
          navigate('/automation');
        })
        .catch(error => {
          console.error('Error:', error);
        });
    }else{
      fetch('https://connectby.io:3001/api/create-automation-one', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          crm_user_id: userData?.userData[0]?.user_id,
          trigger_name: inFlow,
          flow_name: flowName,
          nodes: nodes,
          trigger_data: edges,
          nodedata: nodeData,
          is_active: isActive ? '1' : '2'
        }),
      })
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then(data => {
          message.success('Flow saved successfully');
          console.log('Success:', data);
          navigate('/automation');
        })
        .catch(error => {
          console.error('Error:', error);
        });
    }
  };

  const handleFlowNameChange = (e) => {
    setFlowName(e.target.innerText);
  };

  const hideTemplates = () => {
    setIsModalVisible(false);
  };

  const handleSendTemplate = () => {
    setIsModalVisible(false);
  };

  const handleSelectCatalouge = (nodeId) => {
    handleCatalogueProductChange(nodeId, selectedProductId);
    setIsCatalogueModalVisible(false);
  };

  const handleSelectSingleProduct = (nodeId) => {
    handleSelectSingleCatalogueProductChange(nodeId, selectedProductId, activeCatalogue);
    setIsSingleProductModalVisible(false)
  };

  const handleSelectMultiProduct = (nodeId) => {
    handleSelectMultiCatalogueProductChange(nodeId, selectedProductIds, activeCatalogue);
    setIsMultiProductModalVisible(false)
  };

  const onScrollTemplate = debounce((e) => {
    const target = e.target;
    if (target) {
      if (Math.abs(target.scrollHeight - target.scrollTop - ContainerHeight) <= 1) {

      }
    }
  });
  
  const opentemplate = (item) => {
    setActiveTemplateItem(item);
    setSelectedValues({});
    if (item && Array.isArray(item.components)) {
      setTemplatePreview(item.components);
    }
  };
  
  const onVarChange = (element, value) => { 
    setSelectedValues(prevValues => ({
      ...prevValues,
      [element]: value,
    }));
  };

  const onVarSearch = (value) => {
    console.log('search:', value);
  };

  const inputfilterOption = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  const checkFile = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPEG/PNG file!');
    }
    const isLt5M = file.size / 1024 / 1024 < 5;
    if (!isLt5M) {
      message.error('Image must smaller than 5MB!');
    }
    return isJpgOrPng && isLt5M;
  };
  
  const getCatalogue = async(userData) => {
    try {
      setLoading(true);
      const wabaId = userData?.userData[0]?.wabaid;
      const whatsappaccesstoken = userData?.userData[0]?.whatsappAccessToken;
      const response = await fetch('https://connectby.io:3001/api/get-catalogs', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'whatsappaccesstoken': whatsappaccesstoken,
          'wabaId': wabaId,
        }
      });
      if(response.ok){
        const responseData = await response.json();
        console.log(responseData);
        setCataogueData(responseData?.data);
      }
    } catch(error) {
      console.log(error);
    } finally {
      setLoading(true);
    };
  };

  const customRequestImage = () => {
    return async ({ action, data, file, filename, headers, onError, onProgress, onSuccess }) => {
      try {
        const formData = new FormData();
        formData.append('file', file);
        const fileType = file.type;
        console.log(fileType);
        const response = await fetch(action, {
          method: 'POST',
          body: formData,
          headers: {
            ...headers,
            'whatsappaccesstoken': userData?.userData[0]?.whatsappAccessToken,
            'phonenumberid': userData?.userData[0]?.phonenumberid
          },
        });
        const responseData = await response.json();
        setTemplateImageId(responseData.id);
        setTemplateImageExtension(fileType);
        onSuccess(responseData, file);
      } catch (error) {
        onError(error);
        console.log(error);
      }
    };
  };

  const handleChange = (info) => {
    console.log(info);
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }
    console.log(info.file.status);
    if (info.file.status === 'done') {
      getBase64One(info.file.originFileObj, (url) => {
        setTemplateImage(url);
        setLoading(false);
      });
    }
  };

  const [products, setProducts] = useState([]);
  const [paging, setPaging] = useState({});
  const [productLoading, setProductLoading] = useState(false);
  
  const [selectedProductId, setSelectedProductId] = useState(null);
  const [selectedProductIds, setSelectedProductIds] = useState([]);

  const handleSelectProduct = (id) => {
    setSelectedProductIds((prevSelectedIds) => {
      if (prevSelectedIds.includes(id)) {
        return prevSelectedIds.filter(productId => productId !== id);
      } else {
        if (prevSelectedIds.length < 10) {
          return [...prevSelectedIds, id];
        } else {
          message.error('You can select a maximum of 10 products.');
          return prevSelectedIds;
        }
      }
    });
  };
  
  
  const productColumns = [
    {
      title: 'Select',
      dataIndex: 'id',
      key: 'select',
      render: (_, record) => <Checkbox checked={selectedProductId?.id === record?.id} onChange={() => setSelectedProductId(record)} />,
    },
    {
      title: 'Product',
      dataIndex: 'name',
      key: 'name',
      render: (_, record) => <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}><span style={{ width: 50, height: 50, display: 'inline-block' }}><img style={{ width: '100%', }} alt={record.name} src={record.image_url} /></span> <span>{record.name}</span></div>
    },
    {
      title: 'visibility',
      dataIndex: 'visibility',
      key: 'visibility',
      render: (_, record) => (
        <span style={{ backgroundColor: record.visibility === 'published' ? 'green' : 'red', color: '#fff', padding: 5, borderRadius: 10 }}>
          {record.visibility}
        </span>
      )
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price',
      render: (price) => <span>{price}</span>,
    }
  ];

  const productColumnsOne = [
    {
      title: 'Select',
      dataIndex: 'id',
      key: 'select',
      render: (_, record) => <Checkbox checked={selectedProductIds.includes(record.retailer_id)} onChange={() => handleSelectProduct(record.retailer_id)} />,
    },
    {
      title: 'Product',
      dataIndex: 'name',
      key: 'name',
      render: (_, record) => <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}><span style={{ width: 50, height: 50, display: 'inline-block' }}><img style={{ width: '100%', }} alt={record.name} src={record.image_url} /></span> <span>{record.name}</span></div>
    },
    {
      title: 'visibility',
      dataIndex: 'visibility',
      key: 'visibility',
      render: (_, record) => (
        <span style={{ backgroundColor: record.visibility === 'published' ? 'green' : 'red', color: '#fff', padding: 5, borderRadius: 10 }}>
          {record.visibility}
        </span>
      )
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price',
      render: (price) => <span>{price}</span>,
    }
  ];

  const handlePreviousPage = (item) => {
    if (paging.previous) {
      handleCatalogue(item, paging.previous);
    }
  };

  const handleNextPage = (item) => {
    if (paging.next) {
      handleCatalogue(item, paging.next);
    }
  };

  const handleCatalogue = async (item, page = '') => {
    const whatsappaccesstoken = userData?.userData[0]?.whatsappAccessToken;
    const catalogueId = item?.id;
    setActiveCatalogue(item);
    setProductLoading(true);
    const url = 'https://connectby.io:3001/api/get-catalog';
    try {
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'whatsappaccesstoken': whatsappaccesstoken,
          'catalougid': catalogueId
        },
        body: JSON.stringify({ "page": page }),
      };
      const response = await fetch(url, requestOptions);
      const data = await response.json();
      if (data.success) {
        console.log(data);
        if (data.data.length !== 0) {
          setProducts(data.data);
          setPaging(data.paging);
          console.log(data.paging.next);
        }
      } else {
        console.error('Failed to fetch data:', data.error);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setProductLoading(false);
    }
  };

  const uploadButton = (
    <button
      style={{
        border: 0,
        background: 'none',
      }}
      type="button"
    >
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </button>
  );

  return (
    <>
      <div className='flowheader'>
        <p><ApartmentOutlined /> <span contentEditable suppressContentEditableWarning onBlur={handleFlowNameChange} >{flowName}</span> <EditOutlined /></p>
       
        <div>
        <h4 style={{float:'left', position:'relative', top:'-12px', left:'-15px'}}><span style={{color:'blue'}}>Draft</span></h4>
          <span style={{ marginRight: 10 }}>{isActive === '1' ? 'Active' : 'Inactive'}</span>
          <Switch
            onChange={() => setIsActive(isActive === '1' ? '2' : '1')}
            style={{ marginRight: 10 }}
            checked={isActive === '1'}
          />
          <Button type='primary' onClick={handleSaveChanges}>Save Changes</Button>
        </div>
      </div>
      <div className="dndflow">
        <AutoSide />
        <div className="reactflow-wrapper" ref={reactFlowWrapper}>
          <ReactFlow
            nodes={nodes}
            edges={edges}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onConnect={onConnect}
            attributionPosition="top-right"
            onDrop={onDrop}
            onDragOver={onDragOver}
            fitView={false}
            snapToGrid={true}
            edgeTypes={edgeTypes}
            nodeTypes={nodeTypes}
            className="overview"
          >
            {/* <MiniMap zoomable pannable nodeClassName={nodeClassName} /> */}
            <Controls />
            <Background />
          </ReactFlow>
        </div>
      </div>
      <Modal
        title={<div className='templateTitle'>
          <ReactSVG src={templateSvg} className="templateSvg" />
          <span>Select template</span>
        </div>}
        open={isModalVisible}
        onCancel={hideTemplates}
        tmpBtnLoading
        footer={[<Button type="primary" style={{ width: '150px' }} icon={tmpBtnLoading ? (<Spin indicator={<LoadingOutlined spin />} />) : (<p style={{ padding: 0, margin: 0 }}>Select Template</p>)} size={'large'} onClick={handleSendTemplate} disabled={tmpBtnLoading} />]}
        centered
        width="800px"
      >
        <div className='templateData'>
          <Row>
            <Col span={8} className='templateDatacolumn'>
              {/* <Space direction="vertical" style={{ width: '100%' }} >
                  <Search placeholder="Search template" onSearch={onSearch} />
              </Space> */}
              <List>
                <VirtualList
                  data={templatedata}
                  height={ContainerHeight}
                  itemHeight={47}
                  itemKey={(index) => {
                    return index.id;
                  }}
                  onScroll={onScrollTemplate}
                >
                  {(item, index) => {
                    return (
                      <List.Item
                        key={item.id || index}
                        onClick={() => opentemplate(item)}
                        className={
                          activeTemplateItem.id === item.id ? 'templateList active' : 'templateList'
                        }
                      >
                        {(isLoading || !item.id) ? (
                          <Skeleton avatar active>
                            <List.Item.Meta
                              avatar={<Avatar />}
                              title={<Skeleton.Input style={{ width: '40px' }} />}
                              description={<Skeleton.Input style={{ width: '80px' }} />}
                            />
                          </Skeleton>
                        ) : (
                          <List.Item.Meta
                            title={`${item.name.replace(/_/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase())} (${item.language})`}
                          />
                        )}
                      </List.Item>
                    );
                  }}
                </VirtualList>
              </List>
            </Col>
            <Col span={16} style={{ padding: '0 12px' }}>
              <p><b>Message</b></p>
              <div className='templatemessage'>
                <div className="message-box preview-message">
                  <p>
                    {templatePreview && templatePreview.length > 0 ? (
                      templatePreview.map((component) => (

                        <React.Fragment key={component.type}>
                          {component.type === "HEADER" && (
                            component.format === 'TEXT' ? (
                              <p className='headeretxt'>{component.text}</p>
                            ) : component.format === 'IMAGE' ? (
                              <div className='previewContent'><img alt="Header" src={component.example.header_handle[0]} style={{ height: '200px', width: 'auto' }} /></div>
                            ) : component.format === 'VIDEO' ? (
                              <div className='previewContent'><video src={component.example.header_handle[0]} controls style={{ width: '100%' }} /></div>
                            ) : component.format === 'DOCUMENT' ? (
                              <div className='previewContent'><embed src={component.example.header_handle[0]} type="application/pdf" style={{ width: '100%', height: '500px' }} /></div>
                            ) : (
                              <p>Unsupported format</p>
                            )
                          )}
                          {component.type === "BODY" && (
                            <div className='bodytext'>{component.text}</div>
                          )}
                          {component.type === "FOOTER" && (
                            <div className='footertext'>{component.text}</div>
                          )}{component.type === "BUTTONS" && (
                            <React.Fragment>
                              <hr className='buttonhr' />
                              {component.buttons.map((button) => (
                                <div key={button.id}>
                                  {
                                    button.type === 'QUICK_REPLY' ? (
                                      <>
                                        <div className='quickreply'>
                                          <a target="_blank" href="javascript:void(0)" rel="noreferrer">
                                            {button.text}
                                          </a>
                                        </div>
                                      </>
                                    ) : (
                                      ''
                                    )}
                                  {
                                    button.type === 'URL' ? (
                                      <>
                                        <div className='urlbtn'>
                                          <a target="_blank" href={button.url} rel="noreferrer">
                                            <LinkOutlined />
                                            {button.text}</a>
                                        </div>
                                      </>
                                    ) : (
                                      ''
                                    )}
                                  {
                                    button.type === 'PHONE_NUMBER' ? (
                                      <>
                                        <div className='phonebtn'>
                                          <a target="_blank" href={`tel:${button.phone_number}`} rel="noreferrer">
                                            <PhoneOutlined />
                                            {button.text}</a>
                                        </div>
                                      </>
                                    ) : (
                                      ''
                                    )}
                                </div>
                              ))}
                            </React.Fragment>
                          )}

                        </React.Fragment>
                      ))
                    ) : (
                      <p>Please Select the Template.</p>
                    )}
                  </p>
                </div>
                <hr />
                <div className='variableData'>
                  {templatePreview && templatePreview.length > 0 ? (
                    templatePreview.map((component) => (
                      <React.Fragment key={component.type}>
                        {component.type === "HEADER" && (
                          component.format === 'TEXT' && component.text.match(/{{\d+}}/g) ? (
                            <div>
                              <h4>Header</h4>
                              {component.text.match(/{{\d+}}/g).map(element => (
                                <Row key={`H${component.type}-${element}`} style={{ marginTop: '6px', marginBottom: '6px' }}>
                                  <Col span={12} style={{ paddingRight: '6px' }}>
                                    <Select
                                      showSearch
                                      placeholder="Select Predefined Options"
                                      optionFilterProp="children"
                                      onChange={(value) => onVarChange(`${component.type}-${element}`, value)}
                                      onSearch={onVarSearch}
                                      filterOption={inputfilterOption}
                                      options={filterOption}
                                      style={{ width: '100%' }}
                                      value={selectedValues[`${component.type}-${element}`] ? selectedValues[`${component.type}-${element}`] : undefined}
                                    />
                                  </Col>
                                  <Col span={12} style={{ paddingLeft: '6px' }}>
                                    <Input
                                      placeholder={`Enter Text for variable ${element}`}
                                      prefix={element}
                                      value={selectedValues[`${component.type}-${element}`]}
                                    />
                                  </Col>
                                </Row>
                              ))}
                            </div>
                          ) : component.format === 'IMAGE' ? (
                            <Row>
                              <Col span={24}>
                                <h4>Header</h4>
                              </Col>
                              <Col span={6} style={{ paddingRight: '6px', maxHeight: '100px', overflow: 'hidden' }}>
                                <div className='previewContent'><img alt="Header" src={(templateImage !== '') ? (templateImage) : (component.example.header_handle[0])} style={{ height: '100px', width: 'auto' }} /></div>
                              </Col>
                            </Row>
                          ) : component.format === 'VIDEO' ? (
                            <Row>
                              <Col span={24}>
                                <h4>Header</h4>
                              </Col>
                              <Col span={6} style={{ maxHeight: '60px', overflow: 'hidden' }}>
                                <div className='previewContent'><video src={component.example.header_handle[0]} controls style={{ width: '100%' }} /></div>
                              </Col>
                            </Row>
                          ) : component.format === 'DOCUMENT' ? (
                            <Row>
                              <Col span={24}>
                                <h4>Header</h4>
                              </Col>
                              <Col span={6} style={{ maxHeight: '60px', overflow: 'hidden' }}>
                                <div className='previewContent'><embed src={component.example.header_handle[0]} type="application/pdf" style={{ width: '100%', height: '500px' }} /></div>
                              </Col>
                            </Row>
                          ) : (
                            ''
                          )
                        )}
                        {component.type === "BODY" && (
                          <div>
                            <h4>Body</h4>
                            {component.text.match(/{{\d+}}/g) && component.text.match(/{{\d+}}/g).length > 0 ? (
                              component.text.match(/{{\d+}}/g).map(element => (
                                <>
                                  <Row key={`${component.type}-${element}`} style={{ marginTop: '6px', marginBottom: '6px' }}>
                                    <Col span={12} style={{ paddingRight: '6px' }}>
                                      <Select
                                        showSearch
                                        placeholder="Select Predefined Options"
                                        optionFilterProp="children"
                                        onChange={(value) => onVarChange(`${component.type}-${element}`, value)}
                                        onSearch={onVarSearch}
                                        filterOption={inputfilterOption}
                                        options={filterOption}
                                        style={{ width: '100%' }}
                                        value={selectedValues[`${component.type}-${element}`] ? selectedValues[`${component.type}-${element}`] : undefined}
                                      />
                                    </Col>
                                    <Col span={12} style={{ paddingLeft: '6px' }}>
                                      <Input
                                        placeholder={`Enter Text for variable ${element}`}
                                        prefix={element}
                                        value={selectedValues[`${component.type}-${element}`]}
                                      />
                                    </Col>
                                  </Row>
                                </>
                              ))
                            ) : (
                              ''
                            )}
                          </div>
                        )}
                      </React.Fragment>
                    ))
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Modal>
      <Modal
        title={<div className='templateTitle'>
          <ReactSVG src={templateSvg} className="templateSvg" />
          <span>Select Catalogue</span>
        </div>}
        open={isCatalogueModalVisible}
        onCancel={() => setIsCatalogueModalVisible(false)}
        tmpBtnLoading
        footer={[<Button type="primary" style={{ width: '150px' }} icon={tmpBtnLoading ? (<Spin indicator={<LoadingOutlined spin />} />) : (<p style={{ padding: 0, margin: 0 }}>Select Catalogue</p>)} size={'large'} onClick={() => handleSelectCatalouge(selectedNodeId)} disabled={tmpBtnLoading} />]}
        centered
        width="800px"
      >
        <div className='templateData'>
          <Row>
            <Col span={8} className='templateDatacolumn'>
              <List>
                <VirtualList
                  data={catalogueData}
                  height={ContainerHeight}
                  itemHeight={47}
                  itemKey={(index) => {
                    return index.id;
                  }}
                  onScroll={onScrollTemplate}
                >
                  {(item, index) => {
                    return (
                      <List.Item
                        key={item.id || index}
                        onClick={() => handleCatalogue(item)}
                        className={
                          activeCatalogue.id === item.id ? 'templateList active' : 'templateList'
                        }
                      >
                        {(isLoading || !item.id) ? (
                          <Skeleton avatar active>
                            <List.Item.Meta
                              avatar={<Avatar />}
                              title={<Skeleton.Input style={{ width: '40px' }} />}
                              description={<Skeleton.Input style={{ width: '80px' }} />}
                            />
                          </Skeleton>
                        ) : (
                          <List.Item.Meta
                            title={`${item.name.replace(/_/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase())}`}
                          />
                        )}
                      </List.Item>
                    );
                  }}
                </VirtualList>
              </List>
            </Col>
            <Col span={16} style={{ padding: '0 12px' }}>
              <p><b>Message</b></p>
              <div style={{ padding: '20px' }}>
                {productLoading ? (
                  <div>
                    <Spin />
                  </div>
                ) : (
                  products && (
                  <>
                    <div style={{ marginTop: '20px', textAlign: 'center' }}>
                      <Table
                        dataSource={products.filter(product => product.name.toLowerCase().includes(searchQuery.toLowerCase()))}
                        columns={productColumns}
                        rowKey="id"
                        pagination={false}
                      />
                    </div>
                    <div style={{ marginTop: '20px', textAlign: 'center' }}>
                      <Button
                        type="primary"
                        onClick={() => handlePreviousPage(activeCatalogue)}
                        disabled={!paging?.previous || productLoading}
                        style={{ marginRight: '8px' }}
                      >
                        Previous
                      </Button>
                      <Button
                        type="primary"
                        onClick={() => handleNextPage(activeCatalogue)}
                        disabled={!paging?.next || productLoading}
                      >
                        Next
                      </Button>
                    </div>

                  </>
                  )
                )}
              </div>
            </Col>
          </Row>
        </div>
      </Modal>

      <Modal
        title={<div className='templateTitle'>
          <ReactSVG src={templateSvg} className="templateSvg" />
          <span>Select Single Product</span>
        </div>}
        open={isSingleProductModalVisible}
        onCancel={() => setIsSingleProductModalVisible(false)}
        tmpBtnLoading
        footer={[<Button type="primary" style={{ width: '150px' }} icon={tmpBtnLoading ? (<Spin indicator={<LoadingOutlined spin />} />) : (<p style={{ padding: 0, margin: 0 }}>Select Product</p>)} size={'large'} onClick={() => handleSelectSingleProduct(selectedNodeId)} disabled={tmpBtnLoading} />]}
        centered
        width="800px"
      >
        <div className='templateData'>
          <Row>
            <Col span={8} className='templateDatacolumn'>
              <List>
                <VirtualList
                  data={catalogueData}
                  height={ContainerHeight}
                  itemHeight={47}
                  itemKey={(index) => {
                    return index.id;
                  }}
                  onScroll={onScrollTemplate}
                >
                  {(item, index) => {
                    return (
                      <List.Item
                        key={item.id || index}
                        onClick={() => handleCatalogue(item)}
                        className={
                          activeCatalogue.id === item.id ? 'templateList active' : 'templateList'
                        }
                      >
                        {(isLoading || !item.id) ? (
                          <Skeleton avatar active>
                            <List.Item.Meta
                              avatar={<Avatar />}
                              title={<Skeleton.Input style={{ width: '40px' }} />}
                              description={<Skeleton.Input style={{ width: '80px' }} />}
                            />
                          </Skeleton>
                        ) : (
                          <List.Item.Meta
                            title={`${item.name.replace(/_/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase())}`}
                          />
                        )}
                      </List.Item>
                    );
                  }}
                </VirtualList>
              </List>
            </Col>
            <Col span={16} style={{ padding: '0 12px' }}>
              <p><b>Select Single Product</b></p>
              <div style={{ padding: '20px' }}>
                {productLoading ? (
                  <div>
                    <Spin />
                  </div>
                ) : (
                  products && (
                  <>
                    <div style={{ marginTop: '20px', textAlign: 'center' }}>
                      <Table
                        dataSource={products.filter(product => product.name.toLowerCase().includes(searchQuery.toLowerCase()))}
                        columns={productColumns}
                        rowKey="id"
                        pagination={false}
                      />
                    </div>
                    <div style={{ marginTop: '20px', textAlign: 'center' }}>
                      <Button
                        type="primary"
                        onClick={() => handlePreviousPage(activeCatalogue)}
                        disabled={!paging?.previous || productLoading}
                        style={{ marginRight: '8px' }}
                      >
                        Previous
                      </Button>
                      <Button
                        type="primary"
                        onClick={() => handleNextPage(activeCatalogue)}
                        disabled={!paging?.next || productLoading}
                      >
                        Next
                      </Button>
                    </div>

                  </>
                  )
                )}
              </div>
            </Col>
          </Row>
        </div>
      </Modal>

      <Modal
        title={<div className='templateTitle'>
          <ReactSVG src={templateSvg} className="templateSvg" />
          <span>Select Single Product</span>
        </div>}
        open={isMultiProductModalVisible}
        onCancel={() => setIsMultiProductModalVisible(false)}
        tmpBtnLoading
        footer={[<Button type="primary" style={{ width: '150px' }} icon={tmpBtnLoading ? (<Spin indicator={<LoadingOutlined spin />} />) : (<p style={{ padding: 0, margin: 0 }}>Select Product</p>)} size={'large'} onClick={() => handleSelectMultiProduct(selectedNodeId)} disabled={tmpBtnLoading} />]}
        centered
        width="800px"
      >
        <div className='templateData'>
          <Row>
            <Col span={8} className='templateDatacolumn'>
              <List>
                <VirtualList
                  data={catalogueData}
                  height={ContainerHeight}
                  itemHeight={47}
                  itemKey={(index) => {
                    return index.id;
                  }}
                  onScroll={onScrollTemplate}
                >
                  {(item, index) => {
                    return (
                      <List.Item
                        key={item.id || index}
                        onClick={() => handleCatalogue(item)}
                        className={
                          activeCatalogue.id === item.id ? 'templateList active' : 'templateList'
                        }
                      >
                        {(isLoading || !item.id) ? (
                          <Skeleton avatar active>
                            <List.Item.Meta
                              avatar={<Avatar />}
                              title={<Skeleton.Input style={{ width: '40px' }} />}
                              description={<Skeleton.Input style={{ width: '80px' }} />}
                            />
                          </Skeleton>
                        ) : (
                          <List.Item.Meta
                            title={`${item.name.replace(/_/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase())}`}
                          />
                        )}
                      </List.Item>
                    );
                  }}
                </VirtualList>
              </List>
            </Col>
            <Col span={16} style={{ padding: '0 12px' }}>
              <p><b>Select Multiple Products</b></p>
              <div style={{ padding: '20px' }}>
                {productLoading ? (
                  <div>
                    <Spin />
                  </div>
                ) : (
                  products && (
                  <>
                    <div style={{ marginTop: '20px', textAlign: 'center' }}>
                      <Table
                        dataSource={products.filter(product => product.name.toLowerCase().includes(searchQuery.toLowerCase()))}
                        columns={productColumnsOne}
                        rowKey="id"
                        pagination={false}
                      />
                    </div>
                    <div style={{ marginTop: '20px', textAlign: 'center' }}>
                      <Button
                        type="primary"
                        onClick={() => handlePreviousPage(activeCatalogue)}
                        disabled={!paging?.previous || productLoading}
                        style={{ marginRight: '8px' }}
                      >
                        Previous
                      </Button>
                      <Button
                        type="primary"
                        onClick={() => handleNextPage(activeCatalogue)}
                        disabled={!paging?.next || productLoading}
                      >
                        Next
                      </Button>
                    </div>

                  </>
                  )
                )}
              </div>
            </Col>
          </Row>
        </div>
      </Modal>

    </>
  );
};

const Flow = (userData) => {
  const location = useLocation();
  const record = location.state?.record;
  const is_edit = location.state?.is_edit;
  return (
    <ReactFlowProvider>
      <DnDFlow userData={userData} record={record} is_edit={is_edit} />
    </ReactFlowProvider>
  );
};

export default Flow;