/* eslint-disable no-const-assign */
// Chatbox.js
import React, { useEffect, useState, useRef, useCallback } from 'react';
import VirtualList from 'rc-virtual-list';
import _, { values } from 'lodash';
import { Typography, Drawer, Upload, Input, Button, Tabs, Space, List, Avatar, message, Col, Row, Tooltip, Empty, Card, Dropdown, Menu, Modal, Skeleton, Spin, Select, Form, DatePicker, Image } from 'antd';
import { ReactSVG } from 'react-svg';
import prioritychatSvg from '../assets/icons/prioritychat.svg';
import { LoadingOutlined, SnippetsOutlined, TagsOutlined, LinkOutlined, PhoneOutlined, MoreOutlined, SendOutlined, SmileOutlined, PaperClipOutlined, PlusOutlined, CloseOutlined, CheckOutlined, ClockCircleOutlined, UserAddOutlined, FileImageOutlined, FileVideoOutlined, FileTextOutlined, FilePdfOutlined, FilePptOutlined, FileWordOutlined, FileExcelOutlined, MenuFoldOutlined, MenuUnfoldOutlined, TagOutlined } from '@ant-design/icons';
import userSvg from '../assets/icons/user.svg';
import userMentionSvg from '../assets/icons/mention.svg';
import openchatrSvg from '../assets/icons/openchat.svg';
import resolvedchatSvg from '../assets/icons/resolvedchat.svg';
import unassignchatSvg from '../assets/icons/unassignchat.svg';
import templateSvg from '../assets/icons/template.svg';
import textIcon from '../assets/attachmenticons/text.png';
import fileIcon from '../assets/attachmenticons/file.png';
import excelIcon from '../assets/attachmenticons/excel.png';
import pdfIcon from '../assets/attachmenticons/pdf.png';
import pptIcon from '../assets/attachmenticons/ppt.png';
import wordIcon from '../assets/attachmenticons/word.png';
import { debounce } from 'lodash';
import Picker from '@emoji-mart/react';
import { Block, Label } from '@mui/icons-material';
const { Option } = Select;
const { Title } = Typography;

const onChange = (key) => {
    console.log(key);
};
const { Search } = Input;
const { TextArea } = Input;

const ContainerHeight = 519;
const ContainerHeightOne = `calc(100vh - 204px)`;

const getBase64One = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
};

const Chatbox = (userData) => {
    const [data, setData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [templatedata, setTemplatedata] = useState([]);
    const [activeItem, setActiveItem] = useState([]);
    const activeItemRef = useRef(activeItem);
    const [selectedTags, setSelectedTags] = useState({});
    const [activeTemplateItem, setActiveTemplateItem] = useState([]);
    const [chatContent, setChatContent] = useState({});
    const [chatContainer, setChatContainer] = useState([]);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [flowId, setFlowId] = useState('');
    const [templateImageId, setTemplateImageId] = useState('');
    const [userMenu, setUserMenu] = useState(false);
    const [templateImageExtension, setTemplateImageExtension] = useState('');
    const [templateImage, setTemplateImage] = useState('');
    const [fileList, setFileList] = useState([]);
    const [filesToUpload, setFilesToUpload] = useState([]);
    const [previewVisible, setPreviewVisible] = useState(false);
    const [tagLoading, setTagLoading] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [imageList, setImageList] = useState([]);
    const [activeImageUid, setActiveImageUid] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [attachmentLoader, setAttachmentLoader] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [hasMoreData, setHasMoreData] = useState(true);
    const chatContainerRef = useRef(null);
    const [imageUploadLoading, setImageUploadLoading] = useState(false);
    const [showEmojis, setShowEmojis] = useState(false);
    const [showNotesEmojis, setNotesShowEmojis] = useState(false);
    const [textAreaMessage, setTextAreaMessage] = useState('');
    const [textAreaDisabled, setTextAreaDisabled] = useState(true);
    const textAreaRef = useRef();
    const textAreaOneRef = useRef();
    const [form] = Form.useForm();
    const [formOne] = Form.useForm();
    const [textAreaMessageOne, setTextAreaMessageOne] = useState('');
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [tmpBtnLoading, setTmpBtnLoading] = useState(false);
    const [msgBtnLoading, setMsgBtnLoading] = useState(false);
    const [notesTextAreaMessage, setNotesTextAreaMessage] = useState('');
    const [templatePreview, setTemplatePreview] = useState([]);
    const [filterOption, setFilterOption] = useState([]);
    const [selectedValues, setSelectedValues] = useState({});
    const [notes, setNotes] = useState([]);
    const [tagsData, setTagsData] = useState([]);
    const [assignedTagsData, setAssignedTagsData] = useState([]);
    const [tegPopup, setTegPopup] = useState(false);
    const [tagsearchTerm, setTagSearchTerm] = useState('');
    // const [socket, setSocket] = useState(null);
    // const [fromImgLoading, setFromImgLoading] = useState(false);
    const [templateType, setTemplateType] = useState('custom');
    const textAreaRefOne = useRef();
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalImageUrl, setModalImageUrl] = useState('');
    const [noteLoader, setNoteLoader] = useState(false);
    const [noteViewLoader, setNoteViewLoader] = useState(false);
    const [teamMembers, setTeamMembers] = useState([]);
    const [quickReplies, setQuickReplies] = useState([]);
    const [assignLoader, setAssignLoader] = useState(false);
    const maxSize = 5 * 1024 * 1024;


    const openModal = (imageUrl) => {
        // console.log('modal');
        // setModalImageUrl(imageUrl);
        // setModalIsOpen(true);
    };



    // Handler for search input change
    const handleSearchChange = (event) => {
        setTagSearchTerm(event.target.value);
    };

    // Filtered tags based on search term
    const getFilteredTags = () => {
        return tagsData
            ? tagsData.filter(tag =>
                tag.tag_name.toLowerCase().includes(tagsearchTerm.toLowerCase())
            )
            : [];
    };

    // The list of filtered tags
    const filteredTags = getFilteredTags();

    const handleToggleTag = (tagId) => {
        setSelectedTags((prevSelectedTags) => ({
            ...prevSelectedTags,
            [tagId]: !prevSelectedTags[tagId],
        }));
    };

    const handleAssignTags = async () => {
        const selectedTagIds = Object.keys(selectedTags).filter((tagId) => selectedTags[tagId]);
        console.log(selectedTagIds);
        try {
            const response = await fetch('https://connectby.io:3001/api/assign-tags', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    customer_id: activeItem?.customer_id,
                    tag_ids: JSON.stringify(selectedTagIds),
                }),
            });
            if (response.ok) {
                setActiveItem((prevItem) => ({
                    ...prevItem,
                    tags: JSON.stringify(selectedTagIds),
                }));
                getSelectedTags(JSON.stringify(selectedTagIds));
                message.success('Tags assigned successfully.');
            } else {
                const errorMessage = await response.json();
                message.error(errorMessage.message || 'Unknown error');
            }

        } catch (error) {
            console.log(error);
            console.error('Error Creating Tag:', error);
        }
    }
    const closeModal = () => setModalIsOpen(false);

    const isMessageOld = async (item) => {
        const getStatus = `https://connectby.io:3001/api/get-last-msg-status?phoneid=${userData?.userData[0]?.phonenumberid}&phonenumber=${item?.country_code}${item.customer_phone}`;
        try {
            const response = await fetch(getStatus);
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const data = await response.json();
            if (data) {
                const messageDate = new Date(data[0].created * 1000);
                const currentDate = new Date();
                const hoursDifference = (currentDate - messageDate) / (1000 * 60 * 60);
                return hoursDifference > 24;
            } else {
                return true;
            }
        } catch (error) {
            return true;
        } finally {

        }
    };

    useEffect(() => {
        function newchat() {
            let ws = new WebSocket('wss://connectby.io:3001');
            ws.onopen = () => {
                console.log('WebSocket connected');
            };

            ws.onmessage = (event) => {
                try {
                    const data = JSON.parse(event.data);
                    if (data?.is_message === 'to') {
                        const tomessagesNumber = data?.wanumber;
                        let toactivePhone = '';
                        if (activeItemRef.current && activeItemRef.current.length !== 0) {
                            toactivePhone = formatPhoneId(activeItemRef.current?.country_code, activeItemRef.current.customer_phone);
                        } else {
                            toactivePhone = "notactive";
                        }
                        console.log(tomessagesNumber);
                        console.log(toactivePhone);
                        if (tomessagesNumber === toactivePhone) {
                            const tonewReceivedMessage = {
                                wamid: data?.wamid,
                                mainid: data?.mainid,
                                is_message: data?.is_message,
                                wanumber: data?.wanumber,
                                msgtype: data?.msgtype,
                                message: data?.message,
                                callback: data?.callback,
                                created: data?.created,
                                status: 'read',
                            };
                            setChatContainer(prevChat => [...prevChat, tonewReceivedMessage]);
                        }
                    }
                    const dataphonenumberid = data.entry[0]?.changes[0]?.value?.metadata?.phone_number_id;
                    if (dataphonenumberid === userData?.userData[0]?.phonenumberid) {
                        console.log('Received JSON data:', data);
                        if (data.entry && data.entry.length > 0 && data.entry[0].changes) {
                            const messagesChange = data.entry[0].changes.find(change => change.field === 'messages');
                            if (messagesChange && messagesChange.value.messages) {
                                const receivedMessage = messagesChange.value.messages[0];
                                const messagesNumber = data.entry[0]?.changes[0]?.value?.contacts?.[0]?.wa_id;
                                const messagesName = data.entry[0]?.changes[0]?.value?.contacts?.[0]?.profile?.name;
                                let activephone = '';
                                if (activeItemRef.current && activeItemRef.current.length !== 0) {
                                    activephone = formatPhoneId(activeItemRef.current?.country_code, activeItemRef.current.customer_phone);
                                } else {
                                    activephone = "notactive";
                                }
                                if (receivedMessage.type === 'text') {
                                    const newReceivedMessage = {
                                        wamid: data.entry[0].id,
                                        mainid: receivedMessage.id,
                                        is_message: 'from',
                                        wanumber: messagesNumber,
                                        msgtype: receivedMessage.type,
                                        message: receivedMessage?.text?.body,
                                        callback: JSON.stringify(data),
                                        created: Math.floor(Date.now() / 1000).toString(),
                                        status: 'received',
                                    };
                                    if (activephone === 'notactive') {
                                        if (data.user_data.is_user === 'new') {
                                            const newUserData = {
                                                customer_id: data.user_data.user_id,
                                                country_code: messagesNumber.substring(0, 2),
                                                customer_phone: messagesNumber.substring(2),
                                                customer_name: messagesName,
                                                created: Math.floor(Date.now() / 1000).toString(),
                                                message: receivedMessage?.text?.body,
                                                msgtype: receivedMessage.type,
                                                msgstatus: "unread"
                                            };
                                            setData((prevData) => [newUserData, ...prevData]);
                                        } else {
                                            setData((PrevData) => {
                                                const updatedData = PrevData.map((userdata) => {
                                                    if (formatPhoneId(userdata.country_code, userdata.customer_phone) === messagesNumber) {
                                                        return { ...userdata, created: Math.floor(Date.now() / 1000).toString(), message: receivedMessage?.text?.body, msgstatus: "unread", msgtype: receivedMessage.type };
                                                    }
                                                    return userdata;
                                                });
                                                const movedUser = updatedData.find((userdata) => formatPhoneId(userdata.country_code, userdata.customer_phone) === messagesNumber);
                                                const restOfUsers = updatedData.filter((userdata) => formatPhoneId(userdata.country_code, userdata.customer_phone) !== messagesNumber);
                                                return [movedUser, ...restOfUsers];
                                            });
                                        }
                                    } else {
                                        if (activephone === messagesNumber) {
                                            setChatContainer(prevChat => [...prevChat, newReceivedMessage]);
                                            setTextAreaDisabled(false);
                                            updateReceivedMessage(newReceivedMessage);
                                        } else {
                                            setData((PrevData) => {
                                                const updatedData = PrevData.map((userdata) => {
                                                    if (formatPhoneId(userdata.country_code, userdata.customer_phone) === messagesNumber) {
                                                        return { ...userdata, created: Math.floor(Date.now() / 1000).toString(), message: receivedMessage?.text?.body, msgstatus: "unread", msgtype: receivedMessage.type };
                                                    }
                                                    return userdata;
                                                });
                                                const movedUser = updatedData.find((userdata) => formatPhoneId(userdata.country_code, userdata.customer_phone) === messagesNumber);
                                                const restOfUsers = updatedData.filter((userdata) => formatPhoneId(userdata.country_code, userdata.customer_phone) !== messagesNumber);
                                                return [movedUser, ...restOfUsers];
                                            });
                                        }
                                    }
                                }
                                if (receivedMessage.type === 'image') {
                                    const messagesNumber = data.entry[0]?.changes[0]?.value?.contacts?.[0]?.wa_id;
                                    const newReceivedMessage = {
                                        wamid: data.entry[0].id,
                                        mainid: receivedMessage.id,
                                        is_message: 'from',
                                        wanumber: messagesNumber,
                                        msgtype: receivedMessage.type,
                                        message: '',
                                        image: {
                                            mime_type: receivedMessage.image.mime_type,
                                            sha256: receivedMessage.image.sha256,
                                            id: receivedMessage.image.id,
                                        },
                                        callback: JSON.stringify(data),
                                        created: Math.floor(Date.now() / 1000).toString(),
                                        status: 'received',
                                    };
                                    if (activephone === 'notactive') {
                                        setData((PrevData) => {
                                            const updatedData = PrevData.map((userdata) => {
                                                if (formatPhoneId(userdata.country_code, userdata.customer_phone) === messagesNumber) {
                                                    return { ...userdata, created: Math.floor(Date.now() / 1000).toString(), message: 'image', msgstatus: "unread", msgtype: receivedMessage.type };
                                                }
                                                return userdata;
                                            });
                                            const movedUser = updatedData.find((userdata) => formatPhoneId(userdata.country_code, userdata.customer_phone) === messagesNumber);
                                            const restOfUsers = updatedData.filter((userdata) => formatPhoneId(userdata.country_code, userdata.customer_phone) !== messagesNumber);
                                            return [movedUser, ...restOfUsers];
                                        });
                                    } else {
                                        if (activephone === messagesNumber) {
                                            renderReceivedImage(receivedMessage.id, receivedMessage.image.id);
                                            setChatContainer(prevChat => [...prevChat, newReceivedMessage]);
                                            updateReceivedMessage(newReceivedMessage);
                                        } else {
                                            setData((PrevData) => {
                                                const updatedData = PrevData.map((userdata) => {
                                                    if (formatPhoneId(userdata.country_code, userdata.customer_phone) === messagesNumber) {
                                                        return { ...userdata, created: Math.floor(Date.now() / 1000).toString(), message: 'image', msgstatus: "unread", msgtype: receivedMessage.type };
                                                    }
                                                    return userdata;
                                                });
                                                const movedUser = updatedData.find((userdata) => formatPhoneId(userdata.country_code, userdata.customer_phone) === messagesNumber);
                                                const restOfUsers = updatedData.filter((userdata) => formatPhoneId(userdata.country_code, userdata.customer_phone) !== messagesNumber);
                                                return [movedUser, ...restOfUsers];
                                            });
                                        }
                                    }

                                }
                                if (receivedMessage.type === 'video') {
                                    const messagesNumber = data.entry[0]?.changes[0]?.value?.contacts?.[0]?.wa_id;
                                    const newReceivedMessage = {
                                        wamid: data.entry[0].id,
                                        mainid: receivedMessage.id,
                                        is_message: 'from',
                                        wanumber: messagesNumber,
                                        msgtype: receivedMessage.type,
                                        message: '',
                                        video: {
                                            mime_type: receivedMessage.video.mime_type,
                                            sha256: receivedMessage.video.sha256,
                                            id: receivedMessage.video.id,
                                        },
                                        callback: JSON.stringify(data),
                                        created: Math.floor(Date.now() / 1000).toString(),
                                        status: 'received',
                                    };
                                    if (activephone === 'notactive') {
                                        setData((PrevData) => {
                                            const updatedData = PrevData.map((userdata) => {
                                                if (formatPhoneId(userdata.country_code, userdata.customer_phone) === messagesNumber) {
                                                    return { ...userdata, created: Math.floor(Date.now() / 1000).toString(), message: 'video', msgstatus: "unread", msgtype: receivedMessage.type };
                                                }
                                                return userdata;
                                            });
                                            const movedUser = updatedData.find((userdata) => formatPhoneId(userdata.country_code, userdata.customer_phone) === messagesNumber);
                                            const restOfUsers = updatedData.filter((userdata) => formatPhoneId(userdata.country_code, userdata.customer_phone) !== messagesNumber);
                                            return [movedUser, ...restOfUsers];
                                        });
                                    } else {
                                        if (activephone === messagesNumber) {
                                            renderReceivedVideo(receivedMessage.id, receivedMessage.video.id);
                                            setChatContainer(prevChat => [...prevChat, newReceivedMessage]);
                                            updateReceivedMessage(newReceivedMessage);
                                        } else {
                                            setData((PrevData) => {
                                                const updatedData = PrevData.map((userdata) => {
                                                    if (formatPhoneId(userdata.country_code, userdata.customer_phone) === messagesNumber) {
                                                        return { ...userdata, created: Math.floor(Date.now() / 1000).toString(), message: 'video', msgstatus: "unread", msgtype: receivedMessage.type };
                                                    }
                                                    return userdata;
                                                });
                                                const movedUser = updatedData.find((userdata) => formatPhoneId(userdata.country_code, userdata.customer_phone) === messagesNumber);
                                                const restOfUsers = updatedData.filter((userdata) => formatPhoneId(userdata.country_code, userdata.customer_phone) !== messagesNumber);
                                                return [movedUser, ...restOfUsers];
                                            });
                                        }
                                    }
                                }
                                if (receivedMessage.type === 'audio') {
                                    const messagesNumber = data.entry[0]?.changes[0]?.value?.contacts?.[0]?.wa_id;
                                    const newReceivedMessage = {
                                        wamid: data.entry[0].id,
                                        mainid: receivedMessage.id,
                                        is_message: 'from',
                                        wanumber: messagesNumber,
                                        msgtype: receivedMessage.type,
                                        message: '',
                                        audio: {
                                            mime_type: receivedMessage.audio.mime_type,
                                            sha256: receivedMessage.audio.sha256,
                                            id: receivedMessage.audio.id,
                                        },
                                        callback: JSON.stringify(data),
                                        created: Math.floor(Date.now() / 1000).toString(),
                                        status: 'received',
                                    };
                                    if (activephone === 'notactive') {
                                        setData((PrevData) => {
                                            const updatedData = PrevData.map((userdata) => {
                                                if (formatPhoneId(userdata.country_code, userdata.customer_phone) === messagesNumber) {
                                                    return { ...userdata, created: Math.floor(Date.now() / 1000).toString(), message: 'audio', msgstatus: "unread", msgtype: receivedMessage.type };
                                                }
                                                return userdata;
                                            });
                                            const movedUser = updatedData.find((userdata) => formatPhoneId(userdata.country_code, userdata.customer_phone) === messagesNumber);
                                            const restOfUsers = updatedData.filter((userdata) => formatPhoneId(userdata.country_code, userdata.customer_phone) !== messagesNumber);
                                            return [movedUser, ...restOfUsers];
                                        });
                                    } else {
                                        if (activephone === messagesNumber) {
                                            renderReceivedAudio(receivedMessage.id, receivedMessage.audio.id);
                                            setChatContainer(prevChat => [...prevChat, newReceivedMessage]);
                                            updateReceivedMessage(newReceivedMessage);
                                        } else {
                                            setData((PrevData) => {
                                                const updatedData = PrevData.map((userdata) => {
                                                    if (formatPhoneId(userdata.country_code, userdata.customer_phone) === messagesNumber) {
                                                        return { ...userdata, created: Math.floor(Date.now() / 1000).toString(), message: 'audio', msgstatus: "unread", msgtype: receivedMessage.type };
                                                    }
                                                    return userdata;
                                                });
                                                const movedUser = updatedData.find((userdata) => formatPhoneId(userdata.country_code, userdata.customer_phone) === messagesNumber);
                                                const restOfUsers = updatedData.filter((userdata) => formatPhoneId(userdata.country_code, userdata.customer_phone) !== messagesNumber);
                                                return [movedUser, ...restOfUsers];
                                            });
                                        }
                                    }
                                }
                                if (receivedMessage.type === 'document') {
                                    const messagesNumber = data.entry[0]?.changes[0]?.value?.contacts?.[0]?.wa_id;
                                    const newReceivedMessage = {
                                        wamid: data.entry[0].id,
                                        mainid: receivedMessage.id,
                                        is_message: 'from',
                                        wanumber: messagesNumber,
                                        msgtype: receivedMessage.type,
                                        message: '',
                                        document: {
                                            mime_type: receivedMessage.document.mime_type,
                                            sha256: receivedMessage.document.sha256,
                                            id: receivedMessage.document.id,
                                        },
                                        callback: JSON.stringify(data),
                                        created: Math.floor(Date.now() / 1000).toString(),
                                        status: 'received',
                                    };
                                    if (activephone === 'notactive') {
                                        setData((PrevData) => {
                                            const updatedData = PrevData.map((userdata) => {
                                                if (formatPhoneId(userdata.country_code, userdata.customer_phone) === messagesNumber) {
                                                    return { ...userdata, created: Math.floor(Date.now() / 1000).toString(), message: 'document', msgstatus: "unread", msgtype: receivedMessage.type };
                                                }
                                                return userdata;
                                            });
                                            const movedUser = updatedData.find((userdata) => formatPhoneId(userdata.country_code, userdata.customer_phone) === messagesNumber);
                                            const restOfUsers = updatedData.filter((userdata) => formatPhoneId(userdata.country_code, userdata.customer_phone) !== messagesNumber);
                                            return [movedUser, ...restOfUsers];
                                        });
                                    } else {
                                        if (activephone === messagesNumber) {
                                            renderReceivedDocument(receivedMessage.id, receivedMessage.document.id);
                                            setChatContainer(prevChat => [...prevChat, newReceivedMessage]);
                                            updateReceivedMessage(newReceivedMessage);
                                        } else {
                                            setData((PrevData) => {
                                                const updatedData = PrevData.map((userdata) => {
                                                    if (formatPhoneId(userdata.country_code, userdata.customer_phone) === messagesNumber) {
                                                        return { ...userdata, created: Math.floor(Date.now() / 1000).toString(), message: 'document', msgstatus: "unread", msgtype: receivedMessage.type };
                                                    }
                                                    return userdata;
                                                });
                                                const movedUser = updatedData.find((userdata) => formatPhoneId(userdata.country_code, userdata.customer_phone) === messagesNumber);
                                                const restOfUsers = updatedData.filter((userdata) => formatPhoneId(userdata.country_code, userdata.customer_phone) !== messagesNumber);
                                                return [movedUser, ...restOfUsers];
                                            });
                                        }
                                    }

                                }
                            }
                            if (messagesChange && messagesChange.value.statuses) {
                                const statuses = messagesChange.value.statuses[0];
                                const ststusid = statuses.id;
                                const status = statuses.status;
                                updateStatus(ststusid, status);
                            }
                        }
                    }
                } catch (error) {
                    console.log(error);
                    console.log('Received non-JSON data:', event.data);
                }
            };

            ws.onclose = () => {
                console.log('WebSocket closed');
                setTimeout(() => {
                    newchat();
                }, 3000);
            };
        }
        newchat();
    }, []);

    useEffect(() => {
        if (activeItem) {
            activeItemRef.current = activeItem;
            console.log("activeItem", activeItem);
            form.setFieldsValue({
                customer_id: activeItem?.customer_id,
                customer_name: activeItem.customer_name,
                customer_phone: activeItem.customer_phone,
                customer_email: activeItem.customer_email,
                customer_gender: activeItem.customer_gender
            });
        }
    }, [form, activeItem]);

    function formatPhoneId(countryCode, phoneNumber) {
        if (countryCode.startsWith('+')) {
            countryCode = countryCode.substring(1);
        }
        return countryCode + phoneNumber;
    }

    const updateReceivedMessage = async (newReceivedMessage) => {

        try {
            const response = await fetch('https://connectby.io:3001/api/update-received-message-status', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'whatsappaccesstoken': userData?.userData[0]?.whatsappAccessToken,
                    'phonenumberid': userData?.userData[0]?.phonenumberid
                },
                body: JSON.stringify({
                    message_id: newReceivedMessage.mainid,
                    wamid: newReceivedMessage.wamid,
                    wanumber: newReceivedMessage.wanumber
                }),
            });
            if (!response.ok) {
                throw new Error(`Update Status failed with status ${response.status}`);
            }
            const data = await response.json();
            console.log('Update Status API response:', data);
        } catch (error) {
            console.error('Error updating status:', error);
        }
    };

    const updateStatus = async (statusId, newStatus) => {
        // console.log(statusId);
        // console.log(newStatus);
        setChatContainer((prevChatContainer) => {
            return prevChatContainer.map((chat) => {
                if (chat.mainid === statusId) {
                    if (chat.status !== 'read') {
                        return { ...chat, status: newStatus };
                    }
                }
                return chat;
            });
        });
        // try {
        //     const response = await fetch('https://connectby.io:3001/api/update-status', {
        //         method: 'POST',
        //         headers: {
        //             'Content-Type': 'application/json',
        //         },
        //         body: JSON.stringify({
        //             id: statusId,
        //             status: newStatus,
        //         }),
        //     });
        //     if (!response.ok) {
        //         throw new Error(`Update Status failed with status ${response.status}`);
        //     }
        //     const data = await response.json();
        //     console.log('Update Status API response:', data);
        //     console.log(chatContainer);
        // } catch (error) {
        //     console.error('Error updating status:', error);
        // }
    };

    const renderReceivedAudio = async (mainid, audio) => {
        try {
            const response = await fetch(`https://connectby.io:3001/api/download-media/${audio}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'whatsappaccesstoken': userData?.userData[0]?.whatsappAccessToken,
                    'phonenumberid': userData?.userData[0]?.phonenumberid
                }
            });
            const result = await response.json();
            if (response.ok) {
                setChatContainer((prevChatContainer) => {
                    return prevChatContainer.map((chat) => {
                        if (chat.mainid === mainid) {
                            return { ...chat, audio: { url: `https://api.connectby.io/upload/${audio}.${getExtensionFromMimeType(result.mediaDetails.mime_type)}` } };
                        }
                        return chat;
                    });
                });
            } else {
                console.error('Failed to download media file:', result.error);
            }
        } catch (error) {
            console.error('Error downloading media:', error);
        } finally {
            // setFromImgLoading(false);
        }
    };

    const renderReceivedVideo = async (mainid, video) => {
        try {
            const response = await fetch(`https://connectby.io:3001/api/download-media/${video}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'whatsappaccesstoken': userData?.userData[0]?.whatsappAccessToken,
                    'phonenumberid': userData?.userData[0]?.phonenumberid
                }
            });
            const result = await response.json();
            if (response.ok) {
                setChatContainer((prevChatContainer) => {
                    return prevChatContainer.map((chat) => {
                        if (chat.mainid === mainid) {
                            return { ...chat, video: { url: `https://api.connectby.io/upload/${video}.${getExtensionFromMimeType(result.mediaDetails.mime_type)}` } };
                        }
                        return chat;
                    });
                });
            } else {
                console.error('Failed to download media file:', result.error);
            }
        } catch (error) {
            console.error('Error downloading media:', error);
        } finally {
            // setFromImgLoading(false);
        }
    };

    const renderReceivedDocument = async (mainid, document) => {
        if (document) {
            try {
                const response = await fetch(`https://connectby.io:3001/api/download-media/${document}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'whatsappaccesstoken': userData?.userData[0]?.whatsappAccessToken,
                        'phonenumberid': userData?.userData[0]?.phonenumberid
                    }
                });
                const result = await response.json();
                if (response.ok) {
                    setChatContainer((prevChatContainer) => {
                        return prevChatContainer.map((chat) => {
                            if (chat.mainid === mainid) {
                                return { ...chat, document: { url: `https://api.connectby.io/upload/${document}.${getExtensionFromMimeType(result.mediaDetails.mime_type)}` } };
                            }
                            return chat;
                        });
                    });
                } else {
                    console.error('Failed to download media file:', result.error);
                }
            } catch (error) {
                console.error('Error downloading media:', error);
            } finally {
                // setFromImgLoading(false);
            }
        }
    };

    const renderReceivedImage = async (mainid, image) => {
        // setFromImgLoading(true);

        try {
            const response = await fetch(`https://connectby.io:3001/api/download-media/${image}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'whatsappaccesstoken': userData?.userData[0]?.whatsappAccessToken,
                    'phonenumberid': userData?.userData[0]?.phonenumberid
                }
            });
            const result = await response.json();
            if (response.ok) {
                setChatContainer((prevChatContainer) => {
                    return prevChatContainer.map((chat) => {
                        if (chat.mainid === mainid) {
                            return { ...chat, image: { url: `https://api.connectby.io/upload/${image}.${getExtensionFromMimeType(result.mediaDetails.mime_type)}` } };
                        }
                        return chat;
                    });
                });
            } else {
                console.error('Failed to download media file:', result.error);
            }
        } catch (error) {
            console.error('Error downloading media:', error);
        } finally {
            // setFromImgLoading(false);
        }
    };

    const uploadButton = (
        <button
            style={{
                border: 0,
                background: 'none',
            }}
            type="button"
        >
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload
            </div>
        </button>
    );

    const onVarChange = (element, value) => {
        setSelectedValues(prevValues => ({
            ...prevValues,
            [element]: value,
        }));
    };

    const onVarSearch = (value) => {
        console.log('search:', value);
    };

    const inputfilterOption = (input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    const opentemplate = (item) => {
        setActiveTemplateItem(item);
        setSelectedValues({});
        if (item && Array.isArray(item.components)) {
            setTemplatePreview(item.components);
            item.components && item.components.length > 0 && item.components.forEach((component) => {
                if(component.type === "BUTTONS"){
                    component.buttons.forEach((button) => {
                        if(button.type === "FLOW"){
                            console.log(button);
                            setTemplateType('flow');
                            setFlowId(button.flow_id);
                        }else{
                            setFlowId('');
                            setTemplateType('custom');
                        }
                    });
                }
            });
        }
        
    };

    useEffect(() => {
        if (activeTemplateItem && Array.isArray(activeTemplateItem.components)) {
            setTemplatePreview(activeTemplateItem.components);
            activeTemplateItem.components && activeTemplateItem.components.length > 0 && activeTemplateItem.components.forEach((component) => {
                if(component.type === "BUTTONS"){
                    component.buttons.forEach((button) => {
                        if(button.type === "FLOW"){
                            setTemplateType('flow');
                            setFlowId(button.flow_id);
                        }else{
                            setFlowId('');
                            setTemplateType('custom');
                        }
                    });
                }
            });
            let countryCode = activeItem.country_code;
            if (countryCode.startsWith('+')) {
                countryCode = countryCode.substring(1);
            }
            let options = [
                {
                    value: activeItem.customer_name,
                    label: 'Name',
                },
                {
                    value: activeItem.customer_email,
                    label: 'Email',
                },
                {
                    value: countryCode + activeItem.customer_phone,
                    label: 'Phone Number',
                },
            ];
            setFilterOption(options);
        } else {
            console.error('Invalid item format:', activeTemplateItem);
        }
    }, [activeTemplateItem, templatePreview, isModalVisible]);

    const handleSendMessage = async () => {
        if (msgBtnLoading) return;
        const activerecept = activeItem;
        let countryCode = activerecept.country_code;
        if (countryCode.startsWith('+')) {
            countryCode = countryCode.substring(1);
        }
        const wanumber = countryCode + activerecept.customer_phone;
        const textmessage = textAreaMessage;
        try {
            setMsgBtnLoading(true);
            const response = await fetch('https://connectby.io:3001/api/send-message', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'whatsappaccesstoken': userData?.userData[0]?.whatsappAccessToken,
                    'phonenumberid': userData?.userData[0]?.phonenumberid
                },
                body: JSON.stringify({
                    to: wanumber,
                    message: textmessage,
                }),
            });
            const responseData = await response.json();
            if (response.ok) {
                message.success('Message sent');
                const sentMessage = {
                    created: Date.now() / 1000,
                    is_message: 'to',
                    mainid: responseData.responseData.messages[0].id,
                    msgtype: 'text',
                    message: textmessage,
                    status: 'sending',
                };
                setChatContainer(prevChat => [...prevChat, sentMessage]);
                setData((PrevData) => {
                    const updatedData = PrevData.map((userdata) => {
                        if (formatPhoneId(userdata.country_code, userdata.customer_phone) === wanumber) {
                            return { ...userdata, created: Math.floor(Date.now() / 1000).toString(), message: textmessage, msgstatus: "read" };
                        }
                        return userdata;
                    });
                    const movedUser = updatedData.find((userdata) => formatPhoneId(userdata.country_code, userdata.customer_phone) === wanumber);
                    const restOfUsers = updatedData.filter((userdata) => formatPhoneId(userdata.country_code, userdata.customer_phone) !== wanumber);
                    return [movedUser, ...restOfUsers];
                });
            } else {
                message.error(responseData.error || 'Unknown error');
            }
        } catch (error) {
            console.error('Error sending WhatsApp Message:', error);
        } finally {
            setMsgBtnLoading(false);
            setTextAreaMessage('');
        }
    };
    const [isPopupVisible, setIsPopupVisible] = useState(false);

    const handleKeyDown = (e) => {
        if (e.key === '/') {
            if (textAreaMessage === '') {
                e.preventDefault();
                setIsPopupVisible(true);
            }
        }

        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            handleSendMessage();
        }
    };

    const handleKeyDownOne = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            handleNote();
        }
    };

    const handleSendTemplate = async () => {
        const activerecept = activeItem;
        let countryCode = activerecept.country_code;
        if (countryCode.startsWith('+')) {
            countryCode = countryCode.substring(1);
        }
        const template = _.cloneDeep(activeTemplateItem);
        const templateName = template.name;
        const templateLanguage = template.language;
        const wanumber = countryCode + activerecept.customer_phone;
        const variables = selectedValues;
        const imageId = templateImageId;
        const imageExtension = templateImageExtension;
        let datacomponent = [];
        const headerComponent = template?.components?.find(component => component.type === "HEADER");
        if (headerComponent) {
            if (headerComponent.format === 'IMAGE') {
                if (imageId === '') {
                    message.error('Please Upload the image');
                    return;
                }
            }
        }
        template?.components?.forEach(component => {
            if (component.type === "HEADER") {
                const header = {
                    type: "header",
                    parameters: []
                };

                if (component.format === 'TEXT') {
                    let replacedHeader = component.text;
                    component.text.match(/{{\d+}}/g)?.forEach(element => {
                        const replacement = variables[`HEADER-${element}`];
                        replacedHeader = replacedHeader.replace(element, replacement);
                        header.parameters.push({
                            type: "text",
                            text: variables[`HEADER-${element}`]
                        });
                    });
                    component.text = replacedHeader;
                }

                if (component.format === 'IMAGE') {
                    if (imageExtension === 'image/png') {
                        component.example.header_handle[0] = `https://api.connectby.io/whatsapp_media/${imageId}.png`;
                    }
                    if (imageExtension === 'image/jpeg') {
                        component.example.header_handle[0] = `https://api.connectby.io/whatsapp_media/${imageId}.jpeg`;
                    }
                    header.parameters.push({
                        type: "image",
                        image: {
                            id: imageId
                        }
                    });
                }

                if (header.parameters.length > 0) {
                    datacomponent.push(header);
                }
            }

            if (component.type === "BODY") {
                const body = {
                    type: "body",
                    parameters: []
                };
                let replacedBody = component.text;
                if (component.text.match(/{{\d+}}/g)) {
                    component.text.match(/{{\d+}}/g)?.forEach(element => {
                        const replacement = variables[`BODY-${element}`];
                        replacedBody = replacedBody.replace(element, replacement);
                        body.parameters.push({
                            type: "text",
                            text: variables[`BODY-${element}`]
                        });
                    });
                }
                component.text = replacedBody;
                if (body.parameters.length > 0) {
                    datacomponent.push(body);
                }
            }
        });
        if(templateType === 'flow'){
            const button = {
                type: "button",
                sub_type: "flow",
                "index": "0",
                parameters: [
                    {
                        "type": "action",
                        "action": {
                        "flow_token": `${wanumber}-${flowId}`,
                        },
                    },
                ]
            };
            datacomponent.push(button);
        }
        try {
            setTmpBtnLoading(true);
            const response = await fetch('https://connectby.io:3001/api/send-whatsapp-template', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'whatsappaccesstoken': userData?.userData[0]?.whatsappAccessToken,
                    'phonenumberid': userData?.userData[0]?.phonenumberid
                },
                body: JSON.stringify({
                    to: wanumber,
                    template_name: templateName,
                    language: templateLanguage,
                    template: template,
                    components: datacomponent,
                }),
            });
            const responseData = await response.json();
            if (response.ok) {
                const sentMessage = {
                    created: Date.now() / 1000,
                    is_message: 'to',
                    mainid: responseData.responseData.messages[0].id,
                    msgtype: 'template',
                    message: templateName,
                    status: 'sending',
                    template: JSON.stringify(template)
                };
                setChatContainer(prevChat => [...prevChat, sentMessage]);
                setData((PrevData) => {
                    const updatedData = PrevData.map((userdata) => {
                        if (formatPhoneId(userdata.country_code, userdata.customer_phone) === wanumber) {
                            return { ...userdata, created: Math.floor(Date.now() / 1000).toString(), message: 'template', msgstatus: "read" };
                        }
                        return userdata;
                    });
                    const movedUser = updatedData.find((userdata) => formatPhoneId(userdata.country_code, userdata.customer_phone) === wanumber);
                    const restOfUsers = updatedData.filter((userdata) => formatPhoneId(userdata.country_code, userdata.customer_phone) !== wanumber);
                    return [movedUser, ...restOfUsers];
                });
                message.success('Template sent successfully');
                console.log('WhatsApp template sent successfully:', responseData);
            } else {
                message.error(responseData.error || 'Unknown error');
                console.error('Failed to send WhatsApp template:', responseData.error || 'Unknown error');
            }
        } catch (error) {
            console.error('Error sending WhatsApp template:', error);
            message.error('Internal Server Error');
        } finally {
            setTmpBtnLoading(false);
            setIsModalVisible(false);
        }
    };

    const customRequestImage = () => {
        return async ({ action, data, file, filename, headers, onError, onProgress, onSuccess }) => {
            try {
                const formData = new FormData();
                formData.append('file', file);
                const fileType = file.type;
                console.log(fileType);
                const response = await fetch(action, {
                    method: 'POST',
                    body: formData,
                    headers: {
                        'whatsappaccesstoken': userData?.userData[0]?.whatsappAccessToken,
                        'phonenumberid': userData?.userData[0]?.phonenumberid
                    },
                });
                const responseData = await response.json();
                setTemplateImageId(responseData.id);
                setTemplateImageExtension(fileType);
                onSuccess(responseData, file);
            } catch (error) {
                onError(error);
                console.log(error);
            }
        };
    };

    const showTemplates = () => {
        setIsModalVisible(true);
    };

    useEffect(() => {
        const templatedataUrl = `https://connectby.io:3001/api/get-template`;
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'whatsappaccesstoken': userData?.userData[0]?.whatsappAccessToken,
                'wabaid': userData?.userData[0]?.wabaid
            }
        };
        fetch(templatedataUrl, requestOptions)
            .then((res) => {
                if (!res.ok) {
                    throw new Error('Network response was not ok');
                }
                return res.json();
            })
            .then((body) => {
                if (body.success === true) {
                    setTemplatedata(body.responseData.data);
                } else {
                    console.error('Error: Success is not true');
                }
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            })
            .finally(() => {
                setIsLoading(false);
            });
        getTeamMembers(userData);
        getQuickReplies(userData);
    }, [userData]);


    const getQuickReplies = async (userData) => {
        const userId = userData?.userData[0]?.user_id;
        fetch('https://connectby.io:3001/api/get-quickreplies', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ user_id: userId })
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                setQuickReplies(data);
            })
            .catch(error => {
                console.error('Error fetching notes:', error);
            });
    };

    const getTeamMembers = async (userData) => {
        const userId = userData?.userData[0]?.user_id;
        fetch('https://connectby.io:3001/api/get-team', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ user_id: userId })
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                setTeamMembers(data);
            })
            .catch(error => {
                console.error('Error fetching notes:', error);
            });
    };

    const hideTemplates = () => {
        setIsModalVisible(false);
    };

    const handleNotesEmojiSelect = (emoji) => {
        const cursorPosition = textAreaRefOne.current.resizableTextArea.textArea.selectionStart;
        const updatedMessage =
            notesTextAreaMessage.slice(0, cursorPosition) +
            emoji.native +
            notesTextAreaMessage.slice(cursorPosition);
        setNotesTextAreaMessage(updatedMessage);
        setNotesShowEmojis(false);
        setTimeout(() => {
            if (textAreaRefOne.current.resizableTextArea.textArea) {
                const newCursorPosition = cursorPosition + emoji.native.length;
                textAreaRefOne.current.resizableTextArea.textArea.setSelectionRange(
                    newCursorPosition,
                    newCursorPosition
                );
            }
        }, 0);
    };


    const handleEmojiSelect = (emoji) => {
        const cursorPosition = textAreaRef.current.resizableTextArea.textArea.selectionStart;
        const updatedMessage =
            textAreaMessage.slice(0, cursorPosition) +
            emoji.native +
            textAreaMessage.slice(cursorPosition);
        setTextAreaMessage(updatedMessage);
        setTimeout(() => {
            if (textAreaRef.current.resizableTextArea.textArea) {
                const newCursorPosition = cursorPosition + emoji.native.length;
                textAreaRef.current.resizableTextArea.textArea.setSelectionRange(
                    newCursorPosition,
                    newCursorPosition
                );
            }
        }, 0);
    };

    const fetchChatPage = async (page, item) => {
        const apiEndpoint = `https://connectby.io:3001/api/getchat?phoneid=${userData?.userData[0]?.phonenumberid}&id=${item.country_code}${item.customer_phone}&page=${page}`;
        try {
            const response = await fetch(apiEndpoint);
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const data = await response.json();
            setChatContainer(prevChat => [...data, ...prevChat]);
        } catch (error) {
            console.error('Error fetching chat content:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleScroll = (e) => {
        const chatContainerElement = document.querySelector('.chat-container');
        if (chatContainerElement) {
            const isAtTop = chatContainerElement.scrollTop === 0;

            if (isAtTop && !loading) {
                setLoading(true);
                setPage(prevPage => prevPage + 1);
            }
        }
    };

    useEffect(() => {
        fetchChatPage(page, chatContent);
    }, [page, chatContent]);

    const handleReplaceText = (text) => {
        if (text) {
            text = text.replace(/\*([^*]+)\*/g, "<b>$1</b>");
            text = text.replace(/_([^_]+)_/g, "<i>$1</i>");
        }
        return text;
    };

    useEffect(() => {
        if (chatContainerRef.current && page === 1) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
    }, [page, chatContainer]);

    const onClickImage = (image) => {
        setActiveImageUid(image.uid);
        setPreviewImage(image.url);
    };

    const deleteImage = (img) => {
        setImageList((prevImageList) => {
            const updatedImageList = prevImageList.filter((image) => image.uid !== img.uid);
            const lastImage = updatedImageList[updatedImageList.length - 1];
            setActiveImageUid(lastImage ? lastImage.uid : null);
            setPreviewImage(lastImage ? lastImage.url : '');
            return updatedImageList;
        });
    };

    const checkFile = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPEG/PNG file!');
        }
        const isLt5M = file.size / 1024 / 1024 < 5;
        if (!isLt5M) {
            message.error('Image must smaller than 5MB!');
        }
        return isJpgOrPng && isLt5M;
    };

    const checkFileOne = (file, message) => {
        const isImage = file.type === 'image/jpeg' || file.type === 'image/png';
        const isVideo = file.type === 'video/mp4' || file.type === 'video/3gp';
        const isDocument = file.type === 'text/plain' || file.type === 'application/pdf' || file.type === 'application/vnd.ms-powerpoint' || file.type === 'application/msword' || file.type === 'application/vnd.ms-excel' || file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || file.type === 'application/vnd.openxmlformats-officedocument.presentationml.presentation' || file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

        if (!isImage && !isVideo && !isDocument) {
            message.error('Unsupported file format');
            return false;
        }

        let maxSizeMB;
        if (isImage) {
            maxSizeMB = 5;
        } else if (isVideo) {
            maxSizeMB = 16;
        } else if (isDocument) {
            maxSizeMB = 100;
        }

        const maxSizeBytes = maxSizeMB * 1024 * 1024;
        if (file.size > maxSizeBytes) {
            message.error(`${isImage ? 'Image' : isVideo ? 'Video' : 'Document'} must be smaller than ${maxSizeMB}MB`);
            return false;
        }

        return true;
    };

    const handleQuickReplyClick = (qr_message) => {
        setTextAreaMessage(qr_message);
        setIsPopupVisible(false);
    };

    const customRequest = ({ file, onSuccess, onError, onProgress }) => {
        setFilesToUpload(prevFiles => [...prevFiles, file]);
        if (!previewVisible) {
            setPreviewVisible(true);
        }
        setImageUploadLoading(true);
        const maxRetries = 3;
        let retryCount = 0;
        const uploadWithRetry = () => {
            setTimeout(() => {
                const isUploaded = Math.random() > 0.5;

                if (isUploaded) {
                    onSuccess();
                    handlePreview(file);
                    setImageUploadLoading(false);
                } else {
                    retryCount++;
                    if (retryCount <= maxRetries) {
                        uploadWithRetry();
                    } else {
                        onError();
                        message.error(`${file.name} file upload failed after ${maxRetries} retries`);
                        setImageUploadLoading(false);
                    }
                }
            }, 1000);
        };

        uploadWithRetry();
    };

    const handlePreview = async (file) => {
        console.log(file.type);
        if (!file.url && !file.preview) {
            try {
                const isImage = file.type.startsWith('image/');
                const isVideo = file.type.startsWith('video/');
                const isText = file.type === 'text/plain';
                const isPDF = file.type === 'application/pdf';
                const isPPT = file.type === 'application/vnd.ms-powerpoint' || file.type === 'application/vnd.openxmlformats-officedocument.presentationml.presentation';
                const isWord = file.type === 'application/msword' || file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
                const isExcel = file.type === 'application/vnd.ms-excel' || file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                if (isImage) {
                    // Generate a preview URL using FileReader
                    const preview = await getBase64(file);
                    setPreviewImage(preview);
                    // setUploadFileList((prevImageList) => [
                    //     ...prevImageList,
                    //     { uid: file.uid, url: preview },
                    // ]);
                    setImageList((prevImageList) => [
                        ...prevImageList,
                        { uid: file.uid, url: preview },
                    ]);
                } else if (isVideo) {
                    const preview = await getBase64(file);
                    setPreviewImage(preview);

                    setImageList((prevImageList) => [
                        ...prevImageList,
                        { uid: file.uid, url: preview, filetype: 'image' },
                    ]);
                } else if (isText) {
                    setPreviewImage(textIcon);

                    setImageList((prevImageList) => [
                        ...prevImageList,
                        { uid: file.uid, url: textIcon, filetype: 'icon' },
                    ]);
                } else if (isPDF) {
                    setPreviewImage(pdfIcon);
                    setImageList((prevImageList) => [
                        ...prevImageList,
                        { uid: file.uid, url: pdfIcon, filetype: 'icon' },
                    ]);
                } else if (isPPT) {
                    setPreviewImage(pptIcon);

                    setImageList((prevImageList) => [
                        ...prevImageList,
                        { uid: file.uid, url: pptIcon, filetype: 'icon' },
                    ]);
                } else if (isWord) {
                    setPreviewImage(wordIcon);

                    setImageList((prevImageList) => [
                        ...prevImageList,
                        { uid: file.uid, url: wordIcon, filetype: 'icon' },
                    ]);
                } else if (isExcel) {
                    setPreviewImage(excelIcon);

                    setImageList((prevImageList) => [
                        ...prevImageList,
                        { uid: file.uid, url: excelIcon, filetype: 'icon' },
                    ]);
                } else {
                    setPreviewImage(fileIcon);
                    setImageList((prevImageList) => [
                        ...prevImageList,
                        { uid: file.uid, url: fileIcon, filetype: 'icon' },
                    ]);
                }
                setActiveImageUid(file.uid);
            } catch (error) {
                console.error('Error getting base64:', error);
                setPreviewImage('');
            }
        }

        setPreviewVisible(true);
    };

    const handleCancel = () => setPreviewVisible(false);


    const handleChange = (info) => {
        console.log(info);
        if (info.file.status === 'uploading') {
            setLoading(true);
            return;
        }
        console.log(info.file.status);
        if (info.file.status === 'done') {
            getBase64One(info.file.originFileObj, (url) => {
                setTemplateImage(url);
                setLoading(false);
            });
        }
    };

    const handleUpdate = (values) => {
        console.log('Form submitted with values:', values);
    };

    const handleAddTag = async () => {
        try {
            setTagLoading(true);
            const formData = await formOne.validateFields();
            const tag_name = formData?.tag_name;
            console.log(tag_name);
            const response = await fetch('https://connectby.io:3001/api/create-new-tag', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    crm_user_id: userData?.userData[0]?.user_id,
                    tag_name: formData.tag_name || '',
                }),
            });
            if (response.ok) {
                const responseData = await response.json();
                const newTagId = responseData.tagId;
                message.success('Tag added successfully');
                formOne.resetFields();
                const newTag = {
                    tag_id: newTagId,
                    crm_user_id: userData?.userData[0]?.user_id,
                    tag_name: tag_name,
                    created_at: Date.now(),
                };
                setTagsData((prevTags) => [newTag, ...prevTags]);
            } else {
                const errorMessage = await response.json();
                message.error(errorMessage.message || 'Unknown error');
            }

        } catch (error) {
            console.log(error);
            console.error('Error Creating Tag:', error);
        } finally {
            setTagLoading(false);
        }
    };

    const UploadComponent = (
        <Upload
            fileList={fileList}
            customRequest={customRequest}
            showUploadList={false}
            beforeUpload={checkFileOne}
            accept="image/jpeg, image/png, video/mp4, video/3gp, text/plain, application/pdf, application/vnd.ms-powerpoint, application/msword, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            onChange={({ fileList }) => setFileList(fileList)}
        >
            <Button className='addMoreFile'>
                <PlusOutlined />
            </Button>
        </Upload>
    );

    const getBase64 = (file) =>
        new Promise((resolve, reject) => {
            if (file instanceof Blob) {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = (error) => reject(error);
            } else {
                reject(new Error('Invalid file type. Expected a Blob.'));
            }
        });

    const appendData = () => {
        setSearchTerm('');
        if (searchTerm === '') {
            let userdataUrl;
            if (userData?.teamData) {
                userdataUrl = `https://connectby.io:3001/api/usersphoneteam/?customerId=${userData?.userData[0]?.user_id}&teamId=${userData.teamData[0]?.team_id}&phoneNoId=${userData?.userData[0]?.phonenumberid}&result=20&page=${pageNumber}&searchTerm=${searchTerm}`;
            } else {
                userdataUrl = `https://connectby.io:3001/api/usersphone/?customerId=${userData?.userData[0]?.user_id}&phoneNoId=${userData?.userData[0]?.phonenumberid}&result=20&page=${pageNumber}&searchTerm=${searchTerm}`;
            }
            fetch(userdataUrl)
                .then((res) => {
                    if (!res.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return res.json();
                })
                .then((body) => {
                    const newPaginatedData = body || [];
                    console.log(newPaginatedData);
                    if (newPaginatedData.length < 20) {
                        setHasMoreData(false);
                    }
                    setData((prevData) => [...prevData, ...newPaginatedData]);
                    setPageNumber((prevPageNumber) => prevPageNumber + 1);
                })
                .catch((error) => {
                    console.error('Error fetching data:', error);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    };

    useEffect(() => {
        if (pageNumber === 1 && searchTerm === '') {
            setIsLoading(true);
            appendData();
        }
    }, [pageNumber, searchTerm]);

    const onScroll = debounce((e) => {
        const target = e.target;
        console.log(target.scrollHeight - target.scrollTop - ContainerHeight);
        if (target) {
            if (Math.abs(target.scrollHeight - target.scrollTop - ContainerHeight) <= 150) {
                if (!hasMoreData) {
                    return;
                }
                if (isLoading) {
                    return;
                }
                setIsLoading(true);
                appendData();
            }
        }
    });

    const onScrollTemplate = debounce((e) => {
        const target = e.target;
        if (target) {
            if (Math.abs(target.scrollHeight - target.scrollTop - ContainerHeight) <= 1) {
            }
        }
    });

    function getTimeFromDate(date) {
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const amPM = hours >= 12 ? 'PM' : 'AM';
        const twelveHourFormat = hours % 12 || 12;

        const hours12 = twelveHourFormat.toString().padStart(2, '0');
        const minutesStr = minutes.toString().padStart(2, '0');

        return `${hours12}:${minutesStr} ${amPM}`;
    }

    const formatDate = (timestamp) => {
        const date = new Date(timestamp * 1000);

        const now = new Date();
        const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
        const yesterday = new Date(today);
        yesterday.setDate(today.getDate() - 1);
        const tomorrow = new Date(today);
        tomorrow.setDate(today.getDate() + 1);

        if (date >= today) {
            if (date >= tomorrow) {
                return date.toLocaleDateString('en-US', { weekday: 'long' });
            } else if (date >= today) {
                return getTimeFromDate(date);
            }
        } else if (date >= yesterday) {
            return 'Yesterday';
        } else if (date >= today - 6 * 24 * 60 * 60 * 1000) {
            return date.toLocaleDateString('en-US', { weekday: 'long' });
        }

        return date.toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: '2-digit' });
    };

    const getExtensionFromMimeType = (mimeType) => {
        const mimeTypeToExtensionMap = {
            'image/jpeg': 'jpeg',
            'image/png': 'png',
            'image/gif': 'gif',
            'video/mp4': 'mp4',
            'video/3gp': '3gp',
            'application/pdf': 'pdf',
            'text/plain': 'txt',
            'audio/mp3': 'mp3',
            'audio/ogg': 'ogg',
            'audio/mp4': 'm4a',
            'application/vnd.ms-powerpoint': 'pptx',
            'application/msword': 'doc',
            'application/vnd.ms-excel': 'xls',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'docx',
            'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'pptx',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx'
        };
        return mimeTypeToExtensionMap[mimeType] || 'txt';
    };

    useEffect(() => {

    }, [textAreaDisabled]);

    const tagsArrayToObj = (tagsArray) => {
        const tagsObj = {};
        if (Array.isArray(tagsArray)) {
            tagsArray.forEach((tagId) => {
                tagsObj[tagId] = true; // All tags set to true
            });
        }
        return tagsObj;
    };

    const openchat = (item) => {
        setActiveItem(item);
        setSelectedTags({});
        if (item?.tags !== "") {
            const parsedTags = JSON.parse(item?.tags);
            const tagsObj = tagsArrayToObj(parsedTags);
            setSelectedTags(tagsObj);
        }
        setChatContent(item);
        setChatContainer([]);
        setPage(1);
        setLoading(false);
        setSelectedValues({});
        isMessageOld(item)
            .then(isOld => {
                setTextAreaDisabled(isOld);
            })
            .catch(error => {
                console.error('Error:', error);
            });
        setData((PrevData) => {
            return PrevData.map((userdata) => {
                if (userdata?.customer_id === item?.customer_id) {
                    const newReceivedMessage = {
                        mainid: item.mainid,
                        wamid: item.wamid,
                        wanumber: item.wanumber
                    };
                    updateReceivedMessage(newReceivedMessage);
                    return { ...userdata, msgstatus: "read" };
                }
                return userdata;
            });
        });
    };

    const sendAttachments = async () => {
        const textAreaMessage = textAreaMessageOne || '';
        const attachments = filesToUpload;
        const activerecept = activeItem;
        let countryCode = activerecept.country_code;
        if (countryCode.startsWith('+')) {
            countryCode = countryCode.substring(1);
        }
        const wanumber = countryCode + activerecept.customer_phone;
        const formData = new FormData();
        attachments.forEach((file) => {
            formData.append('file', file);
        });
        formData.append('to', wanumber);
        formData.append('message', textAreaMessage);
        try {
            setAttachmentLoader(true);
            const response = await fetch('https://connectby.io:3001/api/send-attachments', {
                method: 'POST',
                headers: {
                    'whatsappaccesstoken': userData?.userData[0]?.whatsappAccessToken,
                    'phonenumberid': userData?.userData[0]?.phonenumberid
                },
                body: formData
            });

            if (!response.ok) {
                console.log(response);
                throw new Error('Failed to send attachments');
            }
            const responseDataArray = await response.json();
            responseDataArray.responseDataArray.forEach(responseData => {
                const sentMessage = {
                    created: Date.now() / 1000,
                    is_message: 'to',
                    mainid: responseData.mainid,
                    msgtype: responseData.msgtype,
                    message: responseData.message,
                    status: 'sending',
                };
                setChatContainer(prevChat => [...prevChat, sentMessage]);
                setData((PrevData) => {
                    const updatedData = PrevData.map((userdata) => {
                        if (formatPhoneId(userdata.country_code, userdata.customer_phone) === wanumber) {
                            if (responseData.msgtype === 'text') {
                                return { ...userdata, created: Math.floor(Date.now() / 1000).toString(), message: responseData.message, msgstatus: "read" };
                            } else {
                                return { ...userdata, created: Math.floor(Date.now() / 1000).toString(), message: responseData.msgtype, msgstatus: "read" };
                            }
                        }
                        return userdata;
                    });
                    const movedUser = updatedData.find((userdata) => formatPhoneId(userdata.country_code, userdata.customer_phone) === wanumber);
                    const restOfUsers = updatedData.filter((userdata) => formatPhoneId(userdata.country_code, userdata.customer_phone) !== wanumber);
                    return [movedUser, ...restOfUsers];
                });
            });
            setFilesToUpload([]);
            setPreviewVisible(false);
            setPreviewImage('');
            setImageList([]);
            console.log('Attachments sent successfully');
        } catch (error) {
            // Handle errors
            console.error('Error sending attachments:', error);
        } finally {
            setAttachmentLoader(false);
        }
    };

    const handleNote = () => {
        setNoteLoader(true);
        const note = notesTextAreaMessage;
        const user_id = activeItem?.customer_id;

        const data = {
            note: note,
            user_id: user_id
        };

        fetch('https://connectby.io:3001/api/insert-note', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(insertResults => {
                message.success('Note Inserted successfully.');
            })
            .catch(error => {
                console.error('Error inserting data in tbl_whatsapp_notes:', error);
            }).finally(() => {
                setNoteLoader(false);
                setNotesTextAreaMessage('');
            });
    };

    const truncateNotes = (notes) => {
        if (notes.length > 17) {
            return notes.substring(0, 17) + '...';
        }
        return notes;
    };

    const truncateMessage = (notes) => {
        if (notes.length > 30) {
            return notes.substring(0, 30) + '...';
        }
        return notes;
    };

    const getSelectedTags = async (tags) => {
        if (tags !== '') {
            fetch('https://connectby.io:3001/api/get-selected-tags', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ tag_ids: tags })
            })
                .then(response => {
                    console.log(response);
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.json();
                })
                .then(data => {
                    setAssignedTagsData(data);
                })
                .catch(error => {
                    console.error('Error fetching notes:', error);
                });
        } else {
            setAssignedTagsData([]);
        }
    };

    const getTags = async () => {
        const userId = userData?.userData[0]?.user_id;
        fetch('https://connectby.io:3001/api/get-tags', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ user_id: userId })
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                setTagsData(data);
            })
            .catch(error => {
                console.error('Error fetching notes:', error);
            });
    };

    useEffect(() => {
        if (userMenu) {
            handleUserDetail();
        }
    }, [userMenu]);

    const handleUserDetail = () => {
        setUserMenu(true);
        setNoteViewLoader(true);
        getSelectedTags(activeItem.tags);
        const userId = activeItem?.customer_id;
        fetch('https://connectby.io:3001/api/get-notes', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ user_id: userId })
        }).then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        }).then(data => {
            setNotes(data);
        }).catch(error => {
            console.error('Error fetching notes:', error);
        }).finally(() => {
            setNoteViewLoader(false);
        });
    };

    const onCloseNewUser = () => {
        setUserMenu(false);
    };

    const handleBlockClick = async () => {
        try {
            const response = await fetch('https://connectby.io:3001/api/block-customer', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    customer_id: activeItem?.customer_id,
                }),
            });
            if (response.ok) {
                setData((prevData) => prevData.filter(item => item?.customer_id !== activeItem?.customer_id));
                setActiveItem([]);
                message.success('Blocked');
            } else {
                const errorMessage = await response.json();
                message.error(errorMessage.message || 'Unknown error');
            }

        } catch (error) {
            console.log(error);
            console.error('Error Creating Tag:', error);
        }
    };

    const handleTagPopup = () => {
        getTags();
        setTegPopup(true);
    };

    const onCloseTagPopup = () => {
        setTegPopup(false);
    };

    const handleLoadMore = useCallback(() => {
        if (!isLoading && hasMoreData) {
            setIsLoading(true);
            appendData();
        }
    }, [hasMoreData, isLoading]);

    const getMessage = (message) => {
        try {
            const parsedMessage = JSON.parse(message);
            if (parsedMessage.body) {
                return parsedMessage.body;
            } else {
                return parsedMessage;
            }
        } catch (e) {
            return message;
        }
    };
    const handleItemClick = useCallback((item) => openchat(item), [openchat]);

    const getTeamMemberName = (item) => {
        const teammemberid = item?.team_member_id;
        console.log('teammemberid', teammemberid);
        const teamMember = teamMembers.find(member => member.team_id === teammemberid);
        return teamMember ? teamMember.team_name : '';
    };

    const MemoizedListItem = React.memo(({ item, isActive, onClick }) => (
        <List.Item onClick={onClick} className={`userListItem ${isActive ? 'active' : ''}`}>
            <List.Item.Meta className={`customerList ${(!item.msgstatus ? ('read') : (item.msgstatus))}`}
                avatar={<span><Avatar key={item?.customer_id} avatar={item.customer_name ? item.customer_name[0].toUpperCase() : ''}>{item.customer_name ? item.customer_name[0].toUpperCase() : ''}</Avatar>{item?.team_member_id !== 0 && (<span className='teamsymbol'><Tooltip title={getTeamMemberName(item)}>{getTeamMemberName(item)?.[0]?.toUpperCase()}</Tooltip></span>)}</span>}
                title={<Tooltip title={item.customer_name}>{truncateNotes(item.customer_name)}</Tooltip>}
                description={<React.Fragment>
                    {
                        item.msgtype === 'text' ? <span className="last-conv"><Tooltip title={getMessage(item.message)}>{truncateMessage(getMessage(item.message))}</Tooltip></span> : <span className="last-conv">{item.msgtype}</span>}
                    {item.created === null ? <span>&nbsp;</span> : <span className="last-conv-time">{formatDate(item.created)}</span>}
                </React.Fragment>}
            />
        </List.Item>
    ));

    const items = [
        {
            key: '1',
            // label: <Tooltip title="Unassigned Chats"><div><ReactSVG src={unassignchatSvg} className="listSvgIcons" /></div></Tooltip>,
            children: (
                <List style={{ height: ContainerHeightOne, overflow: 'scroll' }}>
                    <VirtualList
                        data={data}
                        // height={ContainerHeightOne}
                        itemHeight={47}
                        itemKey={(index) => data[index]?.customer_id}
                    >
                        {(item, index) => {
                            const isActive = activeItem?.customer_id === item?.customer_id;

                            return isLoading || !item?.customer_id ? (
                                <Skeleton avatar active>
                                    <List.Item.Meta
                                        avatar={<Avatar />}
                                        title={<Skeleton.Input style={{ width: '40px' }} />}
                                        description={<Skeleton.Input style={{ width: '80px' }} />}
                                    />
                                </Skeleton>
                            ) : (
                                <MemoizedListItem item={item} isActive={isActive} onClick={() => handleItemClick(item)} />
                            );
                        }}
                    </VirtualList>
                    {hasMoreData && (
                        isLoading ? (
                            <div className='dotloading' style={{ textAlign: 'center' }}>
                                <Spin indicator={<LoadingOutlined spin style={{ color: '#075E54' }} />} />
                            </div>
                        ) : (
                            <div style={{ textAlign: 'center' }}>
                                <Button onClick={handleLoadMore} className="loadMoreBtn" type='primary' size='mini' color='primary'>
                                    Load More
                                </Button>
                            </div>
                        )
                    )}
                </List>
            ),
        },
        // {
        //     key: '2',
        //     label: <Tooltip title="Assigned Chats"><div><ReactSVG src={userSvg} className="listSvgIcons" /></div></Tooltip>,
        //     children: 'Content of Tab Pane 2',
        // },
        // {
        //     key: '3',
        //     label: <Tooltip title="Mention Chats"><div><ReactSVG src={userMentionSvg} className="listSvgIcons" /></div></Tooltip>,
        //     children: 'Content of Tab Pane 3',
        // },
        // {
        //     key: '4',
        //     label: <Tooltip title="Priority Chats"><div><ReactSVG src={prioritychatSvg} className="listSvgIcons" /></div></Tooltip>,
        //     children: 'Content of Tab Pane 3',
        // },
        // {
        //     key: '5',
        //     label: <Tooltip title="Open Chats"><div><ReactSVG src={openchatrSvg} className="listSvgIcons" /></div></Tooltip>,
        //     children: 'Content of Tab Pane 3',
        // },
        // {
        //     key: '6',
        //     label: <Tooltip title="Resolved Chats"><div><ReactSVG src={resolvedchatSvg} className="listSvgIcons" /></div></Tooltip>,
        //     children: 'Content of Tab Pane 3',
        // },
    ];
    let previousDate = null;
    const itemsOne = [
        {
            key: '1',
            label: <Tooltip title="reply"><div>Reply</div></Tooltip>,
            children: <>
                <TextArea
                    className='messageTextarea'
                    rows={4}
                    placeholder="Type your message here"
                    value={textAreaMessage}
                    onChange={(e) => setTextAreaMessage(e.target.value)}
                    ref={textAreaRef}
                    onKeyDown={handleKeyDown}
                    disabled={textAreaDisabled}
                />
                <Button type="primary" shape="circle" className="sendButton" onClick={handleSendMessage} icon={msgBtnLoading ? (<Spin indicator={<LoadingOutlined spin style={{ color: '#fff' }} />} />) : (<SendOutlined />)} size={'large'} />
                <div className="otherbtns">
                    {showEmojis && (
                        <Picker onEmojiSelect={handleEmojiSelect} />
                    )}
                    {textAreaDisabled ? (
                        <span><SmileOutlined /></span>
                    ) : (
                        <span onClick={() => setShowEmojis(!showEmojis)}><SmileOutlined /></span>
                    )
                    }
                    {textAreaDisabled ? (
                        <span><PaperClipOutlined /></span>
                    ) : (
                        <Upload
                            fileList={fileList}
                            customRequest={customRequest}
                            showUploadList={false}
                            beforeUpload={checkFileOne}
                            accept="image/jpeg, image/png, video/mp4, video/3gp, text/plain, application/pdf, application/vnd.ms-powerpoint, application/msword, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                            onPreview={handlePreview}
                            onChange={({ fileList }) => setFileList(fileList)}
                        >
                            <span><PaperClipOutlined /></span>
                        </Upload>
                    )}
                    <Modal open={previewVisible} footer={null} onCancel={handleCancel} className='imagePreviewModal'>
                        <div className='imagepreviewarea'>
                            {imageUploadLoading ? (
                                <Spin className='spinner' />
                            ) : (
                                <div className='imagePreviewDiv'>
                                    <img alt="Preview" style={{ width: '100%' }} src={previewImage} />
                                </div>
                            )}
                        </div>
                        <Space style={{ marginTop: '10px' }}>
                            <List className='imagePreviewGrid'>
                                {imageList.map((image) => (
                                    <List.Item key={image.uid} className={`imageGridPreview ${activeImageUid === image.uid ? 'active' : ''}`} onClick={() => onClickImage(image)}>
                                        <img alt="Preview" src={image.url} />
                                        <span className='DeleteImage' onClick={() => deleteImage(image)}><CloseOutlined /></span>
                                    </List.Item>
                                ))}
                            </List>
                            {UploadComponent}
                        </Space>
                        <Space className='imagebodytext'>
                            <TextArea rows={4} placeholder="Type your message here" value={textAreaMessageOne}
                                onChange={(e) => setTextAreaMessageOne(e.target.value)}
                                ref={textAreaOneRef} />
                            <Button type="primary" shape="circle" className="sendButton" onClick={sendAttachments} icon={attachmentLoader ? (<Spin indicator={<LoadingOutlined spin style={{ color: '#fff' }} />} />) : (<SendOutlined />)} size={'large'} />
                        </Space>
                    </Modal>
                </div>
                {textAreaDisabled && (
                    <div className="old-message-overlay">
                        <p><b>The customer has not spoken to you in the last 24 hours.</b></p>
                        <p>You can only send pre-approved templates.</p>
                    </div>
                )}
            </>,
        },
        {
            key: '2',
            label: <Tooltip title="notes"><div>Notes</div></Tooltip>,
            children: <><TextArea
                rows={4}
                placeholder="Notes"
                value={notesTextAreaMessage}
                onChange={(e) => setNotesTextAreaMessage(e.target.value)}
                ref={textAreaRefOne}
                onKeyDown={handleKeyDownOne}
            />
                <Button type="primary" shape="circle" onClick={handleNote} className="sendButton" icon={noteLoader ? (<Spin indicator={<LoadingOutlined spin style={{ color: '#fff' }} />} />) : (<SendOutlined />)} size={'large'} />
                <div className="otherbtns">
                    {showNotesEmojis && (
                        <Picker onEmojiSelect={handleNotesEmojiSelect} />
                    )}
                    <span onClick={() => setNotesShowEmojis(!showNotesEmojis)}><SmileOutlined /></span>
                </div>
            </>,
        },
    ];

    const getFileExtension = (url) => {
        if (url && url.includes('.')) {
            return url.split('.').pop();
        } else {
            return null;
        }
    };

    const onSearch = debounce((value) => {
        if (value !== '') {
            setSearchTerm(value);
            appendDataOne();
        } else {
            setHasMoreData(true);
            setIsLoading(true);
            setData([]);
            appendData();
        }
    });

    const appendDataOne = () => {
        setPageNumber(1);
        setIsLoading(true);
        let userdataUrl;
        if (userData?.teamData) {
            userdataUrl = `https://connectby.io:3001/api/usersphoneteam/?customerId=${userData?.userData[0]?.user_id}&teamId=${userData.teamData[0]?.team_id}&phoneNoId=${userData?.userData[0]?.phonenumberid}&result=20&page=${pageNumber}&searchTerm=${searchTerm}`;
        } else {
            userdataUrl = `https://connectby.io:3001/api/usersphone/?customerId=${userData?.userData[0]?.user_id}&phoneNoId=${userData?.userData[0]?.phonenumberid}&result=20&page=${pageNumber}&searchTerm=${searchTerm}`;
        }
        fetch(userdataUrl)
            .then((res) => {
                if (!res.ok) {
                    throw new Error('Network response was not ok');
                }
                return res.json();

            })
            .then((body) => {
                const newPaginatedData = body || [];
                setData(newPaginatedData);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const onChangeAssign = async (value) => {
        setAssignLoader(true);
        try {
            const response = await fetch('https://connectby.io:3001/api/assign-team', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    customer_id: activeItem?.customer_id,
                    team_member_id: value,
                }),
            });
            if (response.ok) {
                if (value === 0) {
                    message.success('Team Member unassigned successfully.');
                    setData((PrevData) => {
                        return PrevData.map((userdata) => {
                            if (userdata?.customer_id === activeItem?.customer_id) {
                                return { ...userdata, team_member_id: 0 };
                            }
                            return userdata;
                        });
                    });
                    setChatContent(prevContent => ({
                        ...prevContent,
                        team_member_id: 0
                    }));
                } else {
                    setData((PrevData) => {
                        return PrevData.map((userdata) => {
                            if (userdata?.customer_id === activeItem?.customer_id) {
                                return { ...userdata, team_member_id: value };
                            }
                            return userdata;
                        });
                    });
                    setChatContent(prevContent => ({
                        ...prevContent,
                        team_member_id: value
                    }));
                    message.success('Team Member assigned successfully.');
                }
            } else {
                const errorMessage = await response.json();
                message.error(errorMessage.message || 'Unknown error');
            }

        } catch (error) {
            console.log(error);
            console.error('Error Creating Tag:', error);
        } finally {
            setAssignLoader(false);
        }
    };
    const onSearchAssign = (value) => {
        console.log('search:', value);
    };

    const formatText = (text) => {
        // Replace ** with <b> for bold
        text = text.replace(/\*(.*?)\*/g, '<b>$1</b>');

        // Replace _ _ with <i> for italic
        text = text.replace(/_(.*?)_/g, '<i>$1</i>');

        // Replace \n with <br> for new line
        text = text.replace(/\\n/g, '<br>');

        return text;
    };

    return (
        <>
            <Row>
                <Col span={6} className="chatList">
                    <Space direction="vertical" style={{ width: '100%', padding: '24px 15px 0 15px' }} >
                        <Search placeholder="Search message or user" onChange={e => onSearch(e.target.value)} style={{ width: '100%' }} />
                    </Space>
                    <Tabs defaultActiveKey="1" items={items} onChange={onChange} className="chatboxTabs" />
                </Col>
                <Col span={18}>
                    {activeItem?.customer_id ? (
                        <Card
                            title={
                                <span>
                                    <Avatar style={{ position: 'relative', top: -5 }} key={chatContent?.customer_id} avatar={chatContent.customer_name ? chatContent.customer_name.trim().charAt(0).toUpperCase() : ''}>
                                        {chatContent.customer_name ? chatContent.customer_name.trim().charAt(0).toUpperCase() : ''}
                                    </Avatar>
                                    <span style={{ marginLeft: 15, display: 'inline-block', lineHeight: 1, position: 'relative', top: 5 }}>{chatContent.customer_name} <br /> <a style={{ fontSize: 10 }} href={`tel:+${chatContent.country_code}${chatContent.customer_phone}`} target="_blank">{`+ ${chatContent.country_code} ${chatContent.customer_phone}`}</a></span>
                                    <span style={{ float: 'right' }}>
                                        {userData?.teamData ? (
                                            ''
                                        ) : (
                                            <Select
                                                showSearch
                                                placeholder="Assign Chat to team member"
                                                optionFilterProp="label"
                                                onChange={onChangeAssign}
                                                onSearch={onSearchAssign}
                                                value={chatContent?.team_member_id || 0}
                                                style={{ marginRight: 15 }}
                                                loading={assignLoader}
                                                options={[
                                                    {
                                                        value: 0,
                                                        label: "Unassign"
                                                    },
                                                    ...teamMembers?.map(member => ({
                                                        value: member?.team_id,
                                                        label: `${member?.team_name} (${member?.team_email})`
                                                    }))
                                                ]}
                                            />
                                        )}
                                        <Tooltip title="Click to View and edit user details">
                                            <Button onClick={handleUserDetail}>
                                                {userMenu ? (
                                                    <MenuUnfoldOutlined />
                                                ) : (
                                                    <MenuFoldOutlined />
                                                )}
                                            </Button>
                                        </Tooltip>
                                    </span>
                                </span>
                            }
                            style={{ height: `calc(100vh - 112px)`, }}
                            className='chatCard'
                        >
                            <div className="chat-container" onScroll={handleScroll} ref={chatContainerRef}>
                                {loading ? (<Spin className='spinner' />) : ('')}
                                {chatContainer.map((message, index) => {
                                    let templatecomp = null;
                                    if (message.msgtype === 'template') {
                                        templatecomp = JSON.parse(message?.template)?.components;
                                    }
                                    const currentDate = new Intl.DateTimeFormat('en-US', { year: 'numeric', month: 'short', day: 'numeric' }).format(new Date(message.created * 1000));
                                    const displayDate = currentDate !== previousDate ? (
                                        <div key={`date-${index}`} className="showdate">
                                            <span style={{ color: '#ddd' }}>{formatDate(message.created)}</span>
                                        </div>
                                    ) : null;
                                    previousDate = currentDate;
                                    return (
                                        <div key={`message-${index}`}>
                                            {displayDate}
                                            <div key={message.mainid} className={`message-box ${message.is_message === 'to' ? 'my-message' : 'friend-message'}`}>
                                                <div className='mainboddiv'>
                                                    {message.msgtype === 'text' ? (
                                                        <div className='messageArea'>
                                                            {(() => {
                                                                try {
                                                                    const parsedMessage = JSON.parse(message.message);
                                                                    if (parsedMessage.body) {
                                                                        return <div dangerouslySetInnerHTML={{ __html: formatText(parsedMessage.body.toString()) }}></div>;
                                                                    } else {
                                                                        return <div dangerouslySetInnerHTML={{ __html: formatText(parsedMessage.body.toString()) }}></div>;
                                                                    }
                                                                } catch (e) {
                                                                    return <div dangerouslySetInnerHTML={{ __html: formatText(message.message.toString()) }}></div>;
                                                                }
                                                            })()}
                                                        </div>
                                                    ) : message.msgtype === 'interactive' ? (
                                                        <div className='messageArea'>
                                                            {message.media !== '' ? (
                                                                (() => {
                                                                    try {
                                                                        const parsedMessage = JSON.parse(message.media);
                                                                        if (parsedMessage[parsedMessage.type].title) {
                                                                            return parsedMessage[parsedMessage.type].title;
                                                                        } else if (parsedMessage[parsedMessage.type].body) {
                                                                            return parsedMessage[parsedMessage.type].body;
                                                                        }
                                                                    } catch (e) {
                                                                        const parsedMessage = JSON.parse(message.media);
                                                                        if (parsedMessage.type === 'button') {
                                                                            return (
                                                                                <>
                                                                                    <div className='headertext'>{parsedMessage?.header?.text}</div>
                                                                                    <div className='prvbodytext'><div dangerouslySetInnerHTML={{ __html: formatText(parsedMessage?.body?.text) }}></div></div>
                                                                                    <div className='footertext'>{parsedMessage?.footer?.text}</div>
                                                                                    {parsedMessage.action.buttons.map((button, index) => (
                                                                                        <div className='quickreply' key={index}>
                                                                                            <a href="javascript:void(0)" rel="noreferrer">{button.reply.title}</a>
                                                                                        </div>
                                                                                    ))}
                                                                                </>
                                                                            );
                                                                        } else if (parsedMessage.type === 'list') {
                                                                            return (
                                                                                <>
                                                                                    <div className='headertext'>{parsedMessage?.header?.text}</div>
                                                                                    <div className='prvbodytext'><div dangerouslySetInnerHTML={{ __html: formatText(parsedMessage?.body?.text) }}></div></div>
                                                                                    <div className='footertext'>{parsedMessage?.footer?.text}</div>
                                                                                    {parsedMessage.action.sections.map((section, index) => (
                                                                                        <div className='list-section' key={index}>
                                                                                            <div className='section-title'>{section.title}</div>
                                                                                            {section.rows.map((row, idx) => (
                                                                                                <div className='list-row' key={idx}>
                                                                                                    <div className='row-title'>{row.title}</div>
                                                                                                    <div className='row-description'>{row.description}</div>
                                                                                                </div>
                                                                                            ))}
                                                                                        </div>
                                                                                    ))}
                                                                                    <div className='moreBtn'>{parsedMessage.action.button}</div>
                                                                                </>
                                                                            );
                                                                        } else if (parsedMessage.type === 'nfm_reply') {
                                                                            return (
                                                                                <>
                                                                                    <div>
                                                                                        {parsedMessage?.type}
                                                                                        {/* {parsedMessage?.nfm_reply?.body} */}
                                                                                    </div>
                                                                                </>
                                                                            );
                                                                        } else {
                                                                            return parsedMessage?.type;
                                                                        }
                                                                    }
                                                                })()
                                                            ) : (
                                                                (() => {
                                                                    const parsedMessage = JSON.parse(message.message);
                                                                    if (parsedMessage.type === 'order_status') {
                                                                        return formatText(parsedMessage?.body?.text.toString());
                                                                    } else if (parsedMessage.type === 'order_details') {
                                                                        return formatText(parsedMessage?.body?.text.toString());
                                                                    } else if (parsedMessage.type === 'button') {
                                                                        return (
                                                                            <>
                                                                                <div className='headertext'>{parsedMessage?.header?.text}</div>
                                                                                <div className='prvbodytext'><div dangerouslySetInnerHTML={{ __html: formatText(parsedMessage?.body?.text) }}></div></div>
                                                                                <div className='footertext'>{parsedMessage?.footer?.text}</div>
                                                                                {parsedMessage.action.buttons.map((button, index) => (
                                                                                    <div className='quickreply' key={index}>
                                                                                        <a href="javascript:void(0)" rel="noreferrer">{button.reply.title}</a>
                                                                                    </div>
                                                                                ))}
                                                                            </>
                                                                        );
                                                                    } else if (parsedMessage.type === 'list') {
                                                                        return (
                                                                            <>
                                                                                <div className='headertext'>{parsedMessage?.header?.text}</div>
                                                                                <div className='prvbodytext'><div dangerouslySetInnerHTML={{ __html: formatText(parsedMessage?.body?.text) }}></div></div>
                                                                                <div className='footertext'>{parsedMessage?.footer?.text}</div>
                                                                                {parsedMessage.action.sections.map((section, index) => (
                                                                                    <div className='list-section' key={index}>
                                                                                        <div className='section-title'>{section.title}</div>
                                                                                        {section.rows.map((row, idx) => (
                                                                                            <div className='list-row' key={idx}>
                                                                                                <div className='row-title'>{row.title}</div>
                                                                                                <div className='row-description'>{row.description}</div>
                                                                                            </div>
                                                                                        ))}
                                                                                    </div>
                                                                                ))}
                                                                                <div className='moreBtn'>{parsedMessage.action.button}</div>
                                                                            </>
                                                                        );
                                                                    } else if (parsedMessage.type === 'request_welcome') {
                                                                        return <div className='messageArea'>
                                                                            {JSON.parse(message?.media)?.message !== '' ? JSON.parse(message?.media)?.message : 'Request Welcome'}
                                                                        </div>;
                                                                    } else if (parsedMessage.type === 'address_message') {
                                                                        return (
                                                                            <>
                                                                                <div className='headertext'>{parsedMessage?.header?.text}</div>
                                                                                <div className='prvbodytext'><div dangerouslySetInnerHTML={{ __html: formatText(parsedMessage?.body?.text) }}></div></div>
                                                                                <div className='footertext'>{parsedMessage?.footer?.text}</div>
                                                                                <hr className='buttonhr' />
                                                                                <div className='moreBtn'>Provide address</div>
                                                                            </>
                                                                        )
                                                                    } else if (parsedMessage.type === 'nfm_reply') {
                                                                        return (
                                                                            <>
                                                                                <div>
                                                                                    {parsedMessage?.nfm_reply?.body}
                                                                                </div>
                                                                            </>
                                                                        );
                                                                    } else {
                                                                        return '';
                                                                    }
                                                                })()
                                                            )}
                                                        </div>
                                                    ) : message.msgtype === 'request_welcome' ? (
                                                        <div className='messageArea'>
                                                            {JSON.parse(message?.media)?.message !== '' ? JSON.parse(message?.media)?.message : 'Request Welcome'}
                                                        </div>
                                                    ) : message.msgtype === 'template' ? (
                                                        templatecomp && templatecomp.length > 0 ? (
                                                            templatecomp.map((template) => (
                                                                <React.Fragment>
                                                                    {template.type === "HEADER" && (
                                                                        template.format === 'TEXT' ? (
                                                                            <p className='headeretxt'>{template.text}</p>
                                                                        ) : template.format === 'IMAGE' ? (
                                                                            <div className='previewContent'><Image alt="Header"
                                                                                src={template.example.header_handle[0]} style={{ height: '150px', width: 'auto' }} /></div>
                                                                            // <div className='previewContent' onClick={() => openModal(template.example.header_handle[0])}><img alt="Header" src={template.example.header_handle[0]} style={{ height: '150px', width: 'auto' }} /></div>
                                                                        ) : template.format === 'VIDEO' ? (
                                                                            <div className='previewContent'><video src={template.example.header_handle[0]} controls style={{ width: '100%' }} /></div>
                                                                        ) : template.format === 'DOCUMENT' ? (
                                                                            <div className='previewContent'><embed src={template.example.header_handle[0]} type="application/pdf" style={{ width: '100%', height: '500px' }} /></div>
                                                                        ) : (
                                                                            <p>Unsupported format</p>
                                                                        )
                                                                    )}
                                                                    {template.type === "BODY" && (
                                                                        <div className='prvbodytext' dangerouslySetInnerHTML={{ __html: handleReplaceText(template.text) }}></div>
                                                                    )}
                                                                    {template.type === "FOOTER" && (
                                                                        <div className='footertext'>{template.text}</div>
                                                                    )}{template.type === "BUTTONS" && (
                                                                        <React.Fragment>
                                                                            <hr className='buttonhr' />
                                                                            {template.buttons.map((button) => (
                                                                                <div key={button.id}>
                                                                                    {
                                                                                        button.type === 'QUICK_REPLY' ? (
                                                                                            <>
                                                                                                <div className='quickreply'>
                                                                                                    <a target="_blank" href="javascript:void(0)" rel="noreferrer">
                                                                                                        {button.text}
                                                                                                    </a>
                                                                                                </div>
                                                                                            </>
                                                                                        ) : (
                                                                                            ''
                                                                                        )}
                                                                                    {
                                                                                        button.type === 'FLOW' ? (
                                                                                            <>
                                                                                                <div className='quickreply'>
                                                                                                    <a target="_blank" href="javascript:void(0)" rel="noreferrer">
                                                                                                        {button.text}
                                                                                                    </a>
                                                                                                </div>
                                                                                            </>
                                                                                        ) : (
                                                                                            ''
                                                                                        )}
                                                                                    {
                                                                                        button.type === 'URL' ? (
                                                                                            <>
                                                                                                <div className='urlbtn'>
                                                                                                    <a target="_blank" href={button.url} rel="noreferrer">
                                                                                                        <LinkOutlined />
                                                                                                        {button.text}</a>
                                                                                                </div>
                                                                                            </>
                                                                                        ) : (
                                                                                            ''
                                                                                        )}
                                                                                    {
                                                                                        button.type === 'PHONE_NUMBER' ? (
                                                                                            <>
                                                                                                <div className='phonebtn'>
                                                                                                    <a target="_blank" href={`tel:${button.phone_number}`} rel="noreferrer">
                                                                                                        <PhoneOutlined />
                                                                                                        {button.text}</a>
                                                                                                </div>
                                                                                            </>
                                                                                        ) : (
                                                                                            ''
                                                                                        )}
                                                                                </div>
                                                                            ))}
                                                                        </React.Fragment>
                                                                    )}
                                                                </React.Fragment>
                                                            ))
                                                        ) : (
                                                            <React.Fragment></React.Fragment>
                                                        )
                                                    ) : message.msgtype === 'image' ? (
                                                        message.is_message === 'to' ? (
                                                            <React.Fragment>
                                                                <div className='messagePreviewImages' onClick={() => openModal(message.message)}>
                                                                    <img
                                                                        alt="Preview"
                                                                        style={{ width: '100%' }}
                                                                        src={message.message}
                                                                        onError={() => console.error(`Failed to load image: ${message.message}`)}
                                                                    />
                                                                </div>
                                                                <Image alt="Preview"
                                                                    style={{ width: '100%' }}
                                                                    src={message.message}
                                                                    onError={() => console.error(`Failed to load image: ${message.message}`)} />
                                                            </React.Fragment>
                                                        ) : (
                                                            message.is_message === 'from' && (
                                                                <React.Fragment>
                                                                    {!message.image ? (
                                                                        (message.media ? (
                                                                            <div className='messagePreviewImages' key={JSON.parse(message.media).id} onClick={() => openModal(`https://api.connectby.io/upload/${JSON.parse(message.media).id}.${getExtensionFromMimeType(JSON.parse(message.media).mime_type)}`)}>
                                                                                <Image alt="Preview"
                                                                                    style={{ width: '100%' }}
                                                                                    src={`https://api.connectby.io/upload/${JSON.parse(message.media).id}.${getExtensionFromMimeType(JSON.parse(message.media).mime_type)}`}
                                                                                    onError={() => renderReceivedImage(message.mainid, JSON.parse(message.media).id)} />
                                                                            </div>
                                                                        ) : (<div className='messagePreviewImages' key={message.message} onClick={() => openModal()}>
                                                                            <div>
                                                                                <Spin indicator={<LoadingOutlined spin />} />
                                                                            </div>
                                                                        </div>))
                                                                    ) : (
                                                                        <div className='messagePreviewImages' key={message.image.id} onClick={() => openModal()}>

                                                                            <Image alt="Preview"
                                                                                style={{ width: '100%' }}
                                                                                src={message.image.url}
                                                                                onError={() => renderReceivedImage(message.mainid, message.image.id)} />
                                                                        </div>
                                                                    )}
                                                                </React.Fragment>
                                                            ))
                                                    ) : message.msgtype === 'video' ? (
                                                        message.is_message === 'to' ? (
                                                            <React.Fragment>
                                                                <div className='messagePreviewVideos'>
                                                                    <video
                                                                        src={message.message}
                                                                        controls
                                                                        style={{ width: '100%' }}
                                                                        onError={(e) => renderReceivedVideo(message.message, message.message)}
                                                                    />
                                                                </div>
                                                            </React.Fragment>
                                                        ) : (
                                                            message.is_message === 'from' && (
                                                                <React.Fragment>
                                                                    {!message.video ? (
                                                                        (message.media ? (
                                                                            <div className='messagePreviewVideos' key={JSON.parse(message.media).id}>
                                                                                <video
                                                                                    src={`https://api.connectby.io/upload/${JSON.parse(message.media).id}.${getExtensionFromMimeType(JSON.parse(message.media).mime_type)}`}
                                                                                    controls
                                                                                    style={{ width: '100%' }}
                                                                                    onError={(e) => renderReceivedVideo(message.mainid, JSON.parse(message.media).id)}
                                                                                />
                                                                            </div>
                                                                        ) : (<div className='messagePreviewVideos' key={message.message}>
                                                                            <div>
                                                                                <Spin indicator={<LoadingOutlined spin />} />
                                                                            </div>
                                                                        </div>))
                                                                    ) : (
                                                                        <div className='messagePreviewVideos' key={message.video.id}>
                                                                            <video
                                                                                src={message.video.url}
                                                                                controls
                                                                                style={{ width: '100%' }} onError={(e) => renderReceivedVideo(message.mainid, message.video.id)}
                                                                            />
                                                                        </div>
                                                                    )}
                                                                </React.Fragment>
                                                            ))
                                                    ) : message.msgtype === 'audio' ? (
                                                        message.is_message === 'to' ? (
                                                            <React.Fragment>
                                                                <div>
                                                                    <audio controls onError={(e) => renderReceivedAudio(message.audio, message.audio)}>
                                                                        <source src={message.message} type="audio/ogg" />
                                                                        <source src={message.message} type="audio/mpeg" />
                                                                        Your browser does not support the audio element.
                                                                    </audio>
                                                                </div>
                                                            </React.Fragment>
                                                        ) : (
                                                            message.is_message === 'from' && (
                                                                <React.Fragment>
                                                                    {!message.audio ? (
                                                                        (message.media ? (
                                                                            <div key={JSON.parse(message.media).id}>
                                                                                <audio controls onError={(e) => renderReceivedAudio(message.mainid, JSON.parse(message.media).id)}>
                                                                                    <source src={`https://api.connectby.io/upload/${JSON.parse(message.media).id}.${getExtensionFromMimeType(JSON.parse(message.media).mime_type)}`} type="audio/ogg" />
                                                                                    <source src={`https://api.connectby.io/upload/${JSON.parse(message.media).id}.${getExtensionFromMimeType(JSON.parse(message.media).mime_type)}`} type="audio/mpeg" />
                                                                                    Your browser does not support the audio element.
                                                                                </audio>
                                                                            </div>
                                                                        ) : (<div className='messagePreviewVideos' key={message.message}>
                                                                            <div>
                                                                                <Spin indicator={<LoadingOutlined spin />} />
                                                                            </div>
                                                                        </div>))
                                                                    ) : (
                                                                        <div key={message.audio.id}>
                                                                            <audio controls onError={(e) => renderReceivedAudio(message.mainid, message.audio.id)}>
                                                                                <source src={message.audio.url} type="audio/ogg" />
                                                                                <source src={message.audio.url} type="audio/mpeg" />
                                                                                Your browser does not support the audio element.
                                                                            </audio>
                                                                        </div>
                                                                    )}
                                                                </React.Fragment>
                                                            ))

                                                    ) : message.msgtype === 'contacts' ? (
                                                        message.is_message === 'to' ? (
                                                            <React.Fragment>
                                                                <div>
                                                                    <audio controls onError={(e) => renderReceivedAudio(message.audio, message.audio)}>
                                                                        <source src={message.message} type="audio/ogg" />
                                                                        <source src={message.message} type="audio/mpeg" />
                                                                        Your browser does not support the audio element.
                                                                    </audio>
                                                                </div>
                                                            </React.Fragment>
                                                        ) : (
                                                            message.is_message === 'from' && (
                                                                <React.Fragment>
                                                                    {!message?.contacts ? (
                                                                        (message.media && (
                                                                            <div key={JSON.parse(message.media)?.id}>
                                                                                {console.log(JSON.parse(JSON.parse(message.media)?.callback)?.entry?.[0]?.changes?.[0]?.value?.messages?.[0]?.contacts)}
                                                                                <List
                                                                                    grid={{ gutter: 16, column: 1 }}
                                                                                    dataSource={JSON.parse(JSON.parse(message.media)?.callback)?.entry?.[0]?.changes?.[0]?.value?.messages?.[0]?.contacts}
                                                                                    renderItem={(contact) => (
                                                                                        <List.Item>
                                                                                            <Card style={{ padding: 10 }}>
                                                                                                <p><strong>{contact?.name?.formatted_name}</strong></p>
                                                                                                {contact?.phones.map((phone) => (
                                                                                                    <p><a href={`tel:${phone?.phone}`}>{phone?.phone}</a></p>
                                                                                                ))}
                                                                                            </Card>
                                                                                        </List.Item>
                                                                                    )}
                                                                                />
                                                                            </div>
                                                                        ))
                                                                    ) : (
                                                                        <div key={message.audio.id}>
                                                                            <audio controls onError={(e) => renderReceivedAudio(message.mainid, message.audio.id)}>
                                                                                <source src={message.audio.url} type="audio/ogg" />
                                                                                <source src={message.audio.url} type="audio/mpeg" />
                                                                                Your browser does not support the audio element.
                                                                            </audio>
                                                                        </div>
                                                                    )}
                                                                </React.Fragment>
                                                            ))

                                                    ) : message.msgtype === 'document' ? (
                                                        message.is_message === 'to' ? (
                                                            <React.Fragment>
                                                                <div className='messagePreviewImages'>
                                                                    <a href={message.message} target='_blank'>
                                                                        {getFileExtension(message.message) === 'pdf' ? (
                                                                            <img
                                                                                alt="Preview"
                                                                                style={{ width: '100%' }}
                                                                                src={pdfIcon}
                                                                                onError={() => console.error(`Failed to load image: ${message.message}`)}
                                                                            />
                                                                        ) : getFileExtension(message.message) === 'txt' ? (
                                                                            <img
                                                                                alt="Preview"
                                                                                style={{ width: '100%' }}
                                                                                src={textIcon}
                                                                                onError={() => console.error(`Failed to load image: ${message.message}`)}
                                                                            />
                                                                        ) : getFileExtension(message.message) === 'doc' || getFileExtension(message.message) === 'docx' ? (
                                                                            <img
                                                                                alt="Preview"
                                                                                style={{ width: '100%' }}
                                                                                src={wordIcon}
                                                                                onError={() => console.error(`Failed to load image: ${message.message}`)}
                                                                            />
                                                                        ) : getFileExtension(message.message) === 'ppt' || getFileExtension(message.message) === 'pptx' ? (
                                                                            <img
                                                                                alt="Preview"
                                                                                style={{ width: '100%' }}
                                                                                src={pptIcon}
                                                                                onError={() => console.error(`Failed to load image: ${message.message}`)}
                                                                            />
                                                                        ) : getFileExtension(message.message) === 'xlsx' || getFileExtension(message.message) === 'xls' || getFileExtension(message.message) === 'csv' ? (
                                                                            <img
                                                                                alt="Preview"
                                                                                style={{ width: '100%' }}
                                                                                src={excelIcon}
                                                                                onError={() => console.error(`Failed to load image: ${message.message}`)}
                                                                            />
                                                                        ) : (
                                                                            <img
                                                                                alt="Preview"
                                                                                style={{ width: '100%' }}
                                                                                src={fileIcon}
                                                                                onError={() => console.error(`Failed to load image: ${message.message}`)}
                                                                            />
                                                                        )}
                                                                    </a>
                                                                </div>
                                                            </React.Fragment>
                                                        ) : (
                                                            message.is_message === 'from' && (
                                                                <React.Fragment>
                                                                    {!message.document ? (
                                                                        (message.media ? (
                                                                            <div className='messagePreviewImages' key={JSON.parse(message.media).id}>
                                                                                <a href={`https://api.connectby.io/upload/${JSON.parse(message.media).id}.${getExtensionFromMimeType(JSON.parse(message.media).mime_type)}`} target='_blank'>
                                                                                    {getExtensionFromMimeType(JSON.parse(message.media).mime_type) === 'pdf' ? (
                                                                                        <img
                                                                                            alt="Preview"
                                                                                            style={{ width: '100%' }}
                                                                                            src={pdfIcon}
                                                                                            onError={() => console.error(`Failed to load image: ${getExtensionFromMimeType(JSON.parse(message.media).mime_type)}`)}
                                                                                        />
                                                                                    ) : getExtensionFromMimeType(JSON.parse(message.media).mime_type) === 'txt' ? (
                                                                                        <img
                                                                                            alt="Preview"
                                                                                            style={{ width: '100%' }}
                                                                                            src={textIcon}
                                                                                            onError={() => console.error(`Failed to load image: ${getExtensionFromMimeType(JSON.parse(message.media).mime_type)}`)}
                                                                                        />
                                                                                    ) : getExtensionFromMimeType(JSON.parse(message.media).mime_type) === 'doc' || getExtensionFromMimeType(JSON.parse(message.media).mime_type) === 'docx' ? (
                                                                                        <img
                                                                                            alt="Preview"
                                                                                            style={{ width: '100%' }}
                                                                                            src={wordIcon}
                                                                                            onError={() => console.error(`Failed to load image: ${getExtensionFromMimeType(JSON.parse(message.media).mime_type)}`)}
                                                                                        />
                                                                                    ) : getExtensionFromMimeType(JSON.parse(message.media).mime_type) === 'ppt' || getExtensionFromMimeType(JSON.parse(message.media).mime_type) === 'pptx' ? (
                                                                                        <img
                                                                                            alt="Preview"
                                                                                            style={{ width: '100%' }}
                                                                                            src={pptIcon}
                                                                                            onError={() => console.error(`Failed to load image: ${getExtensionFromMimeType(JSON.parse(message.media).mime_type)}`)}
                                                                                        />
                                                                                    ) : getExtensionFromMimeType(JSON.parse(message.media).mime_type) === 'xlsx' || getExtensionFromMimeType(JSON.parse(message.media).mime_type) === 'xls' || getExtensionFromMimeType(JSON.parse(message.media).mime_type) === 'csv' ? (
                                                                                        <img
                                                                                            alt="Preview"
                                                                                            style={{ width: '100%' }}
                                                                                            src={excelIcon}
                                                                                            onError={() => console.error(`Failed to load image: ${getExtensionFromMimeType(JSON.parse(message.media).mime_type)}`)}
                                                                                        />
                                                                                    ) : (
                                                                                        <img
                                                                                            alt="Preview"
                                                                                            style={{ width: '100%' }}
                                                                                            src={fileIcon}
                                                                                            onError={() => console.error(`Failed to load image: ${getExtensionFromMimeType(JSON.parse(message.media).mime_type)}`)}
                                                                                        />
                                                                                    )}
                                                                                </a>
                                                                                <img
                                                                                    src={`https://api.connectby.io/upload/${JSON.parse(message.media).id}.${getExtensionFromMimeType(JSON.parse(message.media).mime_type)}`}
                                                                                    alt="Media"
                                                                                    style={{ width: '100%', height: 'auto', display: 'none' }}
                                                                                    onError={() => {
                                                                                        renderReceivedDocument(message.mainid, JSON.parse(message.media).id);
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        ) : (<div className='messagePreviewImages' key={message.message} onClick={() => openModal()}>
                                                                            <div>
                                                                                <Spin indicator={<LoadingOutlined spin />} />
                                                                            </div>
                                                                        </div>))
                                                                    ) : (
                                                                        <div className='messagePreviewImages' key={message.document.id}>
                                                                            <a href={message.document.url} target='_blank'>
                                                                                {getFileExtension(message.document.url) === 'pdf' ? (
                                                                                    <img
                                                                                        alt="Preview"
                                                                                        style={{ width: '100%' }}
                                                                                        src={pdfIcon}
                                                                                        onError={() => console.error(`Failed to load image: ${message.document.url}`)}
                                                                                    />
                                                                                ) : getFileExtension(message.document.url) === 'txt' ? (
                                                                                    <img
                                                                                        alt="Preview"
                                                                                        style={{ width: '100%' }}
                                                                                        src={textIcon}
                                                                                        onError={() => console.error(`Failed to load image: ${message.document.url}`)}
                                                                                    />
                                                                                ) : getFileExtension(message.document.url) === 'doc' || getFileExtension(message.document.url) === 'docx' ? (
                                                                                    <img
                                                                                        alt="Preview"
                                                                                        style={{ width: '100%' }}
                                                                                        src={wordIcon}
                                                                                        onError={() => console.error(`Failed to load image: ${message.document.url}`)}
                                                                                    />
                                                                                ) : getFileExtension(message.document.url) === 'ppt' || getFileExtension(message.document.url) === 'pptx' ? (
                                                                                    <img
                                                                                        alt="Preview"
                                                                                        style={{ width: '100%' }}
                                                                                        src={pptIcon}
                                                                                        onError={() => console.error(`Failed to load image: ${message.document.url}`)}
                                                                                    />
                                                                                ) : getFileExtension(message.document.url) === 'xlsx' || getFileExtension(message.document.url) === 'xls' || getFileExtension(message.document.url) === 'csv' ? (
                                                                                    <img
                                                                                        alt="Preview"
                                                                                        style={{ width: '100%' }}
                                                                                        src={excelIcon}
                                                                                        onError={() => console.error(`Failed to load image: ${message.document.url}`)}
                                                                                    />
                                                                                ) : (
                                                                                    <img
                                                                                        alt="Preview"
                                                                                        style={{ width: '100%' }}
                                                                                        src={fileIcon}
                                                                                        onError={() => console.error(`Failed to load image: ${message.document.url}`)}
                                                                                    />
                                                                                )}
                                                                            </a>
                                                                        </div>
                                                                    )}
                                                                </React.Fragment>
                                                            ))
                                                    ) : message.msgtype === 'order' ? (
                                                        message.is_message === 'from' && (
                                                            <React.Fragment>
                                                                {/* {message.media && ( */}
                                                                <>Order</>
                                                                {/* )} */}
                                                            </React.Fragment>
                                                        )
                                                    ) : message.msgtype === 'payment' ? (
                                                        message.is_message === 'from' && (
                                                            <React.Fragment>
                                                                {/* {message.media && ( )} */}
                                                                <>Payment</>
                                                            </React.Fragment>
                                                        )
                                                    ) : (
                                                        message.is_message === 'from' && (
                                                            <div className='messageArea'>
                                                                {JSON.parse(message.media)['receivedMessage.type']?.button?.text}
                                                            </div>
                                                        )
                                                    )
                                                    }
                                                    <span>{new Intl.DateTimeFormat('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }).format(new Date(message.created * 1000))}</span>
                                                    {message.is_message === 'to' && message.status === 'sending' ? (
                                                        <span key={`status${message.mainid}`} className='message_sending'><ClockCircleOutlined /></span>
                                                    ) : message.is_message === 'to' && message.status === 'sent' ? (
                                                        <span key={`status${message.mainid}`} className='message_sent'><CheckOutlined /></span>
                                                    ) : message.is_message === 'to' && message.status === 'delivered' ? (
                                                        <span key={`status${message.mainid}`} className='message_delivered'><CheckOutlined className='doubletick_one' /><CheckOutlined className='doubletick_two' /></span>
                                                    ) : message.is_message === 'to' && message.status === 'read' ? (
                                                        <span key={`status${message.mainid}`} className='message_read'><CheckOutlined className='doubletick_one' /><CheckOutlined className='doubletick_two' /></span>
                                                    ) : (
                                                        ''
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                            <div className="inputArea">
                                <Tabs defaultActiveKey="1" items={itemsOne} onChange={onChange} className="InputAreaTabs" />

                                <div className='chooseTemplate' onClick={showTemplates}>
                                    <ReactSVG src={templateSvg} className="templateSvg" /> Choose Template
                                </div>
                            </div>
                        </Card>
                    ) : (
                        <Card style={{ height: `calc(100vh - 112px)` }} className='emptyCard'>
                            <Empty description={<span>Start Conversation</span>} />
                        </Card>
                    )}
                </Col>
                {/* <Col span={6}>
                    <Card title={<span>User Details <span style={{ float: 'right' }}><SettingOutlined /></span></span>} style={{ height: 612 }} className='DetailCard'>
                        <div>
                            <p>User details</p>
                        </div>
                    </Card>
                </Col> */}
            </Row >
            <Modal
                open={modalIsOpen}
                title=""
                onCancel={closeModal}
                centered
                width="800px"
                footer=''
                className='previewModalClass'
            >
                <img
                    alt="Preview"
                    style={{ width: '100%', maxWidth: '300px' }}
                    src={modalImageUrl}
                    onError={() => console.error(`Failed to load image in modal: ${modalImageUrl}`)}
                />
            </Modal>
            <Modal
                title={<div className='templateTitle'>
                    <ReactSVG src={templateSvg} className="templateSvg" />
                    <span>Select template</span>
                </div>}
                open={isModalVisible}
                onCancel={hideTemplates}
                tmpBtnLoading
                footer={[<Button type="primary" shape="circle" className="sendtemplateButton" icon={tmpBtnLoading ? (<Spin indicator={<LoadingOutlined spin />} />) : (<SendOutlined />)} size={'large'} onClick={handleSendTemplate} disabled={tmpBtnLoading} />]}
                centered
                width="800px"
            >
                <div className='templateData'>
                    <Row>
                        <Col span={8} className='templateDatacolumn'>
                            {/* <Space direction="vertical" style={{ width: '100%' }} >
                                <Search placeholder="Search template" onSearch={onSearch} />
                            </Space> */}
                            <List>
                                <VirtualList
                                    data={templatedata}
                                    height={ContainerHeight}
                                    itemHeight={47}
                                    itemKey={(index) => {
                                        return index.id;
                                    }}
                                    onScroll={onScrollTemplate}
                                >
                                    {(item, index) => {
                                        return (
                                            <List.Item
                                                key={item.id || index}
                                                onClick={() => opentemplate(item)}
                                                className={
                                                    activeTemplateItem.id === item.id ? 'templateList active' : 'templateList'
                                                }
                                            >
                                                {(isLoading || !item.id) ? (
                                                    <Skeleton avatar active>
                                                        <List.Item.Meta
                                                            avatar={<Avatar />}
                                                            title={<Skeleton.Input style={{ width: '40px' }} />}
                                                            description={<Skeleton.Input style={{ width: '80px' }} />}
                                                        />
                                                    </Skeleton>
                                                ) : (
                                                    <List.Item.Meta
                                                        title={`${item.name.replace(/_/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase())} (${item.language})`}
                                                    />
                                                )}
                                            </List.Item>
                                        );
                                    }}
                                </VirtualList>
                            </List>
                        </Col>
                        <Col span={16} style={{ padding: '0 12px' }}>
                            <p><b>Message</b></p>
                            <div className='templatemessage'>
                                <div className="message-box preview-message">
                                    <p>
                                        {templatePreview && templatePreview.length > 0 ? (
                                            templatePreview.map((component) => (

                                                <React.Fragment key={component.type}>
                                                    {component.type === "HEADER" && (
                                                        component.format === 'TEXT' ? (
                                                            <p className='headeretxt'>{component.text}</p>
                                                        ) : component.format === 'IMAGE' ? (
                                                            <div className='previewContent'><img alt="Header" src={component.example.header_handle[0]} style={{ height: '200px', width: 'auto' }} /></div>
                                                        ) : component.format === 'VIDEO' ? (
                                                            <div className='previewContent'><video src={component.example.header_handle[0]} controls style={{ width: '100%' }} /></div>
                                                        ) : component.format === 'DOCUMENT' ? (
                                                            <div className='previewContent'><embed src={component.example.header_handle[0]} type="application/pdf" style={{ width: '100%', height: '500px' }} /></div>
                                                        ) : (
                                                            <p>Unsupported format</p>
                                                        )
                                                    )}
                                                    {component.type === "BODY" && (
                                                        <div className='bodytext'>{component.text}</div>
                                                    )}
                                                    {component.type === "FOOTER" && (
                                                        <div className='footertext'>{component.text}</div>
                                                    )}{component.type === "BUTTONS" && (
                                                        <React.Fragment>
                                                            <hr className='buttonhr' />
                                                            {component.buttons.map((button) => (
                                                                <div key={button.id}>
                                                                    {
                                                                        button.type === 'QUICK_REPLY' ? (
                                                                            <>
                                                                                <div className='quickreply'>
                                                                                    <a target="_blank" href="javascript:void(0)" rel="noreferrer">
                                                                                        {button.text}
                                                                                    </a>
                                                                                </div>
                                                                            </>
                                                                        ) : (
                                                                            ''
                                                                        )}
                                                                    {
                                                                        button.type === 'URL' ? (
                                                                            <>
                                                                                <div className='urlbtn'>
                                                                                    <a target="_blank" href={button.url} rel="noreferrer">
                                                                                        <LinkOutlined />
                                                                                        {button.text}</a>
                                                                                </div>
                                                                            </>
                                                                        ) : (
                                                                            ''
                                                                        )}
                                                                    {
                                                                        button.type === 'PHONE_NUMBER' ? (
                                                                            <>
                                                                                <div className='phonebtn'>
                                                                                    <a target="_blank" href={`tel:${button.phone_number}`} rel="noreferrer">
                                                                                        <PhoneOutlined />
                                                                                        {button.text}</a>
                                                                                </div>
                                                                            </>
                                                                        ) : (
                                                                            ''
                                                                        )}
                                                                        {
                                                                        button.type === 'FLOW' ? (
                                                                            <>
                                                                                <div className='quickreply'>
                                                                                    <a target="_blank" href="javascript:void(0)" rel="noreferrer">
                                                                                        {button.text}
                                                                                    </a>
                                                                                </div>
                                                                            </>
                                                                        ) : (
                                                                            ''
                                                                        )}
                                                                </div>
                                                            ))}
                                                        </React.Fragment>
                                                    )}

                                                </React.Fragment>
                                            ))
                                        ) : (
                                            <p>Please Select the Template.</p>
                                        )}
                                    </p>
                                </div>
                                <hr />
                                <div className='variableData'>
                                    {templatePreview && templatePreview.length > 0 ? (
                                        templatePreview.map((component) => (
                                            <React.Fragment key={component.type}>
                                                {component.type === "HEADER" && (
                                                    component.format === 'TEXT' && component.text.match(/{{\d+}}/g) ? (
                                                        <div>
                                                            <h4>Header</h4>
                                                            {component.text.match(/{{\d+}}/g).map(element => (
                                                                <Row key={`H${component.type}-${element}`} style={{ marginTop: '6px', marginBottom: '6px' }}>
                                                                    <Col span={12} style={{ paddingRight: '6px' }}>
                                                                        <Select
                                                                            showSearch
                                                                            placeholder="Select Predefined Options"
                                                                            optionFilterProp="children"
                                                                            onChange={(value) => onVarChange(`${component.type}-${element}`, value)}
                                                                            onSearch={onVarSearch}
                                                                            filterOption={inputfilterOption}
                                                                            options={filterOption}
                                                                            style={{ width: '100%' }}
                                                                            value={selectedValues[`${component.type}-${element}`] ? selectedValues[`${component.type}-${element}`] : undefined}
                                                                        />
                                                                    </Col>
                                                                    <Col span={12} style={{ paddingLeft: '6px' }}>
                                                                        <Input
                                                                            placeholder={`Enter Text for variable ${element}`}
                                                                            prefix={element}
                                                                            value={selectedValues[`${component.type}-${element}`]}
                                                                        />
                                                                    </Col>
                                                                </Row>
                                                            ))}
                                                        </div>
                                                    ) : component.format === 'IMAGE' ? (
                                                        <Row>
                                                            <Col span={24}>
                                                                <h4>Header</h4>
                                                            </Col>
                                                            <Col span={6} style={{ paddingRight: '6px', maxHeight: '100px', overflow: 'hidden' }}>
                                                                <div className='previewContent'><img alt="Header" src={(templateImage !== '') ? (templateImage) : (component.example.header_handle[0])} style={{ height: '100px', width: 'auto' }} /></div>
                                                            </Col>
                                                            <Col span={18} style={{ paddingLeft: '6px' }}>
                                                                <Upload
                                                                    accept=".jpeg,.png"
                                                                    listType="picture-card"
                                                                    className="avatar-uploader"
                                                                    customRequest={(fileInfo) => customRequestImage()(fileInfo)}
                                                                    showUploadList={false}
                                                                    action="https://connectby.io:3001/api/upload-temp-media/"
                                                                    beforeUpload={checkFile}
                                                                    onChange={(info) => handleChange(info)}
                                                                >
                                                                    {uploadButton}
                                                                </Upload>
                                                            </Col>

                                                        </Row>
                                                    ) : component.format === 'VIDEO' ? (
                                                        <Row>
                                                            <Col span={24}>
                                                                <h4>Header</h4>
                                                            </Col>
                                                            <Col span={6} style={{ maxHeight: '60px', overflow: 'hidden' }}>
                                                                <div className='previewContent'><video src={component.example.header_handle[0]} controls style={{ width: '100%' }} /></div>
                                                            </Col>
                                                            <Col span={18}>
                                                                <Upload
                                                                    fileList={fileList}
                                                                    customRequest={customRequest}
                                                                    showUploadList={false}
                                                                    beforeUpload={checkFile}
                                                                    accept=".mp4"
                                                                    onChange={({ fileList }) => setFileList(fileList)}
                                                                >
                                                                    <Button className='addMoreFile'>
                                                                        <PlusOutlined />
                                                                    </Button>
                                                                </Upload>
                                                            </Col>
                                                        </Row>
                                                    ) : component.format === 'DOCUMENT' ? (
                                                        <Row>
                                                            <Col span={24}>
                                                                <h4>Header</h4>
                                                            </Col>
                                                            <Col span={6} style={{ maxHeight: '60px', overflow: 'hidden' }}>
                                                                <div className='previewContent'><embed src={component.example.header_handle[0]} type="application/pdf" style={{ width: '100%', height: '500px' }} /></div>
                                                            </Col>
                                                            <Col span={18}>
                                                                <Upload
                                                                    fileList={fileList}
                                                                    customRequest={customRequest}
                                                                    showUploadList={false}
                                                                    beforeUpload={checkFile}
                                                                    accept=".pdf"
                                                                    onChange={({ fileList }) => setFileList(fileList)}
                                                                >
                                                                    <Button className='addMoreFile'>
                                                                        <PlusOutlined />
                                                                    </Button>
                                                                </Upload>
                                                            </Col>
                                                        </Row>
                                                    ) : (
                                                        ''
                                                    )
                                                )}
                                                {component.type === "BODY" && (
                                                    <div>
                                                        <h4>Body</h4>
                                                        {component.text.match(/{{\d+}}/g) && component.text.match(/{{\d+}}/g).length > 0 ? (
                                                            component.text.match(/{{\d+}}/g).map(element => (
                                                                <>
                                                                    <Row key={`${component.type}-${element}`} style={{ marginTop: '6px', marginBottom: '6px' }}>
                                                                        <Col span={12} style={{ paddingRight: '6px' }}>
                                                                            <Select
                                                                                showSearch
                                                                                placeholder="Select Predefined Options"
                                                                                optionFilterProp="children"
                                                                                onChange={(value) => onVarChange(`${component.type}-${element}`, value)}
                                                                                onSearch={onVarSearch}
                                                                                filterOption={inputfilterOption}
                                                                                options={filterOption}
                                                                                style={{ width: '100%' }}
                                                                                value={selectedValues[`${component.type}-${element}`] ? selectedValues[`${component.type}-${element}`] : undefined}
                                                                            />
                                                                        </Col>
                                                                        <Col span={12} style={{ paddingLeft: '6px' }}>
                                                                            <Input
                                                                                placeholder={`Enter Text for variable ${element}`}
                                                                                prefix={element}
                                                                                value={selectedValues[`${component.type}-${element}`]}
                                                                            />
                                                                        </Col>
                                                                    </Row>
                                                                </>
                                                            ))
                                                        ) : (
                                                            ''
                                                        )}
                                                    </div>
                                                )}
                                            </React.Fragment>
                                        ))
                                    ) : (
                                        ''
                                    )}
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Modal>
            <Modal title="Quick Reply Messages" open={isPopupVisible} onCancel={() => { setIsPopupVisible(false) }} footer={null}>
                <List
                    dataSource={quickReplies}
                    renderItem={(item, index) => (
                        <List.Item hoverable onClick={() => handleQuickReplyClick(item?.qr_message)} style={{ cursor: 'pointer' }}>
                            <p><strong>{index + 1}</strong>.</p> {item?.qr_message}
                        </List.Item>
                    )}
                />
            </Modal>
            <Drawer
                title={<><TagsOutlined />Tag List</>}
                width={540}
                onClose={onCloseTagPopup}
                open={tegPopup}
                styles={{
                    body: {
                        paddingBottom: 80,
                    },
                }}
                footer={
                    <div style={{ textAlign: 'right' }}>
                        <Space>
                            <Button type='primary' onClick={handleAssignTags}>Assign Tags</Button>
                            <Button onClick={onCloseTagPopup}>Close</Button>
                        </Space>
                    </div>
                }
            >
                <Form layout="vertical" form={formOne} onFinish={handleAddTag}>
                    <Row gutter={16}>
                        <Col span={18}>
                            <Form.Item
                                name="tag_name"
                                label="Tag Name"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter Tag name',
                                    },
                                ]}
                            >
                                <Input placeholder="Please enter Tag Name" />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item>
                                <label className='emptyLabel'>&nbsp;</label>
                                {tagLoading}
                                <Button type="primary" htmlType="submit" style={{ width: '100%' }} disabled={tagLoading}>{tagLoading ? (<Spin indicator={<LoadingOutlined spin />} />) : ('Add New Tag')}</Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                <hr />
                <Search placeholder="Search Tags by tag name" value={tagsearchTerm} onChange={handleSearchChange} style={{ width: '100%' }} />
                <ul className="tagListUl">
                    {filteredTags.length > 0 ? (
                        filteredTags.map(tag => (
                            <li key={tag.tag_id} onClick={() => handleToggleTag(tag.tag_id)} style={{ cursor: 'pointer' }} >
                                <div className="tagListLi"> <input
                                    type="checkbox"
                                    checked={!!selectedTags[tag.tag_id]}
                                    onChange={() => handleToggleTag(tag.tag_id)}
                                    onClick={(e) => e.stopPropagation()}
                                /> {tag.tag_name}</div>
                            </li>
                        ))
                    ) : (
                        <li>No tags found</li>
                    )}
                </ul>
            </Drawer>
            <Drawer
                title={<><UserAddOutlined /> User Details <span style={{ float: 'right' }}><Button onClick={handleBlockClick} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}><Block style={{ fontSize: 15 }} /> Block</Button></span></>}
                width={720}
                onClose={onCloseNewUser}
                open={userMenu}
                styles={{
                    body: {
                        paddingBottom: 80,
                    },
                }}
                footer={
                    <div style={{ textAlign: 'right' }}>
                        <Space>
                            <Button onClick={onCloseNewUser}>Close</Button>
                        </Space>
                    </div>
                }
            >
                <Form layout="vertical" form={form} onFinish={handleUpdate}>
                    <Form.Item name="customer_id" hidden>
                        <Input />
                    </Form.Item>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="customer_name"
                                label="Customer Name"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter customer name',
                                    },
                                ]}
                            >
                                <Input placeholder="Please enter customer name" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Customer Phone No."
                                name="customer_phone"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter phone number',
                                    },
                                ]}>
                                <Input placeholder="Please enter phone number" disabled />
                            </Form.Item>
                        </Col>
                        <Col span={10}>
                            <Form.Item
                                name="customer_email"
                                label="Customer Email"
                                rules={[
                                    {
                                        required: false,
                                        message: 'Please enter email',
                                    },
                                ]}
                            >
                                <Input placeholder="Please enter email" />
                            </Form.Item>
                        </Col>
                        <Col span={10}>
                            <Form.Item
                                name="customer_gender"
                                label="Gender"
                                rules={[
                                    {
                                        required: false,
                                        message: 'Please select gender',
                                    },
                                ]}
                            >
                                <Select placeholder="Please select gender">
                                    <Option value="male">Male</Option>
                                    <Option value="female">Female</Option>
                                    <Option value="other">Other</Option>
                                    <Option value="notdeclared">Don't declared</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item>
                                <label className='emptyLabel'>&nbsp;</label>
                                <Button type="primary" htmlType="submit" style={{ width: '100%' }}>Update</Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                <hr />
                <div>
                    <Row gutter={16}>
                        <Col span={18}>
                            <Title level={4} style={{ marginTop: 0 }}>Tags</Title>
                        </Col>
                        <Col span={6}>
                            <Button onClick={handleTagPopup} style={{ float: 'right' }}>Edit</Button>
                        </Col>
                    </Row>
                    {assignedTagsData.length > 0 ? (
                        <ul className='tagsul'>
                            {assignedTagsData.map(assignedTags => (
                                <li key={assignedTags.tag_id}><TagOutlined /> <Tooltip title="Tag One">{assignedTags.tag_name}</Tooltip></li>
                            ))}
                        </ul>
                    ) : (
                        <p style={{ textAlign: 'center' }}><TagsOutlined style={{ fontSize: 30 }} /><br /><span>No tags assigned</span></p>
                    )}
                </div>
                <hr />
                <div>
                    <Title level={4}>Notes</Title>
                    {noteViewLoader ? (
                        <Spin className='spinner' />
                    ) : (
                        <ul className='notesul'>
                            {notes.map(note => (
                                <li key={note.id}><div className='notesli'>{note.note}</div><div className='notesTime'>{formatDate(note.created_at)}</div></li>
                            ))}
                            {activeItem.note !== '' && (
                                <li key={activeItem.id}><div className='notesli'>{activeItem.note}</div><div className='notesTime'></div></li>
                            )}
                        </ul>
                    )}
                    {notes.length <= 0 && activeItem.note === '' && (
                        <p style={{ textAlign: 'center' }}><SnippetsOutlined style={{ fontSize: 30 }} /><br /><span>No nots added</span></p>
                    )}
                </div>
            </Drawer>
        </>
    );
};
export default Chatbox;