import React, { useRef, useCallback, useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import CurrencyList from 'currency-list';
import { Switch, Card, TimePicker, Modal, Table, Skeleton, Dropdown, Pagination, List, Button, Menu, Flex, message, Input, Tooltip, Spin, Popconfirm, Col, Row, Drawer, Form, Select, Space, DatePicker, Typography, Checkbox, Layout, Upload } from 'antd';
import { InboxOutlined, ApartmentOutlined, BookOutlined, ShoppingCartOutlined, FacebookOutlined, PlusOutlined, EyeOutlined, EditOutlined, DeleteOutlined, CopyOutlined, LoadingOutlined, SyncOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
const { Title, Text } = Typography;
const { Option } = Select;
const { Header, Content } = Layout;
const { TextArea } = Input;
const { Meta } = Card;
const currencies = CurrencyList?.default?.currencyList?.['en_US'];
// console.log(currencies['INR'].name);

// // Convert currencies to options for react-select
// const currencyOptions = Object.keys(currencies).map(code => (
//     <Option key={code} value={code}>
//       {code} - {currencies[code]?.name}
//     </Option>
// ));

const Automation = (userData) => {
  const navigate = useNavigate();
  const location = useLocation();
  const query = location.search.substring(1);
  const [activeMenu, setActiveMenu] = useState(query ? query : 'inbox');
  const [automationData, setAutomationData] = useState([]);
  const [whatsappFlowData, setWhatsappFlowData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [autoData, setAutoData] = useState([]);
  const [autoLoader, setAutoLoader] = useState(false);
  const [messageLoader, setMessageLoader] = useState(false);
  const [messageLoaderOne, setMessageLoaderOne] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [deleteId, setDeleteId] = useState('');
  const [blockLoading, setBlockLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [paging, setPaging] = useState({});
  const [productLoading, setProductLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedProduct, setSelectedProduct] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedEditProduct, setSelectedEditProduct] = useState(null);
  const [editPriductform] = Form.useForm();
  const [searchValue, setSearchValue] = useState('');
  useEffect(() => {
    fetchAutomationData();
    fetchWelcomeData();
    fetchData();
    fetchWhatsappFlows();
  }, [userData]);


  const fetchData = async (page = '') => {
    setProductLoading(true);
    const url = 'https://connectby.io:3001/api/get-catalog';
    try {
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'whatsappaccesstoken': userData?.userData[0]?.whatsappAccessToken,
          'catalougid': userData?.userData[0]?.cataloug_id
        },
        body: JSON.stringify({ "page": page }),
      };
      const response = await fetch(url, requestOptions);
      const data = await response.json();
      if (data.success) {
        console.log(data);
        if (data.data.length !== 0) {
          setProducts(data.data);
          setPaging(data.paging);
        }
      } else {
        console.error('Failed to fetch data:', data.error);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setProductLoading(false);
    }
  };

  const handlePreviousPage = () => {
    if (paging.previous) {
      fetchData(paging.previous);
    }
  };

  const handleNextPage = () => {
    if (paging.next) {
      fetchData(paging.next);
    }
  };

  const fetchAutomationData = async () => {
    try {
      const userId = userData?.userData[0]?.user_id;
      const response = await fetch('https://connectby.io:3001/api/get-automation', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ user_id: userId })
      });

      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }

      const data = await response.json();
      setAutomationData(data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };

  const fetchWhatsappFlows = async () => {
    try {
      const requestOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'whatsappaccesstoken': userData?.userData[0]?.whatsappAccessToken,
          'wabaid': userData?.userData[0]?.wabaid
        }
      };
      const response = await fetch('https://connectby.io:3001/api/get-flows', requestOptions);

      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }

      const data = await response.json();
      console.log(data);
      setWhatsappFlowData(data?.responseData?.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };

  const fetchWelcomeData = async () => {
    try {
      const userId = userData?.userData[0]?.user_id;
      const response = await fetch(`https://connectby.io:3001/api/get_user_data_phone/${userId}`);
      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }
      const data = await response.json();
      setAutoData(data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };

  const handleTimeChange = (time, timeString) => {
    console.log(time, timeString);
  };

  const handleDeleteFlow = (id) => {
    setDeleteLoader(true);
    fetch(`https://connectby.io:3001/api/delete_flow?automationListId=${id}`, {
      method: 'DELETE',
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to delete user');
        }
        return response.json();
      })
      .then(data => {
        message.success('Flow deleted successfully');
        const updatedData = automationData.filter(item => item.automation_list_id !== id);
        setAutomationData(updatedData);
      })
      .catch(error => {
        console.error('Error deleting Flow:', error.message);
        message.error('Failed to delete Flow');
      }).finally(() => {
        setDeleteLoader(false);
      });
  };


  const handleDeleteWhatsappFlow = (id) => {
    setDeleteLoader(true);
    setDeleteId(id);
    fetch(`https://connectby.io:3001/api/delete-whatsapp-flow?flowId=${id}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'whatsappaccesstoken': userData?.userData[0]?.whatsappAccessToken,
      },
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to delete user');
        }
        return response.json();
      })
      .then(data => {
        message.success('Flow deleted successfully');
        const updatedData = whatsappFlowData.filter(item => item.id !== id);
        setWhatsappFlowData(updatedData);
      })
      .catch(error => {
        console.error('Error deleting Flow:', error.message);
        message.error('Failed to delete Flow');
      }).finally(() => {
        setDeleteLoader(false);
        setDeleteId('');
      });
  };

  const handleBlock = async (automation_list_id, status) => {
    setBlockLoading(true);
    try {
      const response = await fetch('https://connectby.io:3001/api/active-deactive-automation', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          automation_list_id: automation_list_id,
          crm_user_id: userData?.userData[0]?.user_id,
          status: status
        }),
      });
      if (response.ok) {
        setAutomationData(automationData.map(automation =>
          automation.automation_list_id === automation_list_id ? { ...automation, is_active: status } : automation
        ));
        if (status === '1') {
          message.success('Activate');
        } else {
          message.success('De-activate');
        }
      } else {
        const errorMessage = await response.json();
        message.error(errorMessage.message || 'Unknown error');
      }

    } catch (error) {
      console.log(error);
      console.error('Error Creating Tag:', error);
    } finally {
      setBlockLoading(false);
    }
  };

  const handleSelectChange = (value) => {
    console.log(value);
  };

  const columns = [
    {
      title: 'Flow Name',
      dataIndex: 'flow_name',
      key: 'flow_name',
    },
    {
      title: 'Keyword',
      dataIndex: 'trigger_name',
      key: 'trigger_name',
    },
    {
      title: 'Created At',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (timestamp) => (
        <span>{dayjs.unix(timestamp).format('YYYY-MM-DD HH:mm:ss')}</span>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'is_active',
      key: 'is_active',
      render: (text, record) => {
        return (
          <div style={{ justifyContent: 'center', textAlign: 'center' }}>
            <Switch
              onClick={() => handleBlock(record.automation_list_id, record.is_active === '2' ? '1' : '2')}
              checkedChildren="De-active"
              unCheckedChildren="Active"
              defaultChecked={record.is_active === '1'}
              loading={blockLoading}
            />
            <p style={{ fontSize: 10 }}>({record.is_active === '2' ? 'De-activate' : 'Activate'})</p>
          </div>
        )
      }
    },
    {
      title: 'Actions',
      key: 'automation_list_id',
      render: (text, record) => (
        <div style={{ float: 'right' }}>
          <Link
            to="/flow"
            state={{ record, is_edit: 'no' }}
          >
            <Button icon={<CopyOutlined />} style={{ marginRight: 8 }} />
          </Link>
          <Link
            to="/flow"
            state={{ record, is_edit: 'yes' }}
          >
            <Button icon={<EditOutlined />} onClick={() => { }} style={{ marginRight: 8 }} />
          </Link>
          <Popconfirm
            title="Delete the flow"
            description="Are you sure to delete this flow?"
            onConfirm={() => handleDeleteFlow(record.automation_list_id)}
            okText="Yes"
            cancelText="No"
          >
            <Tooltip title="Delete Customer">
              {deleteLoader ? (
                <Button type="primary" danger>
                  <Spin style={{ color: "#fff" }} indicator={<LoadingOutlined spin />} />
                </Button>
              ) : (
                <Button type="primary" danger icon={<DeleteOutlined />} />
              )}
            </Tooltip>
          </Popconfirm>
        </div>
      ),
    },
  ];

  const whatsappColumns = [
    {
      title: 'Flow Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Flow id',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (txt, record) => {
        const getStatusStyles = (status) => {
          switch (status) {
            case 'DRAFT':
              return { color: '#FF5722', backgroundColor: '#ffe4dc', text: 'Draft' };
            case 'PUBLISHED':
              return { color: '#0a7e0f', backgroundColor: '#b8ffbb', text: 'Published' };
            case 'DEPRECATED':
              return { color: '#9e9e9e', backgroundColor: '#f5f5f5', text: 'Deprecated' };
            case 'BLOCKED':
              return { color: '#f44336', backgroundColor: '#ffe6e6', text: 'Blocked' };
            case 'THROTTLED':
              return { color: '#ff9800', backgroundColor: '#fff3e0', text: 'Throttled' };
            default:
              return { color: '#000000', backgroundColor: '#ffffff', text: 'Unknown' };
          }
        };
    
        const { color, backgroundColor, text } = getStatusStyles(record.status);
        return (
          <div style={{ justifyContent: 'center', textAlign: 'center' }}>
            <span
              style={{
                color,
                backgroundColor,
                width: 100,
                display: 'block',
                borderRadius: 8,
                fontWeight: 'bold',
                padding: '4px 8px',
              }}
            >
              {text}
            </span>
          </div>
        );
      },
    },    
    {
      title: 'Actions',
      key: 'flow_id',
      render: (text, record) => (
        <div style={{ float: 'right' }}>
          <Link
            to="/whatsapp-flow"
            state={{ record, is_edit: 'no' }}
          >
            <Button icon={<CopyOutlined />} style={{ marginRight: 8 }} disabled={record.status !== 'DRAFT' && record.status !== 'PUBLISHED' && record.status !== 'DEPRECATED'} />
          </Link>
          <Link
            to={`/flow-response/${record.id}`}
            disabled={record.status !== 'PUBLISHED'}
          >
            <Button icon={<EyeOutlined />} onClick={() => { }} style={{ marginRight: 8 }}  disabled={record.status !== 'PUBLISHED'} />
          </Link>
          <Link
            to={`/whatsapp-flow/${record.id}`}
            state={{ record, is_edit: 'yes' }}
            disabled={record.status === 'PUBLISHED'}
          >
            <Button icon={<EditOutlined />} onClick={() => { }} style={{ marginRight: 8 }}  disabled={record.status !== 'DRAFT'} />
          </Link>
          <Popconfirm
            title="Delete the flow"
            description="Are you sure to delete this flow?"
            onConfirm={() => handleDeleteWhatsappFlow(record.id)}
            okText="Yes"
            cancelText="No"
          >
            <Tooltip title="Delete Flow">
              {deleteLoader && deleteId === record.id ? (
                <Button type="primary" danger>
                  <Spin style={{ color: "#fff" }} indicator={<LoadingOutlined spin />} />
                </Button>
              ) : (
                <Button type="primary" danger icon={<DeleteOutlined />} disabled={record.status !== 'DRAFT'} />
              )}
            </Tooltip>
          </Popconfirm>
        </div>
      ),
    },
  ];

  const menudata = [
    {
      id: 'inbox',
      name: 'Inbox Settings',
      icon: <InboxOutlined />
    },
    {
      id: 'auto-reply',
      name: 'Custom Auto Reply',
      icon: <InboxOutlined />
    },
    {
      id: 'flow-builder',
      name: 'Chat bot',
      icon: <ApartmentOutlined />
    },
    {
      id: 'whatsapp-flows',
      name: 'WhatsApp Flows',
      icon: <ApartmentOutlined />
    }
  ];


  const handleOnChange = async (keyname, status) => {
    setAutoLoader(true);
    try {
      const userId = userData?.userData[0]?.user_id;
      const response = await fetch(`https://connectby.io:3001/api/update-usermessagedata/${userId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ keyname, status })
      });

      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }
      setAutoData(prevData =>
        prevData.map(item => ({
          ...item,
          [keyname]: status
        }))
      );
      message.success('Status Updated.');
    } catch (error) {
      console.error('Error fetching data:', error);
      message.error('Failed to update status.');
    } finally {
      setAutoLoader(false);
    }
  };

  const handleUpdate = async (keyname) => {
    if (keyname === 'out_office_message') {
      setMessageLoader(true);
    } else {
      setMessageLoaderOne(true);
    }

    try {
      const userId = userData?.userData[0]?.user_id;
      const response = await fetch(`https://connectby.io:3001/api/update-user-message/${userId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ welcome_message: autoData[0]?.welcome_message, out_office_message: autoData[0]?.out_office_message })
      });

      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }
      message.success('Message Updated.');
    } catch (error) {
      console.error('Error fetching data:', error);
      message.error('Failed to update status.');
    } finally {
      if (keyname === 'out_office_message') {
        setMessageLoader(false);
      } else {
        setMessageLoaderOne(false);
      }
    }
  };

  const handleSearch = (value) => {
    setSearchQuery(value);
  };

  const filteredProducts = products.filter(product =>
    product.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleEditProduct = (record) => {
    setSelectedEditProduct(record);
  };

  const handleViewProduct = (record) => {
    setSelectedProduct(record);
    setIsModalOpen(true);
  };

  const productColumns = [
    {
      title: 'Product',
      dataIndex: 'name',
      key: 'name',
      render: (_, record) => <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}><span style={{ width: 50, height: 50, display: 'inline-block' }}><img style={{ width: '100%', }} alt={record.name} src={record.image_url} /></span> <span>{record.name}</span></div>
    },
    {
      title: 'visibility',
      dataIndex: 'visibility',
      key: 'visibility',
      render: (_, record) => (
        <span style={{ backgroundColor: record.visibility === 'published' ? 'green' : 'red', color: '#fff', padding: 5, borderRadius: 10 }}>
          {record.visibility}
        </span>
      )
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price',
      render: (price) => <span>{price}</span>,
    }, {
      title: 'Action',
      dataIndex: 'id',
      key: 'id',
      render: (_, record) => (
        <Flex gap="small" align="flex-end" vertical>
          <Flex gap="small">
            <Tooltip title="View Product">
              <Button type="primary" className='viewButton' shape="circle" icon={<EyeOutlined />} onClick={() => handleViewProduct(record)} />
            </Tooltip>
            <Tooltip title="Edit Product">
              <Button type="primary" className='editButton' shape="circle" icon={<EditOutlined />} onClick={() => handleEditProduct(record)} />
            </Tooltip>
          </Flex>
        </Flex>
      )
    }
  ];

  const onSearch = (value) => {
    setSearchValue(value);
  };

  const handleCheckboxChange = (e) => {
    console.log(e.target.checked);
    setSelectedProduct(prevProduct => ({
      ...prevProduct,
      sale_price: e.target.checked
    }));
  };

  const handleOnchangeValue = (e) => {
    console.log(e.target.value);
    setSelectedEditProduct(prevEditProduct => ({
      ...prevEditProduct,
      sale_price: e.target.value
    }));
  };

  const filteredCurrencyOptions = Object.keys(currencies)
    .filter(code => {
      const name = currencies[code]?.name.toLowerCase() || '';
      return code.toLowerCase().includes(searchValue.toLowerCase()) ||
        name.includes(searchValue.toLowerCase());
    })
    .map(code => (
      <Option key={code} value={code}>
        {code} - {currencies[code]?.name}
      </Option>
    ));

  useEffect(() => {
    if (selectedEditProduct !== null) {
      editPriductform.setFieldsValue(selectedEditProduct);
      setIsEditModalOpen(true);
    }
  }, [editPriductform, selectedEditProduct]);

  return (
    <Row>
      <Col span={4}>
        <div className='flowmenudiv'>
          <List
            size="large"
            header={<h2 style={{ margin: 0 }}>Automation</h2>}
            dataSource={menudata}
            className='flowmenulist'
            renderItem={(item) => <List.Item className={`flowmenulistitem ${activeMenu === item.id && 'menuactive'}`} onClick={() => { setActiveMenu(item.id); navigate(`?${item.id}`) }}>{item.icon} {item.name}</List.Item>}
          />
        </div>
      </Col>
      <Col span={20}>
        {activeMenu === 'inbox' ? (
          <div className='itemPreviewDiv'>
            <h2><InboxOutlined /> Inbox Settings</h2>
            <p>Manage Auto replies to user messages and improve your customer experience. Read here to know more about this section in detail.</p>
            <br />
            <Card title="Setup your working hours" style={{ marginTop: '20px', display: 'none' }}>
              <Form layout="vertical">
                <Form.Item label="Working Days">
                  <Select
                    mode="multiple"
                    style={{ width: '100%' }}
                    placeholder="Select working days"
                    onChange={handleSelectChange}
                  >
                    <Option value="Mon">Mon</Option>
                    <Option value="Tue">Tue</Option>
                    <Option value="Wed">Wed</Option>
                    <Option value="Thu">Thu</Option>
                    <Option value="Fri">Fri</Option>
                    <Option value="Sat">Sat</Option>
                    <Option value="Sun">Sun</Option>
                  </Select>
                </Form.Item>
                <Form.Item label="Working Hours">
                  <TimePicker.RangePicker onChange={handleTimeChange} />
                </Form.Item>
              </Form>
            </Card>
            <Card title="Out of Office Message" extra={<Switch checked={autoData[0]?.is_out_office === '1' ? true : false} onChange={() => handleOnChange('is_out_office', autoData[0]?.is_out_office === '1' ? '2' : '1')} loading={autoLoader} />} style={{ marginTop: '20px' }}>
              <Text>Set up your working hours and Out Of Office Message. Please note that the Auto Reply gets triggered for new users and users whose conversation is marked closed.</Text>
              {autoData[0]?.is_out_office === '1' ? (
                <>
                  <TextArea
                    showCount
                    maxLength={1024}
                    value={autoData[0]?.out_office_message}
                    onChange={(e) => setAutoData(prevData => prevData.map(item => ({ ...item, out_office_message: e.target.value })))}
                    placeholder="Enter Message for out of office"
                    style={{ height: 120, marginTop: 15 }}
                  />
                  {messageLoader ? (
                    <Button type="primary" style={{ marginTop: 15, float: 'right' }}>
                      <Spin style={{ color: "#fff" }} indicator={<LoadingOutlined spin />} />
                    </Button>
                  ) : (
                    <Button type="primary" onClick={() => handleUpdate('out_office_message')} style={{ marginTop: 15, float: 'right' }}>Update</Button>
                  )}
                </>
              ) : ('')}
            </Card>
            <Card title="Welcome Message" extra={<Switch checked={autoData[0]?.is_welcome === '1' ? true : false} onChange={() => handleOnChange('is_welcome', autoData[0]?.is_welcome === '1' ? '2' : '1')} loading={autoLoader} />} style={{ marginTop: '20px' }}>
              <Text>Configure Greeting message to be triggered when new customers reach out to your business for the first time or existing customers reach out after a period of 24 hours.</Text>
              {autoData[0]?.is_welcome === '1' ? (
                <>
                  <TextArea
                    showCount
                    maxLength={1024}
                    value={autoData[0]?.welcome_message}
                    onChange={(e) => setAutoData(prevData => prevData.map(item => ({ ...item, welcome_message: e.target.value })))}
                    placeholder="Enter Welcomem Message"
                    style={{ height: 120, marginTop: 15 }}
                  />
                  {messageLoaderOne ? (
                    <Button type="primary" style={{ marginTop: 15, float: 'right' }}>
                      <Spin style={{ color: "#fff" }} indicator={<LoadingOutlined spin />} />
                    </Button>
                  ) : (
                    <Button type="primary" onClick={() => handleUpdate('welcome_message')} style={{ marginTop: 15, float: 'right' }}>Update</Button>
                  )}
                </>
              ) : ('')}
            </Card>

            <Card title="Delayed Response Message" extra={<Switch defaultChecked={false} />} style={{ marginTop: '20px', display: 'none' }}>
              <Text>Configure Auto Replies when you are delayed in responding to customer messages. Setup your delay time and the message to be triggered.</Text>
            </Card>
          </div>
        ) : activeMenu === 'flow-builder' ? (
          <div className='itemPreviewDiv'>
            <h2><ApartmentOutlined /> Chat bot builder</h2>
            <br />
            <Content style={{ margin: '24px 16px 0', overflow: 'initial' }}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Input.Search
                  placeholder="Search by flow name"
                  style={{ marginBottom: '20px', maxWidth: '300px' }}
                />
                <Link to={{ pathname: "/flow" }}>
                  <Button type="primary" icon={<PlusOutlined />} style={{ marginBottom: '20px' }}>
                    Create Flow
                  </Button>
                </Link>
              </div>
              {loading ? (
                <Spin size="large" />
              ) : (
                <Table dataSource={automationData} columns={columns} pagination={false} />
              )}
            </Content>
          </div>
        ) : activeMenu === 'whatsapp-flows' ? (
          <div className='itemPreviewDiv'>
            <h2><ApartmentOutlined /> WhatsApp Flows</h2>
            <br />
            <Content style={{ margin: '24px 16px 0', overflow: 'initial' }}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Input.Search
                  placeholder="Search by flow name"
                  style={{ marginBottom: '20px', maxWidth: '300px' }}
                />
                <Link to={{ pathname: "/whatsapp-flow" }}>
                  <Button type="primary" icon={<PlusOutlined />} style={{ marginBottom: '20px' }}>
                    Create Flow
                  </Button>
                </Link>
              </div>
              {loading ? (
                <Spin size="large" />
              ) : (
                <Table dataSource={whatsappFlowData} columns={whatsappColumns} pagination={false} />
              )}
            </Content>
          </div>
        ) : activeMenu === 'products' && (
          <div className='itemPreviewDiv'>
            <h2><ShoppingCartOutlined /> Products</h2>
            <br />
            <Content style={{ margin: '24px 16px 0', overflow: 'initial' }}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Input.Search
                  placeholder="Search by flow name"
                  style={{ marginBottom: '20px', maxWidth: '300px' }}
                  onSearch={handleSearch}
                  allowClear
                />
                <Button type="primary" icon={<SyncOutlined />} onClick={() => fetchData()} style={{ marginBottom: '20px' }}>
                  Sync
                </Button>
              </div>
              <div style={{ padding: '20px' }}>
                {productLoading ? (
                  <div>
                    <Spin />
                  </div>
                ) : (
                  <>
                    <div style={{ marginTop: '20px', textAlign: 'center' }}>
                      <Table
                        dataSource={products.filter(product => product.name.toLowerCase().includes(searchQuery.toLowerCase()))}
                        columns={productColumns}
                        rowKey="id"
                        pagination={false}
                      />
                    </div>
                    <div style={{ marginTop: '20px', textAlign: 'center' }}>
                      <Button
                        type="primary"
                        onClick={handlePreviousPage}
                        disabled={!paging?.previous || loading}
                        style={{ marginRight: '8px' }}
                      >
                        Previous
                      </Button>
                      <Button
                        type="primary"
                        onClick={handleNextPage}
                        disabled={!paging?.next || loading}
                      >
                        Next
                      </Button>
                    </div>

                  </>
                )}
              </div>
            </Content>
          </div>
        )}
      </Col>
      <Modal title={selectedProduct?.name} open={isModalOpen} onCancel={() => setIsModalOpen(false)} onOk={() => setIsModalOpen(false)}>
        <img src={selectedProduct?.image_url} className='productImage' alt={selectedProduct?.name} style={{ width: '100%' }} />
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <p><b>{selectedProduct?.name}</b></p>
          <p><b>{selectedProduct?.price}</b></p>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <p><b>Availability</b> {selectedProduct?.availability} ({selectedProduct?.inventory})</p>
          <p><b>Brand</b> {selectedProduct?.brand}</p>
        </div>
        <div style={{ whiteSpace: 'break-spaces' }} dangerouslySetInnerHTML={{ __html: selectedProduct?.description?.replace(/\n/g, '<br>') }} />
        <a href={selectedProduct?.url}>{selectedProduct?.url}</a>
      </Modal>
      <Drawer
        title="Edit Product"
        width={720}
        onClose={() => setIsEditModalOpen(false)}
        open={isEditModalOpen}
        styles={{
          body: {
            paddingBottom: 80,
          },
        }}
        footer={
          <div style={{ textAlign: 'right' }}>
            <Space>
              <Button onClick={() => setIsEditModalOpen(false)}>Cancel</Button>
              <Button onClick={() => setIsEditModalOpen(false)} type="primary">
                Update
              </Button>
            </Space>
          </div>
        }
      >
        {selectedProduct && (
          <Form layout="vertical" form={editPriductform}>
            <Row gutter={16}>
              <Col span={12}>

              </Col>
              <Col span={12}>
                <Form.Item
                  name="name"
                  label="Product Name"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter product name',
                    },
                  ]}
                >
                  <Input placeholder="Please enter product name" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  name="description"
                  label="Description"
                  rules={[
                    {
                      required: true,
                      message: 'please enter url description',
                    },
                  ]}
                >
                  <Input.TextArea rows={4} placeholder="please enter url description" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={4}>
                <Form.Item
                  name="currency"
                  label="Currency"
                  rules={[
                    {
                      required: true,
                      message: 'Please select a currency',
                    },
                  ]}
                >
                  <Select placeholder="Select currency" showSearch onSearch={onSearch} filterOption={false}>
                    {filteredCurrencyOptions}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="price"
                  label="Price"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter the price',
                    }
                  ]}
                >
                  <Input type="text" placeholder="Enter price" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="url"
                  label="Website link"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter product website link',
                    },
                  ]}
                >
                  <Input
                    style={{
                      width: '100%',
                    }}
                    placeholder="Please enter product url"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="type"
                  label="Type"
                  rules={[
                    {
                      required: true,
                      message: 'Please choose the type',
                    },
                  ]}
                >
                  <Checkbox checked={selectedProduct?.sale_price ? true : false} onChange={handleCheckboxChange} />
                  <Input
                    style={{
                      width: '100%',
                    }}
                    placeholder="Please enter Sales Price"
                    disabled={selectedProduct?.sale_price ? false : true}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="category"
                  label="Facebook product category "
                  rules={[
                    {
                      required: false,
                      message: 'Please choose the approver',
                    },
                  ]}
                >
                  <Input
                    style={{
                      width: '100%',
                    }}
                    placeholder="Please select Facebook product Category"
                  />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  name="condition"
                  label="Condition"
                  rules={[
                    {
                      required: true,
                      message: 'Please Select Condition',
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder="Select product Condition"
                    optionFilterProp="label" >
                    <Option value='new'>New</Option>
                    <Option value='refurbished'>Refurbished</Option>
                    <Option value='used_like_new'>Used (like new)</Option>
                    <Option value='used_good'>Used (like good)</Option>
                    <Option value='used_fair'>Used (like fair)</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        )}
      </Drawer>
    </Row>
  );
};

export default Automation;