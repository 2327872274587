import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Modal, Tabs, Table, Skeleton, Button, Flex, Input, message, Tooltip, Popconfirm, Col, Row } from 'antd';
import { PhoneOutlined, LinkOutlined, EyeOutlined, DeleteOutlined, CopyOutlined, EditOutlined, FileImageOutlined } from '@ant-design/icons';
const { Search } = Input;

const onChangeTabs = (key) => {
    console.log(key);
};

const copyToClipboard = (content) => {
    navigator.clipboard.writeText(content);
    message.success('Copied to clipboard');
};

const formatText = (text) => {
    // Replace \n with \n (React Native handles new lines automatically)
    let formattedText = text.replace(/\n/g, '<br/>');
    // Convert *bold* to <b>bold</b>
    formattedText = formattedText.replace(/\*(.*?)\*/g, '<b>$1</b>');
    // Convert _italic_ to <i>italic</i>
    formattedText = formattedText.replace(/_(.*?)_/g, '<i>$1</i>');
    return formattedText;
};

const WhatsAppFlows = (userData) => {
    const navigate = useNavigate();
    const [templateData, setTemplateData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [languageData, setLanguageData] = useState([]);
    const [previewVisible, setPreviewVisible] = useState(false);
    const [selectedTemplate, setSelectedTemplate] = useState([]);
    const [templatesLibrary, setTemplatesLibrary] = useState([]);

    useEffect(() => {
        const fetchLanguageData = async () => {
            setIsLoading(true);
            try {
                const response = await fetch('https://connectby.io:3001/languages');
                if (!response.ok) {
                    throw new Error('Failed to fetch language data');
                }
                const data = await response.json();
                setLanguageData(data);
            } catch (error) {
                console.error('Error fetching language data:', error);
            } finally {
                setIsLoading(false);
            }
        };
        fetchLanguageData();


        const fetchTemplateData = async () => {
            setIsLoading(true);
            try {
                const requestOptions = {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'whatsappaccesstoken': userData?.userData[0]?.whatsappAccessToken,
                        'wabaid': userData?.userData[0]?.wabaid
                    }
                };
                const response = await fetch('https://connectby.io:3001/api/get-flows', requestOptions);

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const body = await response.json();

                if (body.success === true) {
                    setTemplateData(body.responseData.data);
                    setFilteredData(body.responseData.data);
                } else {
                    console.error('Error: Success is not true');
                }
            } catch (error) {
                console.error('Error fetching template data:', error);
            } finally {
                setIsLoading(false);
            }
        };

        const fetchTemplateDatas = async () => {
            setIsLoading(true);
            try {
                const requestOptions = {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'whatsappaccesstoken': userData?.userData[0]?.whatsappAccessToken,
                        'wabaid': userData?.userData[0]?.wabaid
                    }
                };
                const response = await fetch('https://connectby.io:3001/api/gettemplates', requestOptions);

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const responseData = await response.json();
                const { data, totalItems } = responseData;
                setTemplatesLibrary(data);
            } catch (error) {
                console.error('Error fetching template data:', error);
            } finally {
                setIsLoading(false);
            }
        };

        if (userData || userData.userData || userData.userData.length > 0 || userData.userData[0].whatsappAccessToken) {
            fetchTemplateData();
            fetchTemplateDatas();
        } else {
            console.error('Missing user data or whatsappAccessToken');
        }
    }, [userData]);




    const handlePreview = (template) => {
        if (template && Array.isArray(template.components)) {
            setSelectedTemplate(template.components);
        }
        setPreviewVisible(true);
    };

    const handleClosePreview = () => {
        setSelectedTemplate([]);
        setPreviewVisible(false);
    };

    const renderLanguageName = (languageCode) => {
        const language = languageData.find(lang => lang.code === languageCode);
        return language ? language.name : languageCode;
    };

    const onChange = (pagination, filters, sorter, extra) => {
        console.log('params', pagination, filters, sorter, extra);
    };

    const nameFilters = templateData.map(template => ({
        text: template.name.charAt(0).toUpperCase() + template.name.slice(1).replace(/_/g, ' '),
        value: template.name
    }));

    const categoryFilters = templateData.map(template => ({
        text: template.category,
        value: template.category
    }));

    const languageFilters = templateData.map(template => ({
        text: template.language,
        value: template.language
    }));

    const handleDelete = (id, name) => {
        setIsDeleting(true);
        const url = `https://graph.facebook.com/v19.0/${userData?.userData[0]?.wabaid}/message_templates?hsm_id=${id}&name=${name}`;
        const accessToken = userData?.userData[0]?.whatsappAccessToken;
        fetch(url, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${accessToken}`
            }
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Failed to delete message template');
                }
                const updatedTemplateData = templateData.filter(template => template.id !== id);
                const updatedFilteredData = filteredData.filter(template => template.id !== id);
                setTemplateData(updatedTemplateData);
                setFilteredData(updatedFilteredData);
                setIsDeleting(false);
                message.success('Template deleted successfully');
            })
            .catch(error => {
                console.error('Error deleting message template:', error);
                setIsDeleting(false);
            });
    };

    const cancel = (e) => {
        console.log(e);
    };

    const handleSearch = (value) => {
        const filtered = templateData.filter(template => {
            const nameMatch = template.name.toLowerCase().includes(value.toLowerCase());
            const categoryMatch = template.category.toLowerCase().includes(value.toLowerCase());
            const statusMatch = template.status.toString().toLowerCase().includes(value.toLowerCase());
            const languageMatch = template.language.toLowerCase().includes(value.toLowerCase());
            return nameMatch || categoryMatch || statusMatch || languageMatch;
        });
        setFilteredData(filtered);
    };

    const columns = [
        {
            title: 'Template Name',
            dataIndex: 'name',
            filters: nameFilters,
            onFilter: (value, record) => record.name === value,
            filterSearch: true,
            width: '30%',
            render: (_, record) => (
                <span>
                    <span>{record.name.charAt(0).toUpperCase() + record.name.slice(1).replace(/_/g, ' ')}</span><br /><span className='copytemplateName' onClick={() => copyToClipboard(record.name)}><small>{record.name} <CopyOutlined /> </small></span>
                </span>
            )
        },
        {
            title: 'Status',
            dataIndex: 'status',
            sorter: (a, b) => a.status - b.status,
            width: '20%',
        },
        {
            title: 'Category',
            dataIndex: 'category',
            filters: categoryFilters,
            onFilter: (value, record) => record.category === value,
            filterSearch: true,
            width: '20%',
        },
        {
            title: 'Language(s)',
            dataIndex: 'language',
            filters: languageFilters,
            onFilter: (value, record) => record.language === value,
            filterSearch: true,
            width: '20%',
            render: (text) => `${renderLanguageName(text)} (${text})` || text,
        },
        {
            title: 'Action',
            dataIndex: 'id',
            width: '10%',
            render: (_, record) => (
                <Flex gap="small" align="flex-end" vertical>
                    <Flex gap="small">
                        <Tooltip title="Preview Template">
                            <Button type="primary" className='copyButton' shape="circle" icon={<EyeOutlined />} onClick={() => handlePreview(record)} />
                        </Tooltip>
                        {/* <Tooltip title="Duplicate Template">
                            <Button type="primary" className='copyButton' shape="circle" icon={<CopyOutlined />} />
                        </Tooltip> */}
                        {/* <Tooltip title="Edit Template">
                            <Link to={{ pathname: "/modify_template",state: { templateData: record } }}>
                                <Tooltip title="Edit Template">
                                    <Button type="primary" className='editButton' shape="circle" icon={<EditOutlined />} />
                                </Tooltip>
                            </Link>
                        </Tooltip> */}
                        <Popconfirm
                            title="Delete the task"
                            description="Are you sure to delete this task?"
                            onConfirm={handleDelete}
                            onCancel={cancel}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Tooltip title="Delete Template">
                                <Button type="primary" className={`deleteButton ${isDeleting ? 'deleting' : ''}`} danger shape="circle" icon={<DeleteOutlined />} onClick={() => handleDelete(record.id, record.name)} />
                            </Tooltip>
                        </Popconfirm>
                    </Flex>
                </Flex>
            )
        },
    ];

    const handleClickTemplate = (record) => {
        navigate('/create_template', { state: { record } });
    };

    const items = [
        {
            key: '1',
            label: 'Template Library',
            children: (
                <Row>
                    {templatesLibrary.map((item, index) => (
                        <Col span={8} key={index} style={{ padding: '10px' }}>
                            <figure class="watemplates-list_top">
                                    <img src="https://connectby.io/assets/front/images/Connectby-mobile-scree.png" class="wa_mockup is-list_item" style={{width: '110%'}}/>
                            <div className="message-boxOne preview-messageOne template-list" onClick={() => handleClickTemplate(item)}>
                                <p>
                                    {JSON.parse(item?.template_data).components && JSON.parse(item?.template_data).components.length > 0 ? (
                                        JSON.parse(item?.template_data).components.map((component) => (
                                            <React.Fragment key={component.type}>
                                                {component.type === "HEADER" && (
                                                    component.format === 'TEXT' ? (
                                                        <p className='headeretxt'>{component.text}</p>
                                                    ) : component.format === 'IMAGE' ? (
                                                        <div className='previewContentOne'><img alt="Header" src={`https://api.connectby.io/template_media/${component.example.header_handle[0]}`} style={{ objectFit: 'cover', borderRadius: '.25rem', width: '100%', marginBottom: '.5rem', }} /></div>
                                                    ) : component.format === 'VIDEO' ? (
                                                        <div className='previewContentOne'><video src={`https://api.connectby.io/template_media/${component.example.header_handle[0]}`} controls style={{ width: '100%' }} /></div>
                                                    ) : component.format === 'DOCUMENT' ? (
                                                        <div className='previewContentOne'><embed src={`https://api.connectby.io/template_media/${component.example.header_handle[0]}`} type="application/pdf" style={{ width: '100%', height: '500px' }} /></div>
                                                    ) : (
                                                        <p>Unsupported format</p>
                                                    )
                                                )}
                                                {component.type === "BODY" && (
                                                    <div className='prvbodytext' dangerouslySetInnerHTML={{ __html: formatText(component.text) }}></div>
                                                )}
                                                {component.type === "FOOTER" && (
                                                    <div className='footertext'>{component.text}</div>
                                                )}{component.type === "BUTTONS" && (
                                                    <React.Fragment>
                                                        <hr className='buttonhr' />
                                                        {component.buttons.map((button) => (
                                                            <div key={button.id}>
                                                                {
                                                                    button.type === 'QUICK_REPLY' ? (
                                                                        <>
                                                                            <div className='quickreply'>
                                                                                <a target="_blank" href="javascript:void(0)" rel="noreferrer">
                                                                                    {button.text}
                                                                                </a>
                                                                            </div>
                                                                        </>
                                                                    ) : (
                                                                        ''
                                                                    )}
                                                                {
                                                                    button.type === 'URL' ? (
                                                                        <>
                                                                            <div className='urlbtn'>
                                                                                <a target="_blank" href={button.url} rel="noreferrer">
                                                                                    <LinkOutlined />
                                                                                    {button.text}</a>
                                                                            </div>
                                                                        </>
                                                                    ) : (
                                                                        ''
                                                                    )}
                                                                {
                                                                    button.type === 'PHONE_NUMBER' ? (
                                                                        <>
                                                                            <div className='phonebtn'>
                                                                                <a target="_blank" href={`tel:${button.phone_number}`} rel="noreferrer">
                                                                                    <PhoneOutlined />
                                                                                    {button.text}</a>
                                                                            </div>
                                                                        </>
                                                                    ) : (
                                                                        ''
                                                                    )}
                                                            </div>
                                                        ))}
                                                    </React.Fragment>
                                                )}

                                            </React.Fragment>
                                        ))
                                    ) : (
                                        <p>Please Select the Template.</p>
                                    )}
                                </p>
                                <div className='copyBtn'>
                                    <p style={{background: '#fff',color: '#000',padding: '10px',borderRadius: '25px'}}>Use Template</p>
                                </div>
                            </div>
                            </figure>
                        </Col>
                    ))}
                </Row>
            ),
        },
        {
            key: '2',
            label: 'Active',
            children: (
                <Row>
                    <Col span={6}>
                        <Search placeholder="Search templates" onChange={(e) => handleSearch(e.target.value)} style={{ marginBottom: '16px' }} />
                    </Col>
                    <Col span={18}>
                        <Flex gap="small" align="flex-end" vertical>
                            <Link to={{ pathname: "/create_template", state: { templateData } }}>
                                <Button type="primary"> <FileImageOutlined /> Create New</Button>
                            </Link>
                        </Flex>
                    </Col>
                    <Col span={24}>
                        {isLoading ? (
                            <Skeleton active />
                        ) : (
                            <Table key="activeTable" columns={columns} dataSource={filteredData.map((item, index) => ({ ...item, key: index }))} onChange={onChange} />
                        )}
                    </Col>
                </Row>
            ),
        },
        {
            key: '3',
            label: 'Deleted',
            children: 'Content of Tab Pane 3',
        },
    ];

    return (
        <>
            <Tabs defaultActiveKey="2" items={items} onChange={onChangeTabs} />
            <Modal
                title="Template Preview"
                visible={previewVisible}
                onCancel={handleClosePreview}
                footer={null}
            >
                <div className="message-box preview-message">
                    <p>
                        {selectedTemplate && selectedTemplate.length > 0 ? (
                            selectedTemplate.map((component) => (

                                <React.Fragment key={component.type}>
                                    {component.type === "HEADER" && (
                                        component.format === 'TEXT' ? (
                                            <p className='headeretxt'>{component.text}</p>
                                        ) : component.format === 'IMAGE' ? (
                                            <div className='previewContent'><img alt="Header" src={component.example.header_handle[0]} style={{ height: '200px', width: 'auto' }} /></div>
                                        ) : component.format === 'VIDEO' ? (
                                            <div className='previewContent'><video src={component.example.header_handle[0]} controls style={{ width: '100%' }} /></div>
                                        ) : component.format === 'DOCUMENT' ? (
                                            <div className='previewContent'><embed src={component.example.header_handle[0]} type="application/pdf" style={{ width: '100%', height: '500px' }} /></div>
                                        ) : (
                                            <p>Unsupported format</p>
                                        )
                                    )}
                                    {component.type === "BODY" && (
                                        <div className='bodytext'>{formatText(component.text)}</div>
                                    )}
                                    {component.type === "FOOTER" && (
                                        <div className='footertext'>{component.text}</div>
                                    )}{component.type === "BUTTONS" && (
                                        <React.Fragment>
                                            <hr className='buttonhr' />
                                            {component.buttons.map((button) => (
                                                <div key={button.id}>
                                                    {
                                                        button.type === 'QUICK_REPLY' ? (
                                                            <>
                                                                <div className='quickreply'>
                                                                    <a target="_blank" href="javascript:void(0)" rel="noreferrer">
                                                                        {button.text}
                                                                    </a>
                                                                </div>
                                                            </>
                                                        ) : (
                                                            ''
                                                        )}
                                                    {
                                                        button.type === 'URL' ? (
                                                            <>
                                                                <div className='urlbtn'>
                                                                    <a target="_blank" href={button.url} rel="noreferrer">
                                                                        <LinkOutlined />
                                                                        {button.text}</a>
                                                                </div>
                                                            </>
                                                        ) : (
                                                            ''
                                                        )}
                                                    {
                                                        button.type === 'PHONE_NUMBER' ? (
                                                            <>
                                                                <div className='phonebtn'>
                                                                    <a target="_blank" href={`tel:${button.phone_number}`} rel="noreferrer">
                                                                        <PhoneOutlined />
                                                                        {button.text}</a>
                                                                </div>
                                                            </>
                                                        ) : (
                                                            ''
                                                        )}
                                                </div>
                                            ))}
                                        </React.Fragment>
                                    )}

                                </React.Fragment>
                            ))
                        ) : (
                            <p>Please Select the Template.</p>
                        )}
                    </p>
                </div>
            </Modal>
        </>
    );
}

export default WhatsAppFlows;