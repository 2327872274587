import React, { useCallback, useEffect, useState } from "react";
import {
  Row,
  Col,
  Typography,
  Table,
  Spin,
  Checkbox,
  Dropdown,
  Button,
  Flex,
} from "antd";
import { DownOutlined } from "@ant-design/icons";
import { useParams } from "react-router-dom";

const { Title } = Typography;

const FlowResponseView = () => {
  const { flowId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [flowData, setFlowData] = useState(null);
  const [error, setError] = useState(null);
  const [visibleColumns, setVisibleColumns] = useState([]);
  const [allColumns, setAllColumns] = useState([]);

  const fetchSelectedFlow = useCallback(async (flowId) => {
    try {
      setIsLoading(true);
      setError(null);
      const response = await fetch(
        `https://connectby.io:3001/api/get-flow-response?flowId=${flowId}`
      );
      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }
      const data = await response.json();
      setFlowData(data?.flowData);
    } catch (error) {
      setError("No data");
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (flowId) {
      fetchSelectedFlow(flowId);
    }
  }, [flowId, fetchSelectedFlow]);

  const processFlowData = (data) => {
    return data.map((response) => {
      const responsess = JSON.parse(response.flow_response);
      return Object.keys(responsess)
        .filter(
          (key) => key !== "flow_token" && typeof responsess[key] !== "boolean"
        )
        .reduce((acc, key) => {
          acc[key] =
            typeof responsess[key] === "string" && responsess[key].includes("_")
              ? responsess[key].split("_").slice(1).join("_").replace(/_/g, " ")
              : responsess[key];
          return acc;
        }, {});
    });
  };

  const generateColumns = (data) => {
    const firstResponse = data[0];
    return Object.keys(firstResponse).map((key) => ({
      title: key.slice(9, -2).replace(/_/g, " "),
      dataIndex: key,
      key,
    }));
  };

  useEffect(() => {
    if (flowData && flowData.length > 0) {
      const processedData = processFlowData(flowData);
      const columns = generateColumns(processedData);
      setAllColumns(columns);
      setVisibleColumns(columns.map((col) => col.key));
    }
  }, [flowData]);

  const renderDropdownMenu = () => {
    const menuItems = allColumns.map((col) => ({
      label: (
        <Checkbox
          checked={visibleColumns.includes(col.key)}
          onChange={(e) => {
            const isChecked = e.target.checked;
            if (isChecked) {
              setVisibleColumns((prev) => [...prev, col.key]);
            } else {
              setVisibleColumns((prev) =>
                prev.filter((key) => key !== col.key)
              );
            }
          }}
        >
          {col.title}
        </Checkbox>
      ),
      key: col.key,
    }));

    return menuItems;
  };

  const renderDropdown = () => (
    <Dropdown
      menu={{
        items: renderDropdownMenu(),
      }}
      trigger={["click"]}
    >
      <Button>
        Select Preview Columns <DownOutlined />
      </Button>
    </Dropdown>
  );

  if (isLoading) {
    return (
      <div
        style={{
          position: "absolute",
          width: "100vw",
          height: "100vh",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
        }}
      >
        <Flex align="center" justify="center" style={{ minHeight: "100vh" }}>
          <Spin size="large" />
        </Flex>
      </div>
    );
  }

  if (error) {
    return <Row>
    <Col span={24}>
      <Typography>
        <Title level={3}>Flow Response</Title>
      </Typography>
      <Table
        dataSource={[]} // Empty data source
        columns={allColumns} // Use all columns for the empty table
        pagination={false}
        bordered
      />
    </Col>
  </Row>;
  }

  if (!flowData || flowData.length === 0) {
    return <Row>
    <Col span={24}>
      <Typography>
        <Title level={3}>Flow Response</Title>
      </Typography>
      <Table
        dataSource={[]} // Empty data source
        columns={allColumns} // Use all columns for the empty table
        pagination={false}
        bordered
      />
    </Col>
  </Row>;
  }

  const processedData = processFlowData(flowData);

  const filteredColumns = allColumns.filter((col) =>
    visibleColumns.includes(col.key)
  );

  return (
    <Row>
      <Col span={24}>
      <Flex justify='space-between' align="center">
        <Typography>
          <Title level={3}>Flow Response</Title>
        </Typography>
        <div style={{ marginBottom: "16px" }}>{renderDropdown()}</div>
        </Flex>
        <Table
          dataSource={processedData}
          columns={filteredColumns}
          pagination={false}
          bordered
          rowKey={(record, index) => index}
        />
      </Col>
    </Row>
  );
};

export default FlowResponseView;
