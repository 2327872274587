import React, { useState, useCallback, useEffect } from "react";
import {
  Button,
  Divider,
  Flex,
  Input,
  Layout,
  Typography,
  Form,
  Select,
  Radio,
  Row,
  Col,
  Space,
  Image,
  message,
  Spin,
  List,
  Card,
  Switch,
  Dropdown,
  TimePicker,
} from "antd";
import storeSvg from "../assets/store.svg";
import dayjs from "dayjs";
import {
  ArrowBackIos,
  ArrowForwardIos,
  CloseOutlined,
  DragIndicator,
  MoreVert,
} from "@mui/icons-material";
import {
  CaretRightOutlined,
  ArrowLeftOutlined,
  PlusOutlined,
  DeleteOutlined,
  DownOutlined,
  FontSizeOutlined,
  FileImageOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import { useDrag, useDrop } from "react-dnd";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
const { Title, Paragraph } = Typography;
const { RangePicker } = TimePicker;
const { Option } = Select;
const { TextArea } = Input;

const generateDataExamples = (children, screenIndex) => {
  return children.reduce((acc, field, fieldIndex) => {
    if (field.type === "Footer") {
      return acc;
    }
    const fieldKey = `screen_${screenIndex}_${field?.name
      ?.split("_")
      .slice(0, -1)
      .join("_")}_${fieldIndex}`;
    if (field.type === "OptIn") {
      acc[fieldKey] = { type: "boolean", __example__: false };
    } else {
      acc[fieldKey] = { type: "string", __example__: "Example" };
    }
    return acc;
  }, {});
};

const dropdownItems = [
  {
    key: "1",
    label: "Text",
    icon: <FontSizeOutlined />,
    children: [
      {
        key: "TextHeading",
        label: "Large heading",
        icon: <FontSizeOutlined />,
      },
      {
        key: "TextSubheading",
        label: "Small heading",
        icon: <FontSizeOutlined />,
      },
      {
        key: "TextBody",
        label: "Body",
        icon: <FontSizeOutlined />,
      },
      {
        key: "TextCaption",
        label: "Caption",
        icon: <FontSizeOutlined />,
      },
    ],
  },
  // {
  //   key: "2",
  //   label: "Media",
  //   icon: <FileImageOutlined />,
  //   children: [
  //     {
  //       key: "Image",
  //       label: "Image",
  //       icon: <FileImageOutlined />,
  //     },
  //   ],
  // },
  {
    key: "3",
    label: "Text Inputs",
    icon: <FontSizeOutlined />,
    children: [
      {
        key: "TextInput",
        label: "Text input",
        icon: <FontSizeOutlined />,
      },
      {
        key: "TextArea",
        label: "Textarea",
        icon: <FontSizeOutlined />,
      },
      {
        key: "DatePicker",
        label: "Date Picker",
        icon: <FontSizeOutlined />,
      },
      {
        key: "TimePicker",
        label: "Time Picker",
        icon: <FontSizeOutlined />,
      },
    ],
  },
  {
    key: "4",
    label: "Selection",
    icon: <UnorderedListOutlined />,
    children: [
      {
        key: "RadioButtonsGroup",
        label: "Single choice",
        icon: <UnorderedListOutlined />,
      },
      {
        key: "CheckboxGroup",
        label: "Multiple choice",
        icon: <UnorderedListOutlined />,
      },
      {
        key: "Dropdown",
        label: "Dropdown",
        icon: <UnorderedListOutlined />,
      },
      {
        key: "OptIn",
        label: "Opt in",
        icon: <UnorderedListOutlined />,
      },
    ],
  },
];

const options = [
  { key: "SIGN_UP", value: "Sign up" },
  { key: "SIGN_IN", value: "Sign in" },
  { key: "APPOINTMENT_BOOKING", value: "Appointment booking" },
  { key: "LEAD_GENERATION", value: "Lead generation" },
  { key: "SHOPPING", value: "Shopping" },
  { key: "CONTACT_US", value: "Contact us" },
  { key: "CUSTOMER_SUPPORT", value: "Customer support" },
  { key: "SURVEY", value: "Survey" },
  { key: "OTHER", value: "Other" },
];
const ItemType = "SCREEN";

const WhatsappFlow = (userData) => {
  const navigate = useNavigate();
  const { flowId } = useParams();
  const [form] = Form.useForm();
  const [templateType, setTemplateType] = useState("without");
  const [flowpreviewPopup, setFlowpreviewPopup] = useState(true);
  const [flowJsons, setFlowJsons] = useState([]);
  const [flowJsonName, setFlowJsonName] = useState("default");
  const [selectedFlowJson, setSelectedFlowJson] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [screens, setScreens] = useState([]);
  const [subPopup, setSubPopup] = useState(null);
  const [activeScreens, setActiveScreens] = useState("");
  const [timeRange, setTimeRange] = useState({});
  const [intervalType, setIntervalType] = useState({});
  const [intervalDuration, setIntervalDuration] = useState({});
  const [optflowIds, setOptflowIds] = useState([]);

  const fetchFlowJsons = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await fetch(
        "https://connectby.io:3001/api/get-flow-jsons"
      );
      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }
      const data = await response.json();
      setFlowJsons(data.userData);
    } catch (error) {
      message.error("Failed to fetch flow JSONs. Please try again.");
    } finally {
      setIsLoading(false);
    }
  }, []);

  const fetchSelectedFlow = useCallback(async (flowId) => {
    try {
      setIsLoading(true);
      const response = await fetch(
        `https://connectby.io:3001/api/get-whatsapp-flow?flowId=${flowId}`
      );
      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }
      const data = await response.json();
      const flowJson = data?.flowData?.flow_json;
      const screensFlow = JSON.parse(flowJson);
      let optIds = [];
      screensFlow?.screens?.forEach((flow) => {
        const optInChildren = flow?.layout?.children?.[0]?.children.filter(
          (child) => child.type === "OptIn"
        );
        if (optInChildren && optInChildren.length > 0) {
          const optflowIds = optInChildren.map(
            (child) => child?.["on-click-action"]?.next?.name
          );
          optIds = [...optIds, ...optflowIds];
        }
      });
      const updatedScreensObject = screensFlow.screens.map((screen, index) => {
        const newScreenId =
          index === 0 ? "QUESTION_ONE" : `QUESTION_ONE_${index}`;
        screensFlow.screens.forEach((flow) => {
          const footerChildren = flow?.layout?.children?.[0]?.children?.filter(
            (child) => child.type === "Footer"
          );
          if (footerChildren && footerChildren.length > 0) {
            footerChildren.forEach((child) => {
              if (child?.["on-click-action"]?.next?.name === screen.id) {
                child["on-click-action"].next.name = newScreenId;
              }
            });
          }
        });
        if (optIds.includes(screen.id)) {
          screensFlow.screens.forEach((flow) => {
            const optInChildren = flow?.layout?.children?.[0]?.children?.filter(
              (child) => child.type === "OptIn"
            );

            if (optInChildren && optInChildren.length > 0) {
              optInChildren.forEach((child) => {
                if (child?.["on-click-action"]?.next?.name === screen.id) {
                  child["on-click-action"].next.name = newScreenId;
                }
              });
              const optflowIds = optInChildren.map(
                (child) => child?.["on-click-action"]?.next?.name
              );
              setOptflowIds((prevOptflowIds) => [
                ...prevOptflowIds,
                ...optflowIds,
              ]);
            }
          });
        }
        return { ...screen, id: newScreenId };
      });
      const parsedFlowJson = { ...screensFlow, screens: updatedScreensObject };
      setActiveScreens(parsedFlowJson.screens[0].id);
      setScreens(parsedFlowJson.screens);
      setSelectedFlowJson(parsedFlowJson);
    } catch (error) {
      message.error("Failed to fetch flow JSONs. Please try again.");
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (flowId) {
      fetchSelectedFlow(flowId);
    }
    fetchFlowJsons();
  }, [flowId, fetchSelectedFlow, fetchFlowJsons]);

  const handlePublish = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(
        "https://connectby.io:3001/api/publish-flow",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            whatsappaccesstoken: userData?.userData[0]?.whatsappAccessToken,
          },
          body: JSON.stringify({
            flowId: flowId,
          }),
        }
      );
      if (!response.ok) {
        message.error("Something went wrong please try again");
        throw new Error(`Update Status failed with status ${response.status}`);
      }
      // const data = await response.json();
      message.success("Flow published successfully");
      navigate("whatsapp-flows");
    } catch (error) {
      message.error("Something went wrong please try again");
      console.error("Error updating status:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdate = async () => {
    setIsLoading(true);
    const selectedFlow = JSON.stringify(selectedFlowJson);

    try {
      const response = await fetch(
        "https://connectby.io:3001/api/update-flow",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            whatsappaccesstoken: userData?.userData[0]?.whatsappAccessToken,
          },
          body: JSON.stringify({
            flowId: flowId,
            flowtemplate: selectedFlow,
          }),
        }
      );
      if (!response.ok) {
        message.error("Something went wrong please try again");
        throw new Error(`Update Status failed with status ${response.status}`);
      }
      message.success("Flow updated successfully");
    } catch (error) {
      message.error("Something went wrong please try again");
      console.error("Error updating status:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleTimeRangeChange = async (times, id, indx) => {
    if (times && times.length === 2) {
      const [start, end] = times;
      const key = `time${id}${indx}`;
      setTimeRange((prev) => ({
        ...prev,
        [key]: { start, end },
      }));
      console.log("Time Range Selected:", { key, start, end });
    } else {
      const key = `time${id}${indx}`;
      setTimeRange((prev) => {
        const updated = { ...prev };
        delete updated[key];
        return updated;
      });
      console.log("Time Range Cleared for key:", key);
    }
  };

  const handleIntervalTypeChange = async (type, id, indx) => {
    const key = `type${id}${indx}`;
    setIntervalType((prev) => ({
      ...prev,
      [key]: type,
    }));
  };

  const handleIntervalDurationChange = async (duration, id, indx) => {
    const key = `duration${id}${indx}`;
    setIntervalDuration((prev) => ({
      ...prev,
      [key]: duration,
    }));
  };

  const handleCreateSloats = async (id, indx) => {
    const key = `time${id}${indx}`;
    const typeKey = `type${id}${indx}`;
    const durationKey = `duration${id}${indx}`;

    const times = timeRange[key];
    const type = intervalType[typeKey] || "minutes";
    const duration = intervalDuration[durationKey];
    const start = dayjs(times.start);
    const end = dayjs(times.end);
    let slots = [];
    if (type === "minutes") {
      let current = start;
      while (current.isBefore(end)) {
        slots.push(current.format("hh:mm A"));
        current = current.add(duration, "minutes");
      }
    } else if (type === "hour") {
      let current = start;
      while (current.isBefore(end)) {
        slots.push(current.format("hh:mm A"));
        current = current.add(duration, "hour");
      }
    }

    setSelectedFlowJson((prevState) => {
      const updatedScreens = prevState.screens.map((screen) => {
        if (screen.id === id) {
          const updatedLayout = {
            ...screen.layout,
            children: screen.layout.children.map((child) => {
              if (child.type === "Form") {
                return {
                  ...child,
                  children: child.children.map((chld, indxx) =>
                    indxx === indx && chld.type === "TimePicker"
                      ? {
                          ...chld,
                          type: "DropDown",
                          "data-source": slots.map((slot, idx) => ({
                            id: `${idx}_${slot.replace(/ /g, "_")}`,
                            title: slot,
                          })),
                        }
                      : chld
                  ),
                };
              }
              return child;
            }),
          };

          return { ...screen, layout: updatedLayout };
        }
        return screen;
      });
      return { ...prevState, screens: updatedScreens };
    });
  };

  const handleSubmit = async (values) => {
    setIsLoading(true);
    const selectedFlow = flowJsons.find(
      (flow) => flow.flow_name === flowJsonName
    ).json;
    setSelectedFlowJson(JSON.parse(selectedFlow));
    try {
      const response = await fetch(
        "https://connectby.io:3001/api/create-flow",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            whatsappaccesstoken: userData?.userData[0]?.whatsappAccessToken,
            wabaid: userData?.userData[0]?.wabaid,
          },
          body: JSON.stringify({
            flow_name: values.flowName,
            categories: values.categories,
            withendpoint: templateType === "without" ? "no" : "yes",
            flowtemplate: selectedFlow,
          }),
        }
      );
      if (!response.ok) {
        throw new Error(`Update Status failed with status ${response.status}`);
      }
      const data = await response.json();
      navigate(`/whatsapp-flow/${data?.responseData?.id}`);
    } catch (error) {
      message.error("Flow name already used, please enter unique Flow name.");
      console.error("Error updating status:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDiscard = () => {
    form.resetFields();
  };

  const handleOnFlowTypeChange = async (value) => {
    setFlowJsonName(value);
    const selectedFlow = flowJsons.find((flow) => flow.flow_name === value);
    if (!selectedFlow) return;
    const { screens } = JSON.parse(selectedFlow.json);
    setActiveScreens(screens[0].id);
    setScreens(screens);
  };

  const renderPreviewPopup = (flowJsons, selectedFlowJson, flowId) => {
    let selectedFlow;
    if (flowId) {
      selectedFlow = JSON.stringify(selectedFlowJson);
    } else {
      selectedFlow = flowJsons.find(
        (flow) => flow.flow_name === flowJsonName
      ).json;
    }
    if (!selectedFlow) return null;
    const { screens } = JSON.parse(selectedFlow);
    const screenToDisplay =
      activeScreens === ""
        ? screens?.[0]
        : screens.find((screen) => screen.id === activeScreens);
    if (!screenToDisplay) return null;
    const { id, title, layout } = screenToDisplay;
    return (
      <div key={id} className="flow-preview-popup">
        <div className="flow-preview-popup-main" data-scrollable="true">
          <div className="flow-preview-popup-start">
            <div className="flow-preview-popup-second">
              <header className="flow-preview-popup-header">
                <nav className="flow-preview-popup-nav">
                  <div className="flow-preview-popup-close-btn-div">
                    <button
                      onClick={() => setFlowpreviewPopup(false)}
                      aria-label="Cancel"
                      className="flow-preview-popup-close-btn"
                    >
                      <CloseOutlined style={{ width: 24 }} />
                    </button>
                  </div>
                  <div className="flow-preview-popup-title-div" dir="auto">
                    <h1 className="flow-preview-popup-title">
                      {title?.startsWith("${data.")
                        ? screenToDisplay.data[
                            title?.replace(/^\${data\.(.*?)}/g, "$1")
                          ]["__example__"]
                        : title}
                    </h1>
                  </div>
                  <div className="flow-preview-popup-more-btn-div">
                    <button
                      className="flow-preview-popup-more-btn"
                      aria-label="more options"
                    >
                      <MoreVert />
                    </button>
                  </div>
                </nav>
              </header>
              <div className="flow-preview-popup-body-main">
                <div className="flow-preview-popup-body-start">
                  <div className="flow-preview-popup-body-second">
                    <div className="flow-preview-popup-body-third">
                      <div className="flow-preview-popup-body">
                        <form name={`${id}_form`}>
                          <section className="flow-preview-popup-section">
                            {layout?.children?.map((child, index) => {
                              if (child.type === "Form") {
                                return child.children.map((chld, indx) => {
                                  switch (chld?.type) {
                                    case "TextHeading":
                                      return (
                                        <div
                                          key={indx}
                                          className="flow-preview-popup-body-text-header"
                                        >
                                          <h1 className="flow-preview-popup-body-header-text">
                                            {chld.text?.startsWith("${data.")
                                              ? screenToDisplay.data[
                                                  chld?.text?.replace(
                                                    /^\${data\.(.*?)}/g,
                                                    "$1"
                                                  )
                                                ]["__example__"]
                                              : chld?.text}
                                          </h1>
                                        </div>
                                      );
                                    case "Image":
                                      return (
                                        <div
                                          key={indx}
                                          className="flow-preview-popup-body-image-div-one"
                                        >
                                          <div
                                            className="flow-preview-popup-body-image-div"
                                            style={{
                                              height: chld?.height,
                                              width: "100%",
                                            }}
                                          >
                                            {chld?.src?.startsWith(
                                              "${data."
                                            ) ? (
                                              <img
                                                src={`data:image/jpg;base64,${
                                                  screenToDisplay.data[
                                                    chld?.src?.replace(
                                                      /^\${data\.(.*?)}/g,
                                                      "$1"
                                                    )
                                                  ]["__example__"]
                                                }`}
                                                style={{
                                                  height: chld?.height,
                                                  objectFit:
                                                    chld?.["scale-type"],
                                                  width: "100%",
                                                }}
                                                alt=""
                                              />
                                            ) : (
                                              <img
                                                src={`data:image/jpg;base64,${chld?.src}`}
                                                style={{
                                                  height: chld?.height,
                                                  objectFit:
                                                    chld?.["scale-type"],
                                                  width: "100%",
                                                }}
                                                alt=""
                                              />
                                            )}
                                          </div>
                                        </div>
                                      );
                                    case "TextSubheading":
                                      return (
                                        <div
                                          key={indx}
                                          className="flow-preview-popup-body-text-header"
                                        >
                                          <h2 className="flow-preview-popup-body-header-sub-text">
                                            {chld.text?.startsWith("${data.")
                                              ? screenToDisplay.data[
                                                  chld?.text?.replace(
                                                    /^\${data\.(.*?)}/g,
                                                    "$1"
                                                  )
                                                ]["__example__"]
                                              : chld?.text}
                                          </h2>
                                        </div>
                                      );
                                    case "TextBody":
                                      return (
                                        <div
                                          key={indx}
                                          className="flow-preview-popup-body-text-body"
                                        >
                                          <p className="flow-preview-popup-body-body-text">
                                            {chld.text?.startsWith("${data.")
                                              ? screenToDisplay.data[
                                                  chld?.text?.replace(
                                                    /^\${data\.(.*?)}/g,
                                                    "$1"
                                                  )
                                                ]["__example__"]
                                              : chld?.text}
                                          </p>
                                        </div>
                                      );
                                    case "TextInput":
                                      return (
                                        <div
                                          key={indx}
                                          className="flow-preview-popup-body-inputs"
                                        >
                                          <label className="custom-field one">
                                            <input
                                              type={chld?.["input-type"]}
                                              placeholder=" "
                                              required={chld?.required}
                                              name={chld?.name}
                                            />
                                            <span className="placeholder">{`${
                                              chld?.label
                                            } ${
                                              !chld?.required
                                                ? "(optional)"
                                                : ""
                                            }`}</span>
                                          </label>
                                          {chld?.["helper-text"] && (
                                            <span className="helper-text">
                                              {chld?.["helper-text"]}
                                            </span>
                                          )}
                                        </div>
                                      );
                                    case "TextArea":
                                      return (
                                        <div
                                          key={indx}
                                          className="flow-preview-popup-body-inputs"
                                        >
                                          <label className="custom-field one">
                                            <TextArea
                                              type={chld?.["input-type"]}
                                              placeholder={`${chld?.label} ${
                                                !chld?.required
                                                  ? "(optional)"
                                                  : ""
                                              }`}
                                              required={chld?.required}
                                              name={chld?.name}
                                              showCount
                                              maxLength={600}
                                              rows={4}
                                            />
                                            {chld?.["helper-text"] && (
                                              <span className="helper-text">
                                                {chld?.["helper-text"]}
                                              </span>
                                            )}
                                          </label>
                                        </div>
                                      );
                                    case "RadioButtonsGroup":
                                      return (
                                        <div
                                          key={indx}
                                          className="flow-preview-popup-body-checkbox-group"
                                        >
                                          <label className="checkbox-label">
                                            {chld.label?.startsWith("${data.")
                                              ? screenToDisplay.data[
                                                  chld?.label?.replace(
                                                    /^\${data\.(.*?)}/g,
                                                    "$1"
                                                  )
                                                ]["__example__"]
                                              : chld?.label}
                                          </label>
                                          {Array.isArray(chld?.["data-source"])
                                            ? chld?.["data-source"]?.map(
                                                (check) => (
                                                  <label className="checkbox-label">
                                                    <span>{check.title}</span>{" "}
                                                    <input
                                                      type="radio"
                                                      name={chld?.name}
                                                      required={chld?.required}
                                                    />
                                                  </label>
                                                )
                                              )
                                            : screenToDisplay.data[
                                                chld?.["data-source"]?.replace(
                                                  /^\${data\.(.*?)}/g,
                                                  "$1"
                                                )
                                              ]?.["__example__"].map(
                                                (radiolabel) => (
                                                  <label className="checkbox-label">
                                                    <span>
                                                      {radiolabel.title}
                                                    </span>{" "}
                                                    <input
                                                      type="radio"
                                                      name={chld?.name}
                                                      required={chld?.required}
                                                    />
                                                  </label>
                                                )
                                              )}
                                        </div>
                                      );
                                    case "TextCaption":
                                      return (
                                        <p className="flow-text-caption-p">
                                          {chld.text?.startsWith("${data.")
                                            ? screenToDisplay.data[
                                                chld?.text?.replace(
                                                  /^\${data\.(.*?)}/g,
                                                  "$1"
                                                )
                                              ]["__example__"]
                                            : chld?.text}
                                        </p>
                                      );
                                    case "Dropdown":
                                      return (
                                        <div
                                          key={indx}
                                          className="flow-preview-popup-body-dropdown"
                                        >
                                          <label
                                            className="flow-preview-popup-body-dropdown-label"
                                            onClick={() => setSubPopup(chld)}
                                          >
                                            <span>
                                              {chld?.label}{" "}
                                              {!chld?.required
                                                ? "(optional)"
                                                : ""}
                                            </span>{" "}
                                            <span>
                                              <CaretRightOutlined />
                                            </span>
                                          </label>
                                        </div>
                                      );
                                    case "EmbeddedLink":
                                      return <></>;
                                    case "DatePicker":
                                      return (
                                        <div
                                          key={indx}
                                          className="flow-preview-popup-body-inputs"
                                        >
                                          <label className="custom-field one">
                                            <input
                                              type={chld?.["input-type"]}
                                              placeholder=" "
                                              required={chld?.required}
                                              name={chld?.name}
                                            />
                                            <span className="placeholder">{`${
                                              chld?.label
                                            } ${
                                              !chld?.required
                                                ? "(optional)"
                                                : ""
                                            }`}</span>
                                          </label>
                                          {chld?.["helper-text"] && (
                                            <span className="helper-text">
                                              {chld?.["helper-text"]}
                                            </span>
                                          )}
                                        </div>
                                      );
                                    case "OptIn":
                                      return (
                                        <div
                                          key={indx}
                                          className="flow-preview-popup-body-optins"
                                        >
                                          <label className="optin-label">
                                            <input
                                              type="checkbox"
                                              name={chld?.name}
                                              required={chld?.required}
                                            />{" "}
                                            <span>
                                              {chld?.label}{" "}
                                              {chld?.["on-click-action"] && (
                                                <Button type="link" href="#">
                                                  Read more.
                                                </Button>
                                              )}
                                            </span>
                                          </label>
                                        </div>
                                      );
                                    case "CheckboxGroup":
                                      return (
                                        <div
                                          key={indx}
                                          className="flow-preview-popup-body-checkbox-group"
                                        >
                                          <label className="checkbox-label">
                                            {chld.label?.startsWith("${data.")
                                              ? screenToDisplay.data[
                                                  chld?.label?.replace(
                                                    /^\${data\.(.*?)}/g,
                                                    "$1"
                                                  )
                                                ]["__example__"]
                                              : chld?.label}
                                          </label>
                                          {Array.isArray(chld?.["data-source"])
                                            ? chld?.["data-source"]?.map(
                                                (check) => (
                                                  <label className="checkbox-label">
                                                    <span>{check.title}</span>{" "}
                                                    <input
                                                      type="checkbox"
                                                      name={check.id}
                                                      required={chld?.required}
                                                    />
                                                  </label>
                                                )
                                              )
                                            : screenToDisplay.data[
                                                chld?.["data-source"]?.replace(
                                                  /^\${data\.(.*?)}/g,
                                                  "$1"
                                                )
                                              ]?.["__example__"].map(
                                                (checklabel) => (
                                                  <label className="checkbox-label">
                                                    <span>
                                                      {checklabel.title}
                                                    </span>{" "}
                                                    <input
                                                      type="radio"
                                                      name={chld?.name}
                                                      required={chld?.required}
                                                    />
                                                  </label>
                                                )
                                              )}
                                        </div>
                                      );
                                    case "Footer":
                                      return (
                                        <div
                                          key={indx}
                                          className="flow-preview-popup-body-footer"
                                          root-component-type="Footer"
                                        ></div>
                                      );
                                    default:
                                      return null;
                                  }
                                });
                              } else {
                                switch (child.type) {
                                  case "TextHeading":
                                    return (
                                      <div
                                        key={index}
                                        className="flow-preview-popup-body-text-header"
                                      >
                                        <h1 className="flow-preview-popup-body-header-text">
                                          {child.text}
                                        </h1>
                                      </div>
                                    );
                                  case "TextSubheading":
                                    return (
                                      <div
                                        key={index}
                                        className="flow-preview-popup-body-text-header"
                                      >
                                        <h2 className="flow-preview-popup-body-header-sub-text">
                                          {child.text}
                                        </h2>
                                      </div>
                                    );
                                  case "TextBody":
                                    return (
                                      <div
                                        key={index}
                                        className="flow-preview-popup-body-text-body"
                                      >
                                        <p className="flow-preview-popup-body-body-text">
                                          {child.text}
                                        </p>
                                      </div>
                                    );
                                  case "Footer":
                                    return (
                                      <div
                                        key={index}
                                        className="flow-preview-popup-body-footer"
                                        root-component-type="Footer"
                                      ></div>
                                    );
                                  default:
                                    return null;
                                }
                              }
                            })}
                          </section>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {layout?.children?.map((child, index) => {
                if (child.type === "Form") {
                  return child.children.map((chld, indx) => {
                    if (chld?.type === "Footer") {
                      return (
                        <div
                          className="flow-preview-popup-footer-main"
                          key={indx}
                        >
                          <div className="flow-preview-popup-footer-start">
                            <div className="flow-preview-popup-footer">
                              <button className="flow-preview-popup-footer-btn">
                                {chld?.label}
                              </button>
                            </div>
                          </div>
                        </div>
                      );
                    }
                    return null;
                  });
                } else {
                  if (child.type === "Footer") {
                    return (
                      <div
                        className="flow-preview-popup-footer-main"
                        key={index}
                      >
                        <div className="flow-preview-popup-footer-start">
                          <div className="flow-preview-popup-footer">
                            <button className="flow-preview-popup-footer-btn">
                              {child.label}
                            </button>
                          </div>
                        </div>
                      </div>
                    );
                  }
                  return null;
                }
              })}
              <div className="flow-preview-popup-branding-container">
                <span className="flow-preview-popup-branding-text">
                  <label>Managed by the business.</label>
                  <span>
                    <Button
                      type="link"
                      href="#"
                      aria-label="Learn more. Double tap to select."
                    >
                      Learn more
                    </Button>
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const handleRemoveScreen = (id) => {
    if (selectedFlowJson.screens.length !== optflowIds.length + 1) {
      const removableScreen = selectedFlowJson.screens.filter(
        (screen) => screen.id === id
      );
      const optInChildren =
        removableScreen?.[0]?.layout?.children?.[0]?.children.filter(
          (child) => child.type === "OptIn"
        );
      let optIds = [];
      if (optInChildren && optInChildren.length > 0) {
        optIds = optInChildren.map(
          (child) => child?.["on-click-action"]?.next?.name
        );
      }
      const screens = selectedFlowJson.screens.filter(
        (screen) => screen.id !== id && !optIds.includes(screen.id)
      );
      setOptflowIds((prevOptflowIds) =>
        prevOptflowIds.filter((optId) => !optIds.includes(optId))
      );
      const updatedScreens = screens.map((screen, index) => {
        const newScreenId =
          index === 0 ? "QUESTION_ONE" : `QUESTION_ONE_${index}`;
        screens.forEach((flow) => {
          const footerChildren = flow?.layout?.children?.[0]?.children?.filter(
            (child) => child.type === "Footer"
          );
          if (footerChildren && footerChildren.length > 0) {
            footerChildren.forEach((child) => {
              if (
                index ===
                screens.filter((screen) => !optflowIds.includes(screen.id))
                  .length -
                  1
              ) {
                delete child["on-click-action"].next;
                child["on-click-action"].name = "complete";
              } else {
                if (child?.["on-click-action"]?.next?.name === screen.id) {
                  child["on-click-action"].next.name = newScreenId;
                }
              }
            });
          }
        });
        if (optflowIds.length > 0 && optflowIds.includes(screen.id)) {
          screens.forEach((flow) => {
            const optInChildren = flow?.layout?.children?.[0]?.children?.filter(
              (child) => child.type === "OptIn"
            );

            if (optInChildren && optInChildren.length > 0) {
              optInChildren.forEach((child) => {
                if (child?.["on-click-action"]?.next?.name === screen.id) {
                  child["on-click-action"].next.name = newScreenId;
                }
              });
              const optflowIds = optInChildren.map(
                (child) => child?.["on-click-action"]?.next?.name
              );
              setOptflowIds((prevOptflowIds) => {
                const filteredOptflowIds = prevOptflowIds.filter(
                  (optId) => optId !== screen.id
                );
                return [...filteredOptflowIds, ...optflowIds];
              });
            }
          });
          return { ...screen, id: newScreenId, data: {} };
        }

        if (index === 0) {
          const currentPayload = generateDataExamples(
            screen.layout?.children?.[0]?.children || [],
            index
          );
          const payload = {
            ...Object.keys(currentPayload)
              .map((key) => ({ [`${key}`]: `form.${key}` }))
              .reduce((acc, curr) => ({ ...acc, ...curr }), {}),
          };
          return {
            ...screen,
            terminal: true,
            id: newScreenId,
            data: {},
            layout: {
              ...screen.layout,
              children: screen.layout?.children?.map((child) => {
                if (child.type === "Form") {
                  return {
                    ...child,
                    children: child.children.map((field) => {
                      if (field.type === "Footer") {
                        return {
                          ...field,
                          "on-click-action": {
                            ...field["on-click-action"],
                            payload: payload,
                          },
                        };
                      }
                      return field;
                    }),
                  };
                }
                return child;
              }),
            },
          };
        }

        let accumulatedData = {};
        for (let i = 0; i < index; i++) {
          const previousScreenLayout =
            screens[i].layout?.children?.[0]?.children;
          const previousScreenData = generateDataExamples(
            previousScreenLayout || [],
            i
          );
          accumulatedData = { ...accumulatedData, ...previousScreenData };
        }
        const currentScreenLayout =
          screens[index - 1].layout?.children?.[0]?.children;
        const currentScreenData = generateDataExamples(
          currentScreenLayout || [],
          index - 1
        );
        const combinedData = { ...accumulatedData, ...currentScreenData };
        const currentPayload = generateDataExamples(
          screen.layout?.children?.[0]?.children || [],
          index
        );
        const payload = {
          ...Object.keys(currentPayload)
            .map((key) => ({ [`${key}`]: `form.${key}` }))
            .reduce((acc, curr) => ({ ...acc, ...curr }), {}),
          ...Object.keys(combinedData)
            .map((key) => ({ [`${key}`]: `data.${key}` }))
            .reduce((acc, curr) => ({ ...acc, ...curr }), {}),
        };
        return {
          ...screen,
          terminal: true,
          id: newScreenId,
          data: combinedData,
          layout: {
            ...screen.layout,
            children: screen.layout?.children?.map((child) => {
              if (child.type === "Form") {
                return {
                  ...child,
                  children: child.children.map((field) => {
                    if (field.type === "Footer") {
                      return {
                        ...field,
                        "on-click-action": {
                          ...field["on-click-action"],
                          payload: payload,
                        },
                      };
                    }
                    return field;
                  }),
                };
              }
              return child;
            }),
          },
        };
      });

      const screensWithoutOptflow = updatedScreens
        .filter((screen) => !optflowIds.includes(screen.id))
        .map(({ terminal, ...rest }) => rest);
      const screensWithOptflow = updatedScreens.filter((screen) =>
        optflowIds.includes(screen.id)
      );
      const lastScreenWithoutOptflow = {
        terminal: true,
        ...screensWithoutOptflow[screensWithoutOptflow.length - 1],
      };

      setSelectedFlowJson((prevState) => {
        return {
          ...prevState,
          screens: [
            ...screensWithoutOptflow.slice(0, screensWithoutOptflow.length - 1),
            lastScreenWithoutOptflow,
            ...screensWithOptflow,
          ],
        };
      });
      setActiveScreens(lastScreenWithoutOptflow.id);
      setScreens(updatedScreens);
    } else {
      message.error("Atleast one screen required.");
    }
  };

  const addNewScreen = () => {
    const allscreensWithPayload = selectedFlowJson.screens.filter((screen) => {
      return !optflowIds.includes(screen.id);
    });
    const allscreensWithPayloadFilter = selectedFlowJson.screens
      .filter((screen) => !optflowIds.includes(screen.id))
      .flatMap((screen) =>
        screen.layout?.children?.flatMap((form) =>
          form?.children
            ?.filter((child) => child?.type !== "Footer")
            ?.map((child) => ({ name: child?.name, type: child?.type }))
        )
      );
    const lastScreen = allscreensWithPayload[allscreensWithPayload.length - 1];
    const lastScreenPayload = lastScreen?.layout?.children?.[0]?.children?.find(
      (child) => child.type === "Footer"
    )?.["on-click-action"]?.payload;
    const transformedPayload = Object.keys(lastScreenPayload).reduce(
      (acc, key) => {
        if (lastScreenPayload[key].startsWith("${form.")) {
          const match = allscreensWithPayloadFilter.find((item) => {
            const cleanedName = lastScreenPayload[key]
              .replace("${form.", "")
              .replace("}", "");
            return item.name === cleanedName;
          });
          if (match?.type === "OptIn") {
            acc[key] = { type: "boolean", __example__: false };
          } else {
            acc[key] = { type: "string", __example__: "Example" };
          }
        }
        return acc;
      },
      {}
    );
    Object.assign(transformedPayload, lastScreen.data);
    const newScreen = {
      id: `QUESTION_ONE_${selectedFlowJson.screens.length + 1}`,
      title: "New Screen",
      terminal: true,
      data: { ...transformedPayload },
      layout: {
        type: "SingleColumnLayout",
        children: [
          {
            type: "Form",
            name: "new_form",
            children: [
              {
                type: "TextInput",
                label: "New Field",
                name: `newField_${Math.floor(Date.now() / 1000)}`,
              },
              {
                type: "Footer",
                label: "Complete",
                "on-click-action": {
                  name: "complete",
                  payload: {
                    [`screen_${
                      screens.length - 1
                    }_newField_0`]: `form.newField_${Math.floor(
                      Date.now() / 1000
                    )}`,
                    ...Object.keys(transformedPayload)
                      .map((key) => ({
                        [`${key}`]: `data.${key}`,
                      }))
                      .reduce((acc, curr) => ({ ...acc, ...curr }), {}),
                  },
                },
              },
            ],
          },
        ],
      },
    };

    setSelectedFlowJson((prevState) => {
      const filteredScreens = prevState.screens
        .filter((screen) => !optflowIds.includes(screen.id))
        .map(({ terminal, ...rest }) => rest);
      const footerChildren = filteredScreens[
        filteredScreens.length - 1
      ]?.layout?.children?.[0]?.children?.filter(
        (child) => child.type === "Footer"
      );
      if (footerChildren && footerChildren.length > 0) {
        footerChildren.forEach((child) => {
          child["on-click-action"].name = "navigate";
          child["on-click-action"].next = {
            type: "screen",
            name: `QUESTION_ONE_${selectedFlowJson.screens.length + 1}`,
          };
        });
      }
      return {
        ...prevState,
        screens: [
          ...prevState.screens
            .filter((screen) => !optflowIds.includes(screen.id))
            .map(({ terminal, ...rest }) => rest),
          newScreen,
          ...prevState.screens.filter((screen) =>
            optflowIds.includes(screen.id)
          ),
        ],
      };
    });
  };

  const moveScreen = (dragIndex, hoverIndex) => {
    const screens = [...selectedFlowJson.screens];
    const [movedScreen] = screens.splice(dragIndex, 1);
    screens.splice(hoverIndex, 0, movedScreen);
    const updatedScreens = screens.map((screen, index) => {
      const newScreenId =
        index === 0 ? "QUESTION_ONE" : `QUESTION_ONE_${index}`;
      screens.forEach((flow, flidx) => {
        const footerChildren = flow?.layout?.children?.[0]?.children?.filter(
          (child) => child.type === "Footer"
        );
        if (footerChildren && footerChildren.length > 0) {
          footerChildren.forEach((child) => {
            if (
              index ===
              screens.filter((screen) => !optflowIds.includes(screen.id))
                .length -
                1
            ) {
              delete child["on-click-action"].next;
              child["on-click-action"].name = "complete";
            } else {
              if (child?.["on-click-action"]?.next?.name === screen.id) {
                child["on-click-action"].next.name = newScreenId;
              }
            }
          });
        }
      });
      if (optflowIds.length > 0 && optflowIds.includes(screen.id)) {
        screens.forEach((flow) => {
          const optInChildren = flow?.layout?.children?.[0]?.children?.filter(
            (child) => child.type === "OptIn"
          );

          if (optInChildren && optInChildren.length > 0) {
            optInChildren.forEach((child) => {
              if (child?.["on-click-action"]?.next?.name === screen.id) {
                child["on-click-action"].next.name = newScreenId;
              }
            });
            const optflowIds = optInChildren.map(
              (child) => child?.["on-click-action"]?.next?.name
            );
            setOptflowIds((prevOptflowIds) => {
              const filteredOptflowIds = prevOptflowIds.filter(
                (optId) => optId !== screen.id
              );
              return [...filteredOptflowIds, ...optflowIds];
            });
          }
        });
        return { ...screen, id: newScreenId, data: {} };
      }

      if (index === 0) {
        const currentPayload = generateDataExamples(
          screen.layout?.children?.[0]?.children || [],
          index
        );
        const payload = {
          ...Object.keys(currentPayload)
            .map((key) => ({ [`${key}`]: `form.${key}` }))
            .reduce((acc, curr) => ({ ...acc, ...curr }), {}),
        };
        return {
          ...screen,
          id: newScreenId,
          terminal: true,
          data: {},
          layout: {
            ...screen.layout,
            children: screen.layout?.children?.map((child) => {
              if (child.type === "Form") {
                return {
                  ...child,
                  children: child.children.map((field) => {
                    if (field.type === "Footer") {
                      return {
                        ...field,
                        "on-click-action": {
                          ...field["on-click-action"],
                          payload: payload,
                        },
                      };
                    }
                    return field;
                  }),
                };
              }
              return child;
            }),
          },
        };
      }

      let accumulatedData = {};
      for (let i = 0; i < index; i++) {
        const previousScreenLayout = screens[i].layout?.children?.[0]?.children;
        const previousScreenData = generateDataExamples(
          previousScreenLayout || [],
          i
        );
        accumulatedData = { ...accumulatedData, ...previousScreenData };
      }
      const currentScreenLayout =
        screens[index - 1].layout?.children?.[0]?.children;
      const currentScreenData = generateDataExamples(
        currentScreenLayout || [],
        index - 1
      );
      const combinedData = { ...accumulatedData, ...currentScreenData };
      const currentPayload = generateDataExamples(
        screen.layout?.children?.[0]?.children || [],
        index
      );
      const payload = {
        ...Object.keys(currentPayload)
          .map((key) => ({ [`${key}`]: `form.${key}` }))
          .reduce((acc, curr) => ({ ...acc, ...curr }), {}),
        ...Object.keys(combinedData)
          .map((key) => ({ [`${key}`]: `data.${key}` }))
          .reduce((acc, curr) => ({ ...acc, ...curr }), {}),
      };
      return {
        ...screen,
        terminal: true,
        data: combinedData,
        layout: {
          ...screen.layout,
          children: screen.layout?.children?.map((child) => {
            if (child.type === "Form") {
              return {
                ...child,
                children: child.children.map((field) => {
                  if (field.type === "Footer") {
                    return {
                      ...field,
                      "on-click-action": {
                        ...field["on-click-action"],
                        payload: payload,
                      },
                    };
                  }
                  return field;
                }),
              };
            }
            return child;
          }),
        },
      };
    });
    const screensWithoutOptflow = updatedScreens
      .filter((screen) => !optflowIds.includes(screen.id))
      .map(({ terminal, ...rest }) => rest);
    const screensWithOptflow = updatedScreens.filter((screen) =>
      optflowIds.includes(screen.id)
    );
    const lastScreenWithoutOptflow = {
      terminal: true,
      ...screensWithoutOptflow[screensWithoutOptflow.length - 1],
    };
    setSelectedFlowJson((prevState) => {
      return {
        ...prevState,
        screens: [
          ...screensWithoutOptflow.slice(0, screensWithoutOptflow.length - 1),
          lastScreenWithoutOptflow,
          ...screensWithOptflow,
        ],
      };
    });
    setActiveScreens(lastScreenWithoutOptflow.id);
    setScreens(updatedScreens);
  };

  useEffect(() => {
    if(selectedFlowJson.length > 0){
      const screens = selectedFlowJson.screens.filter((screen) => !optflowIds.includes(screen.id));
      const updatedScreens = screens.map((screen, index) => {
        let accumulatedData = {};
        for (let i = 0; i < index; i++) {
          const previousScreenLayout =
            screens[i].layout?.children?.[0]?.children;
          const previousScreenData = generateDataExamples(
            previousScreenLayout || [],
            i
          );
          accumulatedData = { ...accumulatedData, ...previousScreenData };
        }
        const currentScreenLayout =
          screens[index - 1].layout?.children?.[0]?.children;
        const currentScreenData = generateDataExamples(
          currentScreenLayout || [],
          index - 1
        );
        const combinedData = { ...accumulatedData, ...currentScreenData };
        const currentPayload = generateDataExamples(
          screen.layout?.children?.[0]?.children || [],
          index
        );
        const payload = {
          ...Object.keys(currentPayload)
            .map((key) => ({ [`${key}`]: `form.${key}` }))
            .reduce((acc, curr) => ({ ...acc, ...curr }), {}),
          ...Object.keys(combinedData)
            .map((key) => ({ [`${key}`]: `data.${key}` }))
            .reduce((acc, curr) => ({ ...acc, ...curr }), {}),
        };
        return {
          ...screen,
          data: combinedData,
          layout: {
            ...screen.layout,
            children: screen.layout?.children?.map((child) => {
              if (child.type === "Form") {
                return {
                  ...child,
                  children: child.children.map((field) => {
                    if (field.type === "Footer") {
                      return {
                        ...field,
                        "on-click-action": {
                          ...field["on-click-action"],
                          payload: payload,
                        },
                      };
                    }
                    return field;
                  }),
                };
              }
              return child;
            }),
          },
        };
      });

      setSelectedFlowJson((prevState) => {
        return {
          ...prevState,
          screens: [updatedScreens],
        };
      });
    }
  }, [selectedFlowJson]);

  const DraggableListItem = ({ flow, index }) => {
    const [, drag] = useDrag(() => ({
      type: ItemType,
      item: { index },
    }));

    const [, drop] = useDrop(() => ({
      accept: ItemType,
      hover: (item) => {
        if (item.index !== index) {
          moveScreen(item.index, index);
          item.index = index;
        }
      },
    }));
    return (
      <List.Item
        ref={(node) => {
          if (optflowIds.length > 0 && optflowIds.includes(flow.id)) {
            return;
          }
          drag(drop(node));
        }}
        className={`screenList ${
          activeScreens === ""
            ? index === 0 && "active"
            : flow.id === activeScreens && "active"
        } ${
          optflowIds.length > 0 && optflowIds.includes(flow.id)
            ? "optInScreen"
            : "normalScreen"
        }`}
        onClick={() => setActiveScreens(flow.id)}
      >
        <Flex
          justify="space-between"
          align="center"
          style={{ width: "100%", cursor: "pointer" }}
        >
          <Flex>
            <span
              style={{
                display: "flex",
                alignItems: "center",
                opacity: 0.7,
                cursor: "all-scroll",
              }}
            >
              <DragIndicator />
            </span>
            <span>{flow.title}</span>
          </Flex>
          {!optflowIds.includes(flow.id) && (
            <span
              style={{ display: "flex", alignItems: "center" }}
              onClick={(e) => {
                e.stopPropagation();
                handleRemoveScreen(flow.id);
              }}
            >
              <CloseOutlined />
            </span>
          )}
        </Flex>
      </List.Item>
    );
  };

  const handleTitleChange = (e, id) => {
    const updatedScreens = selectedFlowJson.screens.map((screen) => {
      if (screen.id === id) {
        return {
          ...screen,
          title: e.target.value,
        };
      }
      return screen;
    });

    setSelectedFlowJson((prevState) => ({
      ...prevState,
      screens: updatedScreens,
    }));
  };

  const handleChildRequiredChange = (screenId, childIndex, childType) => {
    setSelectedFlowJson((prevState) => {
      const updatedScreens = prevState.screens.map((screen) => {
        if (screen.id === screenId) {
          const updatedLayout = {
            ...screen.layout,
            children: screen.layout.children.map((child) => {
              if (child.type === "Form") {
                return {
                  ...child,
                  children: child.children.map((chld, indx) =>
                    indx === childIndex && chld.type === childType
                      ? { ...chld, required: !chld.required }
                      : chld
                  ),
                };
              }
              return child;
            }),
          };
          return { ...screen, layout: updatedLayout };
        }
        return screen;
      });
      return { ...prevState, screens: updatedScreens };
    });
  };

  const handelDeleteField = (screenId, childIndex, childType) => {
    setSelectedFlowJson((prevState) => {
      const updatedScreens = prevState.screens.map((screen) => {
        if (screen.id === screenId) {
          const updatedLayout = {
            ...screen.layout,
            children: screen.layout.children.map((child) => {
              if (child.type === "Form") {
                return {
                  ...child,
                  children: child.children.filter(
                    (chld, indx) =>
                      !(indx === childIndex && chld.type === childType)
                  ),
                };
              }
              return child;
            }),
          };
          return { ...screen, layout: updatedLayout };
        }
        return screen;
      });
      return { ...prevState, screens: updatedScreens };
    });
  };

  const handleChildLabelChange = (
    value,
    screenId,
    childIndex,
    childType,
    type
  ) => {
    const newLabel = value;

    setSelectedFlowJson((prevState) => {
      const updatedScreens = prevState.screens.map((screen) => {
        if (screen.id === screenId) {
          const updatedLayout = {
            ...screen.layout,
            children: screen.layout.children.map((child) => {
              if (child.type === "Form") {
                return {
                  ...child,
                  children: child.children.map((chld, indx) =>
                    indx === childIndex && chld.type === childType
                      ? {
                          ...chld,
                          ...(type === "label" && { label: newLabel }),
                          ...(type === "text" && { text: newLabel }),
                          ...(type === "input-type" && {
                            "input-type": newLabel,
                          }),
                          ...(type === "helper-text" && {
                            "helper-text": newLabel,
                          }),
                          ...(childType === "TextInput" &&
                            type === "label" && {
                              name: newLabel
                                .replace(/[^a-zA-Z0-9\s]/g, "")
                                .replace(/\s+/g, "_")
                                .toLowerCase(),
                            }),
                          ...((childType === "RadioButtonsGroup" ||
                            childType === "Dropdown" ||
                            childType === "CheckboxGroup") &&
                            type === "label" && {
                              "data-source": `\${${newLabel
                                .replace(/[^a-zA-Z0-9\s]/g, "")
                                .replace(/\s+/g, "_")
                                .toLowerCase()}}`,
                            }),
                        }
                      : chld
                  ),
                };
              }
              return child;
            }),
          };

          return { ...screen, layout: updatedLayout };
        }
        return screen;
      });

      return { ...prevState, screens: updatedScreens };
    });
  };

  const handelAddDropListItem = (screenId, indxx, idx, ChildType) => {
    setSelectedFlowJson((prevState) => {
      const updatedScreens = prevState.screens.map((screen) => {
        if (screen.id === screenId) {
          const updatedLayout = {
            ...screen.layout,
            children: screen.layout.children.map((child) => {
              if (child.type === "Form") {
                return {
                  ...child,
                  children: child.children.map((chld, indx) =>
                    indx === indxx && chld.type === ChildType
                      ? {
                          ...chld,
                          // Ensure `data-source` is an array before spreading
                          "data-source": Array.isArray(chld["data-source"]) 
                            ? [
                                ...chld["data-source"],
                                {
                                  id: `example${idx + 1}`,
                                  title: `Example ${idx + 1}`,
                                },
                              ]
                            : [
                                {
                                  id: `example${idx + 1}`,
                                  title: `Example ${idx + 1}`,
                                },
                              ],
                        }
                      : chld
                  ),
                };
              }
              return child;
            }),
          };
  
          return { ...screen, layout: updatedLayout };
        }
        return screen;
      });
      return { ...prevState, screens: updatedScreens };
    });
  };  

  const handelDeleteDropListItem = (screenId, indxx, idx, ChildType) => {
    setSelectedFlowJson((prevState) => {
      const updatedScreens = prevState.screens.map((screen) => {
        if (screen.id === screenId) {
          const updatedData = {
            ...screen.layout,
            children: screen.layout.children.map((child) => {
              if (child.type === "Form") {
                return {
                  ...child,
                  children: child.children.map((chld, indx) =>{
                    if (indx === indxx && chld.type === ChildType) {
                      return {
                        ...chld,
                        "data-source": chld["data-source"].filter((_, itemIdx) => itemIdx !== idx),
                      };
                    }
                    return chld;
                  }),
                };
              }
              return child;
            }),
          };
          return { ...screen, layout: updatedData };
        }
        return screen;
      });
      return { ...prevState, screens: updatedScreens };
    });
  };

  const handleOnListItemChange = (e, screenId, indxx, listitem, idx, ChildType) => {
    const newLabel = e.target.value;
    setSelectedFlowJson((prevState) => {
      const updatedScreens = prevState.screens.map((screen) => {
        if (screen.id === screenId) {
          const updatedData = {
            ...screen.layout,
            children: screen.layout.children.map((child) => {
              if (child.type === "Form") {
                return {
                  ...child,
                  children: child.children.map((chld, indx) =>{
                    if (indx === indxx && chld.type === ChildType) {
                      return {
                        ...chld,
                        "data-source": chld["data-source"].map((item, itemIdx) => itemIdx === idx ? { ...item, id:`${idx}_${newLabel.replace(/ /g, "_")}`, title: newLabel } : item),
                      };
                    }
                    return chld;
                  }),
                };
              }
              return child;
            }),
          };
          return { ...screen, layout: updatedData };
        }
        return screen;
      });

      return { ...prevState, screens: updatedScreens };
    });
  };

  const onMenuClick = ({ key }) => {
    const activeScreen =
      activeScreens === ""
        ? screens[0]
        : selectedFlowJson.screens.find(
            (screen) => screen.id === activeScreens
          );
    setSelectedFlowJson((prevState) => {
      const updatedScreens = prevState.screens.map((screen) => {
        if (screen.id === activeScreen.id) {
          const updatedLayout = {
            ...screen.layout,
            children: screen.layout.children.map((child) => {
              if (child.type === "Form") {
                const updatedChildren = [...child.children];
                const footerIndex = updatedChildren.findIndex(
                  (c) => c.type === "Footer"
                );
                const newComponent = {
                  type: key,
                  label: "Label",
                  name: "label",
                  required: true,
                  ...(key !== "DatePicker" &&
                    key !== "TimePicker" && {
                      "input-type": key === "DatePicker" ? "date" : "text",
                      text: "Text",
                    }),
                };
                if (footerIndex !== -1) {
                  updatedChildren.splice(footerIndex, 0, newComponent);
                } else {
                  updatedChildren.push(newComponent);
                }
                return {
                  ...child,
                  children: updatedChildren,
                };
              }
              return child;
            }),
          };
          return { ...screen, layout: updatedLayout };
        }
        return screen;
      });

      return { ...prevState, screens: updatedScreens };
    });
  };

  return (
    <>
      {isLoading ? (
        <div
          style={{
            position: "absolute",
            width: "100vw",
            height: "100vh",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
          }}
        >
          <Flex align="center" justify="center" style={{ minHeight: "100vh" }}>
            <Spin size="large" />
          </Flex>
        </div>
      ) : (
        <Layout style={{ background: "#fff" }}>
          <Flex justify="space-between" align="center">
            <Title level={3} style={{ margin: 0 }}>
              Whatsapp Flow
            </Title>
            {flowId ? (
              <Flex justify="flex-end" gap={16} align="center">
                <Button onClick={handleUpdate}>Save</Button>
                <Button type="primary" onClick={handlePublish}>
                  Publish
                </Button>
              </Flex>
            ) : (
              <Flex justify="flex-end" gap={16} align="center">
                <Button onClick={handleDiscard}>Discard</Button>
                <Button type="primary" onClick={() => form.submit()}>
                  Create
                </Button>
              </Flex>
            )}
          </Flex>
          <Divider style={{ margin: "12px 0" }} />
          <Row gutter={[16, 16]}>
            {flowId ? (
              <Col span={16}>
                <Row gutter={[16, 16]}>
                  <Col
                    span={6}
                    style={{
                      height: "calc(100vh - 219px)",
                      borderRight: "1px solid #ddd",
                    }}
                  >
                    <Title level={4}>Screens</Title>
                    <DndProvider backend={HTML5Backend}>
                      <List
                        dataSource={selectedFlowJson.screens}
                        renderItem={(flow, index) => (
                          <DraggableListItem
                            key={flow.id}
                            flow={flow}
                            index={index}
                          />
                        )}
                      />
                      <Button
                        onClick={() => addNewScreen()}
                        type="primary"
                        block
                      >
                        <PlusOutlined /> Add New Screen
                      </Button>
                    </DndProvider>
                  </Col>
                  <Col
                    span={18}
                    style={{
                      height: "calc(-219px + 100vh)",
                      overflowY: "auto",
                    }}
                  >
                    <Card title="Screen title" className="editscreencards">
                      <Input
                        type="text"
                        value={
                          activeScreens === ""
                            ? screens?.[0]?.title
                            : selectedFlowJson.screens.find(
                                (screen) => screen.id === activeScreens
                              )?.title
                        }
                        onChange={(e) =>
                          handleTitleChange(
                            e,
                            activeScreens === ""
                              ? screens[0].id
                              : screens.find(
                                  (screen) => screen.id === activeScreens
                                ).id
                          )
                        }
                      />
                    </Card>
                    {(() => {
                      const activeScreen =
                        activeScreens === ""
                          ? screens[0]
                          : selectedFlowJson.screens.find(
                              (screen) => screen.id === activeScreens
                            );
                      if (!activeScreen) return null;
                      const { id, layout } = activeScreen;
                      return (
                        <>
                          {layout?.children?.map((child, index) => {
                            if (child.type === "Form") {
                              return (
                                <div key={`form-${index}`}>
                                  {child?.children?.map((chld, indx) => {
                                    switch (chld?.type) {
                                      case "TextHeading":
                                        return (
                                          <Card
                                            key={`text-heading-${indx}`}
                                            title={
                                              <Title
                                                style={{
                                                  margin: 0,
                                                  display: "flex",
                                                  alignItems: "center",
                                                  lineHeight: 0,
                                                }}
                                                level={5}
                                              >
                                                <span style={{ opacity: 0.7 }}>
                                                  <DragIndicator />
                                                </span>
                                                <span>Text Heading</span>
                                              </Title>
                                            }
                                            className="editscreencards"
                                          >
                                            <TextArea
                                              value={chld?.text || ""}
                                              onChange={(e) =>
                                                handleChildLabelChange(
                                                  e.target.value,
                                                  id,
                                                  indx,
                                                  "TextHeading",
                                                  "text"
                                                )
                                              }
                                              showCount
                                              maxLength={80}
                                            />
                                            <Flex
                                              justify="flex-end"
                                              gap={16}
                                              style={{ marginTop: 16 }}
                                            >
                                              <Button
                                                danger
                                                style={{
                                                  textAlign: "center",
                                                  width: 30,
                                                  padding: 0,
                                                }}
                                                onClick={() =>
                                                  handelDeleteField(
                                                    id,
                                                    indx,
                                                    "TextHeading"
                                                  )
                                                }
                                              >
                                                <DeleteOutlined />
                                              </Button>
                                            </Flex>
                                          </Card>
                                        );
                                      case "TextSubheading":
                                        return (
                                          <Card
                                            key={`text-sub-heading-${indx}`}
                                            title={
                                              <Title
                                                style={{
                                                  margin: 0,
                                                  display: "flex",
                                                  alignItems: "center",
                                                  lineHeight: 0,
                                                }}
                                                level={5}
                                              >
                                                <span style={{ opacity: 0.7 }}>
                                                  <DragIndicator />
                                                </span>
                                                <span>Text Subheading</span>
                                              </Title>
                                            }
                                            className="editscreencards"
                                          >
                                            <TextArea
                                              value={chld?.text || ""}
                                              onChange={(e) =>
                                                handleChildLabelChange(
                                                  e.target.value,
                                                  id,
                                                  indx,
                                                  "TextSubheading",
                                                  "text"
                                                )
                                              }
                                              showCount
                                              maxLength={80}
                                            />
                                            <Flex
                                              justify="flex-end"
                                              gap={16}
                                              style={{ marginTop: 16 }}
                                            >
                                              <Button
                                                danger
                                                style={{
                                                  textAlign: "center",
                                                  width: 30,
                                                  padding: 0,
                                                }}
                                                onClick={() =>
                                                  handelDeleteField(
                                                    id,
                                                    indx,
                                                    "TextSubheading"
                                                  )
                                                }
                                              >
                                                <DeleteOutlined />
                                              </Button>
                                            </Flex>
                                          </Card>
                                        );
                                      case "TextBody":
                                        return (
                                          <Card
                                            key={`text-body-${index}-${indx}`}
                                            title={
                                              <Title
                                                style={{
                                                  margin: 0,
                                                  display: "flex",
                                                  alignItems: "center",
                                                  lineHeight: 0,
                                                }}
                                                level={5}
                                              >
                                                <span style={{ opacity: 0.7 }}>
                                                  <DragIndicator />
                                                </span>
                                                <span>Text Body</span>
                                              </Title>
                                            }
                                            className="editscreencards"
                                          >
                                            <TextArea
                                              value={chld?.text || ""}
                                              onChange={(e) =>
                                                handleChildLabelChange(
                                                  e.target.value,
                                                  id,
                                                  indx,
                                                  "TextBody",
                                                  "text"
                                                )
                                              }
                                              showCount
                                              maxLength={4096}
                                            />
                                            <Flex
                                              justify="flex-end"
                                              gap={16}
                                              style={{ marginTop: 16 }}
                                            >
                                              <Button
                                                danger
                                                style={{
                                                  textAlign: "center",
                                                  width: 30,
                                                  padding: 0,
                                                }}
                                                onClick={() =>
                                                  handelDeleteField(
                                                    id,
                                                    indx,
                                                    "TextBody"
                                                  )
                                                }
                                              >
                                                <DeleteOutlined />
                                              </Button>
                                            </Flex>
                                          </Card>
                                        );
                                      case "TextCaption":
                                        return (
                                          <Card
                                            key={`text-caption-${indx}`}
                                            title={
                                              <Title
                                                style={{
                                                  margin: 0,
                                                  display: "flex",
                                                  alignItems: "center",
                                                  lineHeight: 0,
                                                }}
                                                level={5}
                                              >
                                                <span style={{ opacity: 0.7 }}>
                                                  <DragIndicator />
                                                </span>
                                                <span>Text Caption</span>
                                              </Title>
                                            }
                                            className="editscreencards"
                                          >
                                            <TextArea
                                              value={chld?.text || ""}
                                              onChange={(e) =>
                                                handleChildLabelChange(
                                                  e.target.value,
                                                  id,
                                                  indx,
                                                  "TextCaption",
                                                  "text"
                                                )
                                              }
                                              showCount
                                              maxLength={4096}
                                            />
                                            <Flex
                                              justify="flex-end"
                                              gap={16}
                                              style={{ marginTop: 16 }}
                                            >
                                              <Button
                                                danger
                                                style={{
                                                  textAlign: "center",
                                                  width: 30,
                                                  padding: 0,
                                                }}
                                                onClick={() =>
                                                  handelDeleteField(
                                                    id,
                                                    indx,
                                                    "TextCaption"
                                                  )
                                                }
                                              >
                                                <DeleteOutlined />
                                              </Button>
                                            </Flex>
                                          </Card>
                                        );
                                      case "TextInput":
                                        return (
                                          <Card
                                            key={`text-input-${indx}`}
                                            title={
                                              <Title
                                                style={{
                                                  margin: 0,
                                                  display: "flex",
                                                  alignItems: "center",
                                                  lineHeight: 0,
                                                }}
                                                level={5}
                                              >
                                                <span style={{ opacity: 0.7 }}>
                                                  <DragIndicator />
                                                </span>
                                                <span>Input</span>
                                              </Title>
                                            }
                                            className="editscreencards"
                                          >
                                            <div style={{ marginBottom: 16 }}>
                                              <Select
                                                value={
                                                  chld?.["input-type"] || "text"
                                                }
                                                style={{ width: "100%" }}
                                                onChange={(value) =>
                                                  handleChildLabelChange(
                                                    value,
                                                    id,
                                                    indx,
                                                    "TextInput",
                                                    "input-type"
                                                  )
                                                }
                                              >
                                                <Option
                                                  key={"text"}
                                                  value={"text"}
                                                >
                                                  Text
                                                </Option>
                                                <Option
                                                  key={"password"}
                                                  value={"password"}
                                                >
                                                  Password
                                                </Option>
                                                <Option
                                                  key={"email"}
                                                  value={"email"}
                                                >
                                                  Email
                                                </Option>
                                                <Option
                                                  key={"number"}
                                                  value={"number"}
                                                >
                                                  Number
                                                </Option>
                                                <Option
                                                  key={"passcode"}
                                                  value={"passcode"}
                                                >
                                                  Passcode
                                                </Option>
                                                <Option
                                                  key={"phone"}
                                                  value={"phone"}
                                                >
                                                  Phone
                                                </Option>
                                              </Select>
                                            </div>
                                            <div style={{ marginBottom: 16 }}>
                                              <Input
                                                type="text"
                                                value={chld?.label || ""}
                                                onChange={(e) =>
                                                  handleChildLabelChange(
                                                    e.target.value,
                                                    id,
                                                    indx,
                                                    "TextInput",
                                                    "label"
                                                  )
                                                }
                                                showCount
                                                maxLength={20}
                                              />
                                            </div>
                                            <div style={{ marginBottom: 16 }}>
                                              <p>
                                                Instructions{" "}
                                                <span color="#eee">
                                                  Optional
                                                </span>
                                              </p>
                                              <Input
                                                type="text"
                                                value={
                                                  chld?.["helper-text"] || ""
                                                }
                                                onChange={(e) =>
                                                  handleChildLabelChange(
                                                    e.target.value,
                                                    id,
                                                    indx,
                                                    "TextInput",
                                                    "helper-text"
                                                  )
                                                }
                                                showCount
                                                maxLength={80}
                                              />
                                            </div>
                                            <Flex
                                              justify="flex-end"
                                              gap={16}
                                              style={{ marginTop: 16 }}
                                            >
                                              <Flex align="center" gap={16}>
                                                <span>Required</span>{" "}
                                                <Switch
                                                  checked={chld?.required}
                                                  onChange={() =>
                                                    handleChildRequiredChange(
                                                      id,
                                                      indx,
                                                      "TextInput"
                                                    )
                                                  }
                                                />
                                              </Flex>
                                              <Button
                                                danger
                                                style={{
                                                  textAlign: "center",
                                                  width: 30,
                                                  padding: 0,
                                                }}
                                                onClick={() =>
                                                  handelDeleteField(
                                                    id,
                                                    indx,
                                                    "TextInput"
                                                  )
                                                }
                                              >
                                                <DeleteOutlined />
                                              </Button>
                                            </Flex>
                                          </Card>
                                        );
                                      case "TextArea":
                                        return (
                                          <Card
                                            key={`text-area-${indx}`}
                                            title={
                                              <Title
                                                style={{
                                                  margin: 0,
                                                  display: "flex",
                                                  alignItems: "center",
                                                  lineHeight: 0,
                                                }}
                                                level={5}
                                              >
                                                <span style={{ opacity: 0.7 }}>
                                                  <DragIndicator />
                                                </span>
                                                <span>
                                                  Textarea / Paragraph
                                                </span>
                                              </Title>
                                            }
                                            className="editscreencards"
                                          >
                                            <div style={{ marginBottom: 16 }}>
                                              <Input
                                                type="text"
                                                value={chld?.label || ""}
                                                onChange={(e) =>
                                                  handleChildLabelChange(
                                                    e.target.value,
                                                    id,
                                                    indx,
                                                    "TextArea",
                                                    "label"
                                                  )
                                                }
                                                showCount
                                                maxLength={20}
                                              />
                                            </div>
                                            <div style={{ marginBottom: 16 }}>
                                              <p>
                                                Instructions{" "}
                                                <span color="#eee">
                                                  Optional
                                                </span>
                                              </p>
                                              <Input
                                                type="text"
                                                value={
                                                  chld?.["helper-text"] || ""
                                                }
                                                onChange={(e) =>
                                                  handleChildLabelChange(
                                                    e.target.value,
                                                    id,
                                                    indx,
                                                    "TextArea",
                                                    "helper-text"
                                                  )
                                                }
                                                showCount
                                                maxLength={80}
                                              />
                                            </div>
                                            <Flex
                                              justify="flex-end"
                                              gap={16}
                                              style={{ marginTop: 16 }}
                                            >
                                              <Flex align="center" gap={16}>
                                                <span>Required</span>{" "}
                                                <Switch
                                                  checked={chld?.required}
                                                  onChange={() =>
                                                    handleChildRequiredChange(
                                                      id,
                                                      indx,
                                                      "TextArea"
                                                    )
                                                  }
                                                />
                                              </Flex>
                                              <Button
                                                danger
                                                style={{
                                                  textAlign: "center",
                                                  width: 30,
                                                  padding: 0,
                                                }}
                                                onClick={() =>
                                                  handelDeleteField(
                                                    id,
                                                    indx,
                                                    "TextArea"
                                                  )
                                                }
                                              >
                                                <DeleteOutlined />
                                              </Button>
                                            </Flex>
                                          </Card>
                                        );
                                      case "DatePicker":
                                        return (
                                          <Card
                                            key={`text-datepicker-${indx}`}
                                            title={
                                              <Title
                                                style={{
                                                  margin: 0,
                                                  display: "flex",
                                                  alignItems: "center",
                                                  lineHeight: 0,
                                                }}
                                                level={5}
                                              >
                                                <span style={{ opacity: 0.7 }}>
                                                  <DragIndicator />
                                                </span>
                                                <span>DatePicker</span>
                                              </Title>
                                            }
                                            className="editscreencards"
                                          >
                                            <div style={{ marginBottom: 16 }}>
                                              <Input
                                                type="text"
                                                value={chld?.label || ""}
                                                onChange={(e) =>
                                                  handleChildLabelChange(
                                                    e.target.value,
                                                    id,
                                                    indx,
                                                    "DatePicker",
                                                    "label"
                                                  )
                                                }
                                              />
                                            </div>
                                            <div style={{ marginBottom: 16 }}>
                                              <p>
                                                Instructions{" "}
                                                <span color="#eee">
                                                  Optional
                                                </span>
                                              </p>
                                              <Input
                                                type="text"
                                                value={
                                                  chld?.["helper-text"] || ""
                                                }
                                                onChange={(e) =>
                                                  handleChildLabelChange(
                                                    e.target.value,
                                                    id,
                                                    indx,
                                                    "DatePicker",
                                                    "helper-text"
                                                  )
                                                }
                                                showCount
                                                maxLength={80}
                                              />
                                            </div>
                                            <Flex
                                              justify="flex-end"
                                              gap={16}
                                              style={{ marginTop: 16 }}
                                            >
                                              <Flex align="center" gap={16}>
                                                <span>Required</span>{" "}
                                                <Switch
                                                  checked={chld?.required}
                                                  onChange={() =>
                                                    handleChildRequiredChange(
                                                      id,
                                                      indx,
                                                      "DatePicker"
                                                    )
                                                  }
                                                />
                                              </Flex>
                                              <Button
                                                danger
                                                style={{
                                                  textAlign: "center",
                                                  width: 30,
                                                  padding: 0,
                                                }}
                                                onClick={() =>
                                                  handelDeleteField(
                                                    id,
                                                    indx,
                                                    "DatePicker"
                                                  )
                                                }
                                              >
                                                <DeleteOutlined />
                                              </Button>
                                            </Flex>
                                          </Card>
                                        );
                                      case "TimePicker":
                                        return (
                                          <Card
                                            key={`text-body-${index}-${indx}`}
                                            title={
                                              <Title
                                                style={{
                                                  margin: 0,
                                                  display: "flex",
                                                  alignItems: "center",
                                                  lineHeight: 0,
                                                }}
                                                level={5}
                                              >
                                                <span style={{ opacity: 0.7 }}>
                                                  <DragIndicator />
                                                </span>
                                                <span>TimePicker</span>
                                              </Title>
                                            }
                                            className="editscreencards"
                                          >
                                            <Input
                                              type="text"
                                              placeholder="Label"
                                              value={chld?.label || ""}
                                              onChange={(e) =>
                                                handleChildLabelChange(
                                                  e.target.value,
                                                  id,
                                                  indx,
                                                  "TimePicker",
                                                  "label"
                                                )
                                              }
                                              maxLength={50}
                                              showCount
                                            />
                                            <Paragraph style={{ marginTop: 8 }}>
                                              Time
                                            </Paragraph>
                                            <Flex gap={16}>
                                              <RangePicker
                                                format="hh:mm A"
                                                use12Hours
                                                onChange={(times) =>
                                                  handleTimeRangeChange(
                                                    times,
                                                    id,
                                                    indx
                                                  )
                                                }
                                              />
                                              <Select
                                                style={{ width: 100 }}
                                                onChange={(value) =>
                                                  handleIntervalTypeChange(
                                                    value,
                                                    id,
                                                    indx
                                                  )
                                                }
                                                value={
                                                  intervalType[
                                                    `type${id}${indx}`
                                                  ]
                                                    ? intervalType[
                                                        `type${id}${indx}`
                                                      ]
                                                    : "minutes"
                                                }
                                              >
                                                <Option value="hour">
                                                  Hours
                                                </Option>
                                                <Option value="minutes">
                                                  Minutes
                                                </Option>
                                              </Select>
                                              <Select
                                                style={{ width: 100 }}
                                                onChange={(value) =>
                                                  handleIntervalDurationChange(
                                                    value,
                                                    id,
                                                    indx
                                                  )
                                                }
                                                value={
                                                  intervalDuration[
                                                    `duration${id}${indx}`
                                                  ]
                                                    ? intervalDuration[
                                                        `duration${id}${indx}`
                                                      ]
                                                    : 1
                                                }
                                              >
                                                {[...Array(60)].map((_, m) => (
                                                  <Option key={m} value={m + 1}>
                                                    {m + 1}
                                                  </Option>
                                                ))}
                                              </Select>
                                              <Button
                                                type="primary"
                                                onClick={() =>
                                                  handleCreateSloats(id, indx)
                                                }
                                              >
                                                Create Slots
                                              </Button>
                                            </Flex>
                                            {Array.isArray(
                                              chld?.["data-source"]
                                            ) ? (
                                              chld?.["data-source"]?.map(
                                                (droplist, idx) => (
                                                  <Flex
                                                    justify="space-between"
                                                    gap={6}
                                                    align="center"
                                                    style={{
                                                      marginBottom: 8,
                                                    }}
                                                    key={`inner-${indx}-${idx}`}
                                                  >
                                                    <Input
                                                      type="text"
                                                      showCount
                                                      maxLength={80}
                                                      value={droplist.title}
                                                      onChange={(e) =>
                                                        handleOnListItemChange(
                                                          e,
                                                          id,
                                                          indx,
                                                          droplist,
                                                          idx,
                                                          "TimePicker"
                                                        )
                                                      }
                                                    />
                                                    <Flex
                                                      gap={6}
                                                      align="center"
                                                    >
                                                      <Button
                                                        disabled={
                                                          chld?.["data-source"]
                                                            .length === 1
                                                        }
                                                        danger
                                                        style={{
                                                          textAlign: "center",
                                                          width: 30,
                                                          padding: 0,
                                                        }}
                                                        onClick={() =>
                                                          handelDeleteDropListItem(
                                                            id,
                                                            indx,
                                                            idx,
                                                            "TimePicker"
                                                          )
                                                        }
                                                      >
                                                        <DeleteOutlined />
                                                      </Button>
                                                      {chld?.["data-source"]
                                                        .length -
                                                        1 ===
                                                        idx && (
                                                        <Button
                                                          disabled={
                                                            chld?.[
                                                              "data-source"
                                                            ].length === 30
                                                          }
                                                          type="primary"
                                                          style={{
                                                            textAlign: "center",
                                                            width: 30,
                                                            padding: 0,
                                                          }}
                                                          onClick={() =>
                                                            handelAddDropListItem(
                                                              id,
                                                              indx,
                                                              chld?.[
                                                                "data-source"
                                                              ].length,
                                                              "RadioButtonsGroup"
                                                            )
                                                          }
                                                        >
                                                          <PlusOutlined />
                                                        </Button>
                                                      )}
                                                    </Flex>
                                                  </Flex>
                                                )
                                              )
                                            ) : (
                                              <>
                                              </>
                                            )}
                                            <Flex
                                              justify="flex-end"
                                              gap={16}
                                              style={{ marginTop: 16 }}
                                            >
                                              <Flex align="center" gap={16}>
                                                <span>Required</span>{" "}
                                                <Switch
                                                  checked={chld?.required}
                                                  onChange={() =>
                                                    handleChildRequiredChange(
                                                      id,
                                                      indx,
                                                      "TimePicker"
                                                    )
                                                  }
                                                />
                                              </Flex>
                                              <Button
                                                danger
                                                style={{
                                                  textAlign: "center",
                                                  width: 30,
                                                  padding: 0,
                                                }}
                                                onClick={() =>
                                                  handelDeleteField(
                                                    id,
                                                    indx,
                                                    "TimePicker"
                                                  )
                                                }
                                              >
                                                <DeleteOutlined />
                                              </Button>
                                            </Flex>
                                          </Card>
                                        );
                                      case "RadioButtonsGroup":
                                        return (
                                          <Card
                                            key={`text-body-${index}-${indx}`}
                                            title={
                                              <Title
                                                style={{
                                                  margin: 0,
                                                  display: "flex",
                                                  alignItems: "center",
                                                  lineHeight: 0,
                                                }}
                                                level={5}
                                              >
                                                <span style={{ opacity: 0.7 }}>
                                                  <DragIndicator />
                                                </span>
                                                <span>Radio Buttons</span>
                                              </Title>
                                            }
                                            className="editscreencards"
                                          >
                                            <Input
                                              type="text"
                                              placeholder="Label"
                                              value={chld?.label || ""}
                                              onChange={(e) =>
                                                handleChildLabelChange(
                                                  e.target.value,
                                                  id,
                                                  indx,
                                                  "RadioButtonsGroup",
                                                  "label"
                                                )
                                              }
                                              maxLength={50}
                                              showCount
                                            />
                                            <Paragraph style={{ marginTop: 8 }}>
                                              Options
                                            </Paragraph>
                                            {Array.isArray(
                                              chld?.["data-source"]
                                            ) ? (
                                              chld?.["data-source"]?.map(
                                                (droplist, idx) => (
                                                  <Flex
                                                    justify="space-between"
                                                    gap={6}
                                                    align="center"
                                                    style={{
                                                      marginBottom: 8,
                                                    }}
                                                    key={`inner-${indx}-${idx}`}
                                                  >
                                                    <Input
                                                      type="text"
                                                      showCount
                                                      maxLength={80}
                                                      value={droplist.title}
                                                      onChange={(e) =>
                                                        handleOnListItemChange(
                                                          e,
                                                          id,
                                                          indx,
                                                          droplist,
                                                          idx,
                                                          "RadioButtonsGroup"
                                                        )
                                                      }
                                                    />
                                                    <Flex
                                                      gap={6}
                                                      align="center"
                                                    >
                                                      <Button
                                                        disabled={
                                                          chld?.["data-source"]
                                                            .length === 1
                                                        }
                                                        danger
                                                        style={{
                                                          textAlign: "center",
                                                          width: 30,
                                                          padding: 0,
                                                        }}
                                                        onClick={() =>
                                                          handelDeleteDropListItem(
                                                            id,
                                                            indx,
                                                            idx,
                                                            "RadioButtonsGroup"
                                                          )
                                                        }
                                                      >
                                                        <DeleteOutlined />
                                                      </Button>
                                                      {chld?.["data-source"]
                                                        .length -
                                                        1 ===
                                                        idx && (
                                                        <Button
                                                          disabled={
                                                            chld?.[
                                                              "data-source"
                                                            ].length === 30
                                                          }
                                                          type="primary"
                                                          style={{
                                                            textAlign: "center",
                                                            width: 30,
                                                            padding: 0,
                                                          }}
                                                          onClick={() =>
                                                            handelAddDropListItem(
                                                              id,
                                                              indx,
                                                              chld?.[
                                                                "data-source"
                                                              ].length,
                                                              "RadioButtonsGroup"
                                                            )
                                                          }
                                                        >
                                                          <PlusOutlined />
                                                        </Button>
                                                      )}
                                                    </Flex>
                                                  </Flex>
                                                )
                                              )
                                            ) : (
                                              <>
                                                {(() => {
                                                  const newString =
                                                    chld?.[
                                                      "data-source"
                                                    ]?.replace(
                                                      /\${data\.(.*?)}/g,
                                                      "$1"
                                                    ) || "";
                                                  return (
                                                    <div key={`inner-${indx}`}>
                                                      {activeScreen.data[
                                                        newString
                                                      ]?.["__example__"] ? (
                                                        activeScreen.data[
                                                          newString
                                                        ]?.["__example__"].map(
                                                          (listitem, idx) => (
                                                            <Flex
                                                              justify="space-between"
                                                              gap={6}
                                                              align="center"
                                                              style={{
                                                                marginBottom: 8,
                                                              }}
                                                              key={`inner-${indx}-${idx}`}
                                                            >
                                                              <Input
                                                                type="text"
                                                                showCount
                                                                maxLength={80}
                                                                value={
                                                                  listitem.title
                                                                }
                                                                onChange={(e) =>
                                                                  handleOnListItemChange(
                                                                    e,
                                                                    id,
                                                                    newString,
                                                                    listitem,
                                                                    idx,
                                                                    "RadioButtonsGroup"
                                                                  )
                                                                }
                                                              />
                                                              <Flex
                                                                gap={6}
                                                                align="center"
                                                              >
                                                                <Button
                                                                  disabled={
                                                                    activeScreen
                                                                      .data[
                                                                      newString
                                                                    ][
                                                                      "__example__"
                                                                    ].length ===
                                                                    1
                                                                  }
                                                                  danger
                                                                  style={{
                                                                    textAlign:
                                                                      "center",
                                                                    width: 30,
                                                                    padding: 0,
                                                                  }}
                                                                  onClick={() =>
                                                                    handelDeleteDropListItem(
                                                                      id,
                                                                      newString,
                                                                      idx,
                                                                      "RadioButtonsGroup"
                                                                    )
                                                                  }
                                                                >
                                                                  <DeleteOutlined />
                                                                </Button>
                                                                {activeScreen
                                                                  .data[
                                                                  newString
                                                                ]["__example__"]
                                                                  .length -
                                                                  1 ===
                                                                  idx && (
                                                                  <Button
                                                                    disabled={
                                                                      activeScreen
                                                                        .data[
                                                                        newString
                                                                      ][
                                                                        "__example__"
                                                                      ]
                                                                        .length ===
                                                                      30
                                                                    }
                                                                    type="primary"
                                                                    style={{
                                                                      textAlign:
                                                                        "center",
                                                                      width: 30,
                                                                      padding: 0,
                                                                    }}
                                                                    onClick={() =>
                                                                      handelAddDropListItem(
                                                                        id,
                                                                        newString,
                                                                        activeScreen
                                                                          .data[
                                                                          newString
                                                                        ][
                                                                          "__example__"
                                                                        ].length
                                                                      )
                                                                    }
                                                                  >
                                                                    <PlusOutlined />
                                                                  </Button>
                                                                )}
                                                              </Flex>
                                                            </Flex>
                                                          )
                                                        )
                                                      ) : (
                                                        <Button
                                                          type="primary"
                                                          style={{
                                                            textAlign: "center",
                                                            width: 30,
                                                            padding: 0,
                                                          }}
                                                          onClick={() =>
                                                            handelAddDropListItem(
                                                              id,
                                                              indx,
                                                              0,
                                                              "RadioButtonsGroup"
                                                            )
                                                          }
                                                        >
                                                          <PlusOutlined />
                                                        </Button>
                                                      )}
                                                    </div>
                                                  );
                                                })()}
                                              </>
                                            )}
                                            <Flex
                                              justify="flex-end"
                                              gap={16}
                                              style={{ marginTop: 16 }}
                                            >
                                              <Flex align="center" gap={16}>
                                                <span>Required</span>{" "}
                                                <Switch
                                                  checked={chld?.required}
                                                  onChange={() =>
                                                    handleChildRequiredChange(
                                                      id,
                                                      indx,
                                                      "RadioButtonsGroup"
                                                    )
                                                  }
                                                />
                                              </Flex>
                                              <Button
                                                danger
                                                style={{
                                                  textAlign: "center",
                                                  width: 30,
                                                  padding: 0,
                                                }}
                                                onClick={() =>
                                                  handelDeleteField(
                                                    id,
                                                    indx,
                                                    "RadioButtonsGroup"
                                                  )
                                                }
                                              >
                                                <DeleteOutlined />
                                              </Button>
                                            </Flex>
                                          </Card>
                                        );
                                      case "Dropdown":
                                        return (
                                          <Card
                                            key={`text-body-${index}-${indx}`}
                                            title={
                                              <Title
                                                style={{
                                                  margin: 0,
                                                  display: "flex",
                                                  alignItems: "center",
                                                  lineHeight: 0,
                                                }}
                                                level={5}
                                              >
                                                <span style={{ opacity: 0.7 }}>
                                                  <DragIndicator />
                                                </span>
                                                <span>Dropdown</span>
                                              </Title>
                                            }
                                            className="editscreencards"
                                          >
                                            <Input
                                              type="text"
                                              placeholder="Label"
                                              value={chld?.label || ""}
                                              onChange={(e) =>
                                                handleChildLabelChange(
                                                  e.target.value,
                                                  id,
                                                  indx,
                                                  "Dropdown",
                                                  "label"
                                                )
                                              }
                                              maxLength={50}
                                              showCount
                                            />
                                            <Paragraph style={{ marginTop: 8 }}>
                                              Options
                                            </Paragraph>
                                            {Array.isArray(
                                              chld?.["data-source"]
                                            ) ? (
                                              <div key={`inner-${indx}`}>
                                                {chld?.["data-source"] ? (
                                                  chld?.["data-source"]?.map(
                                                    (droplist, idx) => (
                                                      <Flex
                                                        justify="space-between"
                                                        gap={6}
                                                        align="center"
                                                        style={{
                                                          marginBottom: 8,
                                                        }}
                                                        key={`inner-${indx}-${idx}`}
                                                      >
                                                        <Input
                                                          type="text"
                                                          showCount
                                                          maxLength={80}
                                                          value={droplist.title}
                                                          onChange={(e) =>
                                                            handleOnListItemChange(
                                                              e,
                                                              id,
                                                              indx,
                                                              droplist,
                                                              idx,
                                                              "Dropdown"
                                                            )
                                                          }
                                                        />
                                                        <Flex
                                                          gap={6}
                                                          align="center"
                                                        >
                                                          <Button
                                                            disabled={
                                                              chld?.[
                                                                "data-source"
                                                              ].length === 1
                                                            }
                                                            danger
                                                            style={{
                                                              textAlign:
                                                                "center",
                                                              width: 30,
                                                              padding: 0,
                                                            }}
                                                            onClick={() =>
                                                              handelDeleteDropListItem(
                                                                id,
                                                                indx,
                                                                idx,
                                                                "Dropdown"
                                                              )
                                                            }
                                                          >
                                                            <DeleteOutlined />
                                                          </Button>
                                                          {chld?.["data-source"]
                                                            .length -
                                                            1 ===
                                                            idx && (
                                                            <Button
                                                              disabled={
                                                                chld?.[
                                                                  "data-source"
                                                                ].length === 30
                                                              }
                                                              type="primary"
                                                              style={{
                                                                textAlign:
                                                                  "center",
                                                                width: 30,
                                                                padding: 0,
                                                              }}
                                                              onClick={() =>
                                                                handelAddDropListItem(
                                                                  id,
                                                                  indx,
                                                                  chld?.[
                                                                    "data-source"
                                                                  ].length,
                                                                  "Dropdown"
                                                                )
                                                              }
                                                            >
                                                              <PlusOutlined />
                                                            </Button>
                                                          )}
                                                        </Flex>
                                                      </Flex>
                                                    )
                                                  )
                                                ) : (
                                                  <Button
                                                    type="primary"
                                                    style={{
                                                      textAlign: "center",
                                                      width: 30,
                                                      padding: 0,
                                                    }}
                                                    onClick={() =>
                                                      handelAddDropListItem(
                                                        id,
                                                        indx,
                                                        0,
                                                        "Dropdown"
                                                      )
                                                    }
                                                  >
                                                    <PlusOutlined />
                                                  </Button>
                                                )}
                                              </div>
                                            ) : (
                                              <>
                                                {(() => {
                                                  const newString =
                                                    chld?.[
                                                      "data-source"
                                                    ]?.replace(
                                                      /\${data\.(.*?)}/g,
                                                      "$1"
                                                    ) || "";
                                                  return (
                                                    <div key={`inner-${indx}`}>
                                                      {activeScreen.data[
                                                        newString
                                                      ]?.["__example__"] ? (
                                                        activeScreen.data[
                                                          newString
                                                        ]["__example__"].map(
                                                          (listitem, idx) => (
                                                            <Flex
                                                              justify="space-between"
                                                              gap={6}
                                                              align="center"
                                                              style={{
                                                                marginBottom: 8,
                                                              }}
                                                              key={`inner-${indx}-${idx}`}
                                                            >
                                                              <Input
                                                                type="text"
                                                                showCount
                                                                maxLength={80}
                                                                value={
                                                                  listitem.title
                                                                }
                                                                onChange={(e) =>
                                                                  handleOnListItemChange(
                                                                    e,
                                                                    id,
                                                                    newString,
                                                                    listitem,
                                                                    idx,
                                                                    "Dropdown"
                                                                  )
                                                                }
                                                              />
                                                              <Flex
                                                                gap={6}
                                                                align="center"
                                                              >
                                                                <Button
                                                                  disabled={
                                                                    activeScreen
                                                                      .data[
                                                                      newString
                                                                    ][
                                                                      "__example__"
                                                                    ].length ===
                                                                    1
                                                                  }
                                                                  danger
                                                                  style={{
                                                                    textAlign:
                                                                      "center",
                                                                    width: 30,
                                                                    padding: 0,
                                                                  }}
                                                                  onClick={() =>
                                                                    handelDeleteDropListItem(
                                                                      id,
                                                                      newString,
                                                                      idx,
                                                                      "Dropdown"
                                                                    )
                                                                  }
                                                                >
                                                                  <DeleteOutlined />
                                                                </Button>
                                                                {activeScreen
                                                                  .data[
                                                                  newString
                                                                ]["__example__"]
                                                                  .length -
                                                                  1 ===
                                                                  idx && (
                                                                  <Button
                                                                    disabled={
                                                                      activeScreen
                                                                        .data[
                                                                        newString
                                                                      ][
                                                                        "__example__"
                                                                      ]
                                                                        .length ===
                                                                      30
                                                                    }
                                                                    type="primary"
                                                                    style={{
                                                                      textAlign:
                                                                        "center",
                                                                      width: 30,
                                                                      padding: 0,
                                                                    }}
                                                                    onClick={() =>
                                                                      handelAddDropListItem(
                                                                        id,
                                                                        newString,
                                                                        activeScreen
                                                                          .data[
                                                                          newString
                                                                        ][
                                                                          "__example__"
                                                                        ].length
                                                                      )
                                                                    }
                                                                  >
                                                                    <PlusOutlined />
                                                                  </Button>
                                                                )}
                                                              </Flex>
                                                            </Flex>
                                                          )
                                                        )
                                                      ) : (
                                                        <Button
                                                          type="primary"
                                                          style={{
                                                            textAlign: "center",
                                                            width: 30,
                                                            padding: 0,
                                                          }}
                                                          onClick={() =>
                                                            handelAddDropListItem(
                                                              id,
                                                              indx,
                                                              0,
                                                              "Dropdown"
                                                            )
                                                          }
                                                        >
                                                          <PlusOutlined />
                                                        </Button>
                                                      )}
                                                    </div>
                                                  );
                                                })()}
                                              </>
                                            )}
                                            <Flex
                                              justify="flex-end"
                                              gap={16}
                                              style={{ marginTop: 16 }}
                                            >
                                              <Flex align="center" gap={16}>
                                                <span>Required</span>{" "}
                                                <Switch
                                                  checked={chld?.required}
                                                  onChange={() =>
                                                    handleChildRequiredChange(
                                                      id,
                                                      indx,
                                                      "Dropdown"
                                                    )
                                                  }
                                                />
                                              </Flex>
                                              <Button
                                                danger
                                                style={{
                                                  textAlign: "center",
                                                  width: 30,
                                                  padding: 0,
                                                }}
                                                onClick={() =>
                                                  handelDeleteField(
                                                    id,
                                                    indx,
                                                    "Dropdown"
                                                  )
                                                }
                                              >
                                                <DeleteOutlined />
                                              </Button>
                                            </Flex>
                                          </Card>
                                        );
                                      case "EmbeddedLink":
                                        return (
                                          <Card
                                            key={`text-body-${index}-${indx}`}
                                            title={
                                              <Title
                                                style={{
                                                  margin: 0,
                                                  display: "flex",
                                                  alignItems: "center",
                                                  lineHeight: 0,
                                                }}
                                                level={5}
                                              >
                                                <span style={{ opacity: 0.7 }}>
                                                  <DragIndicator />
                                                </span>
                                                <span>Embedded link</span>
                                              </Title>
                                            }
                                            className="editscreencards"
                                          >
                                            <Input
                                              type="text"
                                              value={chld?.label || ""}
                                              onChange={(e) =>
                                                handleChildLabelChange(
                                                  e.target.value,
                                                  id,
                                                  indx,
                                                  "EmbeddedLink",
                                                  "label"
                                                )
                                              }
                                            />
                                            <Flex
                                              justify="flex-end"
                                              gap={16}
                                              style={{ marginTop: 16 }}
                                            >
                                              <Button
                                                danger
                                                style={{
                                                  textAlign: "center",
                                                  width: 30,
                                                  padding: 0,
                                                }}
                                                onClick={() =>
                                                  handelDeleteField(
                                                    id,
                                                    indx,
                                                    "EmbeddedLink"
                                                  )
                                                }
                                              >
                                                <DeleteOutlined />
                                              </Button>
                                            </Flex>
                                          </Card>
                                        );
                                      case "OptIn":
                                        return (
                                          <Card
                                            key={`text-caption-${indx}`}
                                            title={
                                              <Title
                                                style={{
                                                  margin: 0,
                                                  display: "flex",
                                                  alignItems: "center",
                                                  lineHeight: 0,
                                                }}
                                                level={5}
                                              >
                                                <span style={{ opacity: 0.7 }}>
                                                  <DragIndicator />
                                                </span>
                                                <span>Opt in</span>
                                              </Title>
                                            }
                                            className="editscreencards"
                                          >
                                            <Input
                                              type="text"
                                              value={chld?.label || ""}
                                              onChange={(e) =>
                                                handleChildLabelChange(
                                                  e.target.value,
                                                  id,
                                                  indx,
                                                  "OptIn",
                                                  "label"
                                                )
                                              }
                                            />
                                            <Flex
                                              justify="flex-end"
                                              gap={16}
                                              style={{ marginTop: 16 }}
                                            >
                                              <Button
                                                danger
                                                style={{
                                                  textAlign: "center",
                                                  width: 30,
                                                  padding: 0,
                                                }}
                                                onClick={() =>
                                                  handelDeleteField(
                                                    id,
                                                    indx,
                                                    "OptIn"
                                                  )
                                                }
                                              >
                                                <DeleteOutlined />
                                              </Button>
                                            </Flex>
                                          </Card>
                                        );
                                      case "CheckboxGroup":
                                        return (
                                          <Card
                                            key={`text-body-${index}-${indx}`}
                                            title={
                                              <Title
                                                style={{
                                                  margin: 0,
                                                  display: "flex",
                                                  alignItems: "center",
                                                  lineHeight: 0,
                                                }}
                                                level={5}
                                              >
                                                <span style={{ opacity: 0.7 }}>
                                                  <DragIndicator />
                                                </span>
                                                <span>Checkbox</span>
                                              </Title>
                                            }
                                            className="editscreencards"
                                          >
                                            <Input
                                              type="text"
                                              placeholder="Label"
                                              value={chld?.label || ""}
                                              onChange={(e) =>
                                                handleChildLabelChange(
                                                  e.target.value,
                                                  id,
                                                  indx,
                                                  "CheckboxGroup",
                                                  "label"
                                                )
                                              }
                                              maxLength={50}
                                              showCount
                                            />
                                            <Paragraph style={{ marginTop: 8 }}>
                                              Options
                                            </Paragraph>
                                            {Array.isArray(
                                              chld?.["data-source"]
                                            ) ? (
                                              chld?.["data-source"]?.map(
                                                (droplist, idx) => (
                                                  <Flex
                                                    justify="space-between"
                                                    gap={6}
                                                    align="center"
                                                    style={{
                                                      marginBottom: 8,
                                                    }}
                                                    key={`inner-${indx}-${idx}`}
                                                  >
                                                    <Input
                                                      type="text"
                                                      showCount
                                                      maxLength={80}
                                                      value={droplist.title}
                                                      onChange={(e) =>
                                                        handleOnListItemChange(
                                                          e,
                                                          id,
                                                          indx,
                                                          droplist,
                                                          idx,
                                                          "CheckboxGroup"
                                                        )
                                                      }
                                                    />
                                                    <Flex
                                                      gap={6}
                                                      align="center"
                                                    >
                                                      <Button
                                                        disabled={
                                                          chld?.["data-source"]
                                                            .length === 1
                                                        }
                                                        danger
                                                        style={{
                                                          textAlign: "center",
                                                          width: 30,
                                                          padding: 0,
                                                        }}
                                                        onClick={() =>
                                                          handelDeleteDropListItem(
                                                            id,
                                                            indx,
                                                            idx,
                                                            "CheckboxGroup"
                                                          )
                                                        }
                                                      >
                                                        <DeleteOutlined />
                                                      </Button>
                                                      {chld?.["data-source"]
                                                        .length -
                                                        1 ===
                                                        idx && (
                                                        <Button
                                                          disabled={
                                                            chld?.[
                                                              "data-source"
                                                            ].length === 30
                                                          }
                                                          type="primary"
                                                          style={{
                                                            textAlign: "center",
                                                            width: 30,
                                                            padding: 0,
                                                          }}
                                                          onClick={() =>
                                                            handelAddDropListItem(
                                                              id,
                                                              indx,
                                                              chld?.[
                                                                "data-source"
                                                              ].length,
                                                              "CheckboxGroup"
                                                            )
                                                          }
                                                        >
                                                          <PlusOutlined />
                                                        </Button>
                                                      )}
                                                    </Flex>
                                                  </Flex>
                                                )
                                              )
                                            ) : (
                                                <Button
                                                  type="primary"
                                                  style={{
                                                    textAlign: "center",
                                                    width: 30,
                                                    padding: 0,
                                                  }}
                                                  onClick={() =>
                                                    handelAddDropListItem(
                                                      id,
                                                      indx,
                                                      0,
                                                      "CheckboxGroup"
                                                    )
                                                  }
                                                >
                                                  <PlusOutlined />
                                                </Button>
                                            )}
                                            <Flex
                                              justify="flex-end"
                                              gap={16}
                                              style={{ marginTop: 16 }}
                                            >
                                              <Flex align="center" gap={16}>
                                                <span>Required</span>{" "}
                                                <Switch
                                                  checked={chld?.required}
                                                  onChange={() =>
                                                    handleChildRequiredChange(
                                                      id,
                                                      indx,
                                                      "CheckboxGroup"
                                                    )
                                                  }
                                                />
                                              </Flex>
                                              <Button
                                                danger
                                                style={{
                                                  textAlign: "center",
                                                  width: 30,
                                                  padding: 0,
                                                }}
                                                onClick={() =>
                                                  handelDeleteField(
                                                    id,
                                                    indx,
                                                    "CheckboxGroup"
                                                  )
                                                }
                                              >
                                                <DeleteOutlined />
                                              </Button>
                                            </Flex>
                                          </Card>
                                        );
                                      case "Footer":
                                        return (
                                          <Card
                                            key={`footer-${indx}`}
                                            title="Button"
                                            className="editscreencards"
                                          >
                                            <Input
                                              type="text"
                                              value={chld?.label || ""}
                                              onChange={(e) =>
                                                handleChildLabelChange(
                                                  e.target.value,
                                                  id,
                                                  indx,
                                                  "Footer",
                                                  "label"
                                                )
                                              }
                                            />
                                          </Card>
                                        );
                                      default:
                                        return null;
                                    }
                                  })}
                                </div>
                              );
                            }
                            return null;
                          })}
                        </>
                      );
                    })()}
                    <Dropdown
                      menu={{
                        items:
                          optflowIds.length > 0 &&
                          optflowIds.includes(activeScreens)
                            ? dropdownItems.filter(
                                (item) => item.key === "1" || item.key === "2"
                              )
                            : dropdownItems,
                        onClick: onMenuClick,
                      }}
                      trigger={["click"]}
                    >
                      <Button onClick={(e) => e.preventDefault()}>
                        <PlusOutlined /> Add Content <DownOutlined />
                      </Button>
                    </Dropdown>
                  </Col>
                </Row>
              </Col>
            ) : (
              <Col span={16}>
                <Form
                  form={form}
                  layout="vertical"
                  onFinish={handleSubmit}
                  style={{ marginTop: "16px" }}
                >
                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item
                        name="flowName"
                        label="Flow Name"
                        rules={[
                          {
                            required: true,
                            message: "Please enter the flow name!",
                          },
                          {
                            max: 200,
                            message: "Maximum 200 characters allowed!",
                          },
                        ]}
                      >
                        <Input
                          type="text"
                          placeholder="Enter flow name"
                          maxLength={200}
                          showCount
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name="categories"
                        label="Categories"
                        rules={[
                          {
                            required: true,
                            message: "Please select at least one category!",
                          },
                        ]}
                      >
                        <Select
                          mode="multiple"
                          placeholder="Select categories"
                          allowClear
                        >
                          {options.map((option) => (
                            <Option key={option.key} value={option.key}>
                              {option.value}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item name="template" label="Template">
                        <Radio.Group
                          defaultValue={"default"}
                          className="end-point-radio-group"
                          onChange={(e) =>
                            handleOnFlowTypeChange(e.target.value)
                          }
                        >
                          <Space
                            direction="vertical"
                            className="ant-endpoint-space"
                          >
                            <Radio value="default">Default</Radio>
                            <Radio value="collect-prchase-intrest">
                              Collect purchase interest
                            </Radio>
                            <Radio value="get-feedback">Get feedback</Radio>
                            <Radio value="send-a-survey">Send a survey</Radio>
                            <Radio value="customer-support">
                              Customer support
                            </Radio>
                            <Radio value="appointment-booking">
                              Appointment booking
                            </Radio>
                          </Space>
                        </Radio.Group>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </Col>
            )}
            <Col span={8}>
              <Flex
                justify="space-between"
                align="center"
                style={{ height: 24 }}
              >
                <Title level={5} style={{ margin: 0 }}>
                  Flow preview
                </Title>
                {flowId
                  ? screens.length > 1 && (
                      <Select
                        placeholder="Select a screen"
                        style={{ width: 200 }}
                        onChange={(value) => setActiveScreens(value)}
                        value={activeScreens}
                      >
                        {screens.map((screen) => (
                          <Select.Option key={screen.id} value={screen.id}>
                            {screen.title}
                          </Select.Option>
                        ))}
                      </Select>
                    )
                  : selectedFlowJson?.screens?.length > 1 && (
                      <Select
                        placeholder="Select a screen"
                        style={{ width: 200 }}
                        onChange={(value) => setActiveScreens(value)}
                        value={activeScreens}
                      >
                        {selectedFlowJson?.screens.map((screen) => (
                          <Select.Option key={screen.id} value={screen.id}>
                            {screen.title}
                          </Select.Option>
                        ))}
                      </Select>
                    )}
              </Flex>
              <Flex justify="center" align="center">
                <div className="flow-preview-main">
                  <div className="flow-preview">
                    <div className="flow-preview-header">
                      <Image
                        src={storeSvg}
                        preview={false}
                        width={40}
                        height={40}
                        title="Profile picture"
                        alt="Profile picture"
                      />
                      <div className="flow-header-title" />
                    </div>
                    <div className="flow-preview-body-main">
                      <div className="flow-preview-body">
                        <div className="flow-preview-to-message-main">
                          <div className="flow-preview-to-message">
                            <div className="flow-preview-to-message-inner">
                              <div className="flow-preview-to-message-line" />
                            </div>
                          </div>
                          <svg
                            className="flow-preview-to-message-svg"
                            role="none"
                            width="6"
                            height="11"
                            viewBox="0 0 6 11"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M-11.29 0C-10.0877 7.82394e-05 1.71573 0.000195598 4.12034 0.000352077C5.96107 0.000471862 6.55198 1.16245 5.45061 2.58222L-1.27598 11.2535"
                            ></path>
                          </svg>
                        </div>
                        <div className="flow-preview-from-message-main">
                          <div className="flow-preview-from-message">
                            <div className="flow-preview-from-message-inner">
                              <div className="flow-preview-from-message-line-one" />
                              <div className="flow-preview-from-message-line-two" />
                            </div>
                            <hr className="flow-preview-from-message-hr" />
                            <Button
                              onClick={() => setFlowpreviewPopup(true)}
                              className="flow-preview-from-message-button"
                            >
                              Preview Flow
                            </Button>
                          </div>
                          <svg
                            className="flow-preview-from-message-svg"
                            role="none"
                            width="6"
                            height="11"
                            viewBox="0 0 6 11"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M-11.29 0C-10.0877 7.82394e-05 1.71573 0.000195598 4.12034 0.000352077C5.96107 0.000471862 6.55198 1.16245 5.45061 2.58222L-1.27598 11.2535"
                            ></path>
                          </svg>
                        </div>
                      </div>
                    </div>
                    <div className="flow-preview-footer-main">
                      <Input disabled className="flow-preview-footer-input" />
                    </div>
                    {flowpreviewPopup && (
                      <div className="flow-preview-popup-shadow" />
                    )}
                  </div>
                  {flowpreviewPopup &&
                    renderPreviewPopup(flowJsons, selectedFlowJson, flowId)}
                  {subPopup?.type && (
                    <div className="flow-preview-popup">
                      <div
                        className="flow-preview-popup-main"
                        data-scrollable="true"
                      >
                        <div className="flow-preview-popup-start">
                          <div className="flow-preview-popup-second">
                            <header className="flow-preview-popup-header">
                              <nav className="flow-preview-popup-nav">
                                <div className="flow-preview-popup-close-btn-div">
                                  <button
                                    onClick={() => setSubPopup(null)}
                                    aria-label="Cancel"
                                    className="flow-preview-popup-close-btn"
                                  >
                                    <ArrowLeftOutlined
                                      style={{ fontSize: 20 }}
                                    />
                                  </button>
                                </div>
                                <div
                                  className="flow-preview-popup-title-div"
                                  dir="auto"
                                >
                                  <h1 className="flow-preview-popup-title">
                                    {subPopup.label}
                                  </h1>
                                </div>
                                <div className="flow-preview-popup-more-btn-div">
                                  <button
                                    className="flow-preview-popup-more-btn"
                                    aria-label="more options"
                                  >
                                    <MoreVert />
                                  </button>
                                </div>
                              </nav>
                            </header>
                            <div className="flow-preview-popup-body-main">
                              <div className="flow-preview-popup-body-start">
                                <div className="flow-preview-popup-body-second">
                                  <div className="flow-preview-popup-body-third">
                                    <div className="flow-preview-popup-body">
                                      <div className="flow-preview-droplist">
                                        {Array.isArray(
                                          subPopup?.["data-source"]
                                        ) ? (
                                          subPopup?.["data-source"]?.map(
                                            (droplist) => (
                                              <label>{droplist.title}</label>
                                            )
                                          )
                                        ) : (
                                          <>
                                            {(() => {
                                              let screenToDisplay;
                                              if (flowId) {
                                                screenToDisplay =
                                                  activeScreens === ""
                                                    ? selectedFlowJson
                                                        .screens[0]
                                                    : selectedFlowJson.screens.find(
                                                        (screen) =>
                                                          screen.id ===
                                                          activeScreens
                                                      );
                                              } else {
                                                screenToDisplay =
                                                  activeScreens === ""
                                                    ? screens[0]
                                                    : screens.find(
                                                        (screen) =>
                                                          screen.id ===
                                                          activeScreens
                                                      );
                                              }
                                              const newString =
                                                subPopup?.[
                                                  "data-source"
                                                ]?.replace(
                                                  /\${data\.(.*?)}/g,
                                                  "$1"
                                                ) || "";
                                              return (
                                                <div>
                                                  {screenToDisplay.data[
                                                    newString
                                                  ]["__example__"].map(
                                                    (listitem) => (
                                                      <label>
                                                        {listitem.title}
                                                      </label>
                                                    )
                                                  )}
                                                </div>
                                              );
                                            })()}
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </Flex>
              {flowId ? (
                <Flex
                  justify="center"
                  align="center"
                  gap={16}
                  className="arrow-btns"
                >
                  <Button
                    className="flow-preview-arrow-btns"
                    disabled={
                      activeScreens === "" ||
                      activeScreens === selectedFlowJson?.screens?.[0]?.id ||
                      selectedFlowJson?.screens.length === 1
                    }
                    onClick={() =>
                      setActiveScreens(
                        selectedFlowJson?.screens[
                          selectedFlowJson?.screens.findIndex(
                            (screen) => screen.id === activeScreens
                          ) - 1
                        ].id
                      )
                    }
                  >
                    <ArrowBackIos
                      style={{ width: 18, position: "relative", left: 4 }}
                    />
                  </Button>
                  <Button
                    className="flow-preview-arrow-btns"
                    disabled={
                      activeScreens === "" ||
                      activeScreens ===
                        selectedFlowJson?.screens?.[
                          selectedFlowJson?.screens.length - 1
                        ]?.id ||
                      selectedFlowJson?.screens.length === 1
                    }
                    onClick={() =>
                      setActiveScreens(
                        selectedFlowJson?.screens[
                          selectedFlowJson?.screens.findIndex(
                            (screen) => screen.id === activeScreens
                          ) + 1
                        ].id
                      )
                    }
                  >
                    <ArrowForwardIos style={{ width: 18 }} />
                  </Button>
                </Flex>
              ) : (
                <Flex
                  justify="center"
                  align="center"
                  gap={16}
                  className="arrow-btns"
                >
                  <Button
                    className="flow-preview-arrow-btns"
                    disabled={
                      activeScreens === "" ||
                      activeScreens === screens?.[0]?.id ||
                      screens.length === 1
                    }
                    onClick={() =>
                      setActiveScreens(
                        screens[
                          screens.findIndex(
                            (screen) => screen.id === activeScreens
                          ) - 1
                        ].id
                      )
                    }
                  >
                    <ArrowBackIos
                      style={{ width: 18, position: "relative", left: 4 }}
                    />
                  </Button>
                  <Button
                    className="flow-preview-arrow-btns"
                    disabled={
                      activeScreens === "" ||
                      activeScreens === screens?.[screens.length - 1]?.id ||
                      screens.length === 1
                    }
                    onClick={() =>
                      setActiveScreens(
                        screens[
                          screens.findIndex(
                            (screen) => screen.id === activeScreens
                          ) + 1
                        ].id
                      )
                    }
                  >
                    <ArrowForwardIos style={{ width: 18 }} />
                  </Button>
                </Flex>
              )}
            </Col>
          </Row>
        </Layout>
      )}
    </>
  );
};
export default WhatsappFlow;
