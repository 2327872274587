import { Handle, Position } from "reactflow";
import { MessageOutlined, CloseOutlined } from "@ant-design/icons";
import { Input, Select } from "antd";
const { Option } = Select;
const { TextArea } = Input;

function ContentFlow({ data }) {
  const onCloseIconClick = () => {
    if (typeof data.onClose === "function") {
      data.onClose(data.id);
    }
  };


  return (
    <>
      <Handle type="target" position={Position.Top} />
      <div className="nodes">
        <div className="header">
          <p>
            <MessageOutlined /> {data.label}
          </p>
          <CloseOutlined className="closeIcon" onClick={onCloseIconClick} />
        </div>
        <div className="body">
          <div className="inputMainBox">
            <TextArea
              maxLength={1024}
              rows={5}
              placeholder="Type Message"
              count={{ show: true, max: 1024 }}
              allowClear
              onChange={(e) =>
                data.onMainTextAreaChange(data.id, e.target.value)
              }
              className="nodrag"
              defaultValue={data?.nodeData?.mainText}
            />
            <Select
              placeholder="Select Flow"
              className="nodrag"
              style={{ width: "100%", marginTop: 16, marginBottom: 16 }}
              onChange={(value) => data.onFlowSelect(data.id, value)}
              value={data?.nodeData?.flowId}
            >
              {data?.flowData
                .filter((flow) => flow?.status === "PUBLISHED")
                .map((flow) => (
                  <Option value={flow?.id} key={flow?.id}>
                    {flow?.name}
                  </Option>
                ))}
            </Select>
            <div style={{position: 'relative'}}>
            <Input type="text" value={data?.nodeData?.flowBtnText} className="nodrag" placeholder="Type Button text" onChange={(e) => data.onFlowBtnTextAreaChange(data.id, e.target.value)} />
                <Handle type="source" position={Position.Right} id={`${data.id}`} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ContentFlow;
